import React, {useEffect} from "react";
import {Sub_top_002} from "../const/ConstImages";
import {CmmnHeader} from "../components/common/CmmnHeader";
import LoginBody from "../components/login/LoginBody";

interface Props {
    //redirect page after login
    redirectTo?: string
}
const LoginScreen: React.FC<Props> = ({redirectTo}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div style={{
            backgroundColor: "white",
            height: "100%"
        }}>
            <CmmnHeader backgroundImg={Sub_top_002} title="Login" />
            <LoginBody redirectTo={redirectTo} />
        </div>
    );
}

export default LoginScreen;