import React from "react";

export const TransactionHistoryHeader = () => {
    return(
        <div style={{
            width: "100%",
        }} >
            <h1>In-game Purchase History</h1>
        </div>
    )
}