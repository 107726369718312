import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {MainNoticeItem} from "./MainNoticeItem";
import {_BASE_URL} from "../../const/ConstBase";
import {NoticeItems} from "../announcement/notice_types";
import paths from "../../router/paths";

export const MainNoticeContainer = () => {
    const [noticeList, setNoticeList] = React.useState<NoticeItems[]>([]);
    const [updateList, setUpdateList] = React.useState<NoticeItems[]>([]);
    const [eventList, setEventList] = React.useState<NoticeItems[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${_BASE_URL}notice/main_notice`, { method : "GET" });
                const res = await response.json();

                setNoticeList(res.data.notice_list);
                setUpdateList(res.data.update_list);
                setEventList(res.data.event_list);
            } catch (error) {

            }
        }

        fetchData();
        return () => {
        };
    }, []);

    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "#E6E6E6", paddingTop: 20}}>
            <Container
                style={{
                    paddingTop: 20,
                }}
                fluid
            >
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem pkey="u" type="update" item={updateList} title={"Updates"} link={`${paths.ANNOUNCEMENT_PATH}/update`}/>
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem pkey="e" type="event"  item={eventList} title={"Events"} link={`${paths.ANNOUNCEMENT_PATH}/event`}/>
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem pkey="a" type="announce" item={noticeList} title={"Notices"} link={`${paths.ANNOUNCEMENT_PATH}/notice`}/>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    );
}