import React from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import {PvpRankBody} from "../components/ranking/PvpRankBody";

export const PvpRankDayScreen = () => {
    return(
        <div style={{width: "100%", height: "100%", backgroundColor: "white", paddingTop: 20}}>
            <Helmet>
                <title>PVP Ranking</title>
                <meta name="description" content="PVP Ranking" />
            </Helmet>
            <CmmnHeader
                backgroundImg={Sub_top_002}
                title="PVP Daily Ranking"
                subTitle="Aika Online Global PVP Daily Ranking"
            />
            <PvpRankBody tp="day" />
        </div>
    )
}