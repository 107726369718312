import React from "react";
import "./ProfileLeftMenu.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faChevronRight, faCoins,
    faCreditCardAlt, faMoneyBill,
    faPlus,
    faRoute,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {ProfileMenuItem} from "./block/ProfileMenuItem";
import {ProfileSignout} from "./block/ProfileSignout";
import paths from "../../router/paths";

export const ProfileLeftMenu = () => {
    return (
        <div style={{
            width: "100%",
            borderTop: "2px solid #575F6C",
        }}>
            <ProfileMenuItem icon={faUser} title="Account Information" link={paths.PROFILE_PATH} />
            <ProfileMenuItem icon={faAddressCard} title="Account Details" link={paths.ACCOUNT_DETAIL_PATH} />
            <ProfileMenuItem icon={faRoute} title="Login History" link={paths.LOGIN_HISTORY_PATH} />
            <ProfileMenuItem icon={faCreditCardAlt} title="In-game Purchase History" link={paths.TRANSACTION_HISTORY_PATH} />
            <ProfileMenuItem icon={faCoins} title="M-Coin Purchase History" link={paths.TOPUP_COIN_HISTORY_PATH} />
            <br /><br />
            <ProfileSignout />
        </div>

    );
}