import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Sub_overwiew_page_001, job_screenshot} from "../../const/ConstImages";
import "./GuideTop.css";
import ModalVideo from 'react-modal-video';

export const GuideTop = () => {
    const [isOpen, setIsOpen] = useState(false);

    return(
        <Container fluid="true">
            <Row>
                <Col style={{
                    background: `url(${Sub_overwiew_page_001}) no-repeat center center`,
                    height: 1054,
                }}>
                    <Row>
                        <Col md={6} className="guide1">
                            <div className="guide1_sub_title">I.  Game Overview</div>
                            <div className="guide1_title">
                                THE BEST MMORPG IN THE WORLD
                            </div>
                            <Col md={14} className="guide1_content">
                                Aika Online is a free-to-play fantasy-themed MMORPG known for its dynamic features.  The game offers players the unique choice of aligning with one of five nations, each with its own quests and culture, adding depth and strategic gameplay.  It stood out for its intense player versus player (PvP) combat, featuring large-scale "Nation Wars" and a bounty system.  With a diverse selection of character classes, Aika Online catered to various playstyles, from warriors to mages. The Pran system introduced fairy-like companions to assist players in their adventures.
                            </Col>
                            <div className="guide1_video">
                                <img src={job_screenshot} width="400" style={{cursor: "pointer"}} onClick={() => {setIsOpen(true)}} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalVideo channel="youtube" isOpen={isOpen} videoId={"nZY7v2oc3uI"} onClose={() => setIsOpen(false)} />
        </Container>
    )
}