import React from "react";
import {NoticeItems} from "../announcement/notice_types";
import {Link} from "react-router-dom";
import paths from "../../router/paths";

interface Prop {
    item: NoticeItems;
    type: string;
}
const MainNoticeItemNoImage: React.FC<Prop> = (prop: Prop) => {
  let url = encodeURI(paths.ANNOUNCEMENT_DETAIL_PATH + '/' + prop.item.n_seq + '/' + prop.item.n_title);
    return (
        <Link to={`${url}`} style={{textDecoration: "none"}}>
            <div
                    id="contentdiv"
                    style={{
                    width: "100%",
                    // height: "100%",
                    background: "#ffffff",
                    // paddingBottom: 19
                }}
            >
                <div
                    style={{
                        padding: 30,
                        borderTop: "1px solid rgb(230,230,230)",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 40,
                        paddingLeft: 40,
                        color: "#000000",
                        textDecoration: "none",
                        fontWeight: "bold",
                    }}
                >
                    [{prop.item.reg_date}]&nbsp;{prop.item.n_title}
                </div>
            </div>
        </Link>
    );
}

export default MainNoticeItemNoImage;