import React, {ReactNode, useEffect, useState} from "react";
import AuthContext, {
    PendingUser,
    SignInCredentials,
    SignUpCredentials,
    SignUpResponse,
    User
} from "../contexts/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import {createSessionStorage, getToken, removeSessionStorage} from "../utils/tokenStorage";
import {api} from "../services/api";
import {setAuthorizationHeader} from "../services/interceptors";
import paths from "../router/paths";
import {_FRESHDESK_LOGOUT_URL} from "../const/ConstBase";
import Paths from "../router/paths";


type Props = {
    children: ReactNode
}

function AuthProvider(props: Props) {
    const { children } = props

    const [user, setUser] = useState<User>()
    const [pendingUser, setPendingUser] = useState<PendingUser>()
    const [loadingUserData, setLoadingUserData] = useState(true)
    const [contactLink, setContactLink] = useState<string>(Paths.CONTACT_US_PATH)

    // const [currentBalance, setCurrentBalance] = useState(0);
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const token = getToken()
    const isAuthenticated = Boolean(token)

    async function signIn(params: SignInCredentials) {
        const { email, password , captcha} = params

        // try {
            const response = await api.post('/auth/signin', { email, password, captcha })
            const { token, refreshToken, permissions, roles, status, lang, last_update_password, nickname } = response.data

            if(status === "V") {
                createSessionStorage({ token, refreshToken });
                setUser({ email, permissions: [], roles: [], lang, nickname, last_update_password});
                setPendingUser(undefined);
                setAuthorizationHeader({ request: api.defaults, token });
                setContactLink(Paths.AFTER_CONTACT_US_PATH);
            } else if(status === "E") {
                removeSessionStorage()
                setUser(undefined)
                setPendingUser({email: email})
                setLoadingUserData(false)
                setContactLink(Paths.CONTACT_US_PATH)
            }
            return status;

        // } catch (error) {
        //     return error as AxiosError;
        // }
    }

    function signOut() {
        removeSessionStorage()
        setUser(undefined)
        setPendingUser(undefined)
        setLoadingUserData(false)
        setContactLink(Paths.CONTACT_US_PATH)
        navigate(paths.ROOT_PATH)
        // window.location.href = _FRESHDESK_LOGOUT_URL;
    }

    async function signUp(params: SignUpCredentials) {
        removeSessionStorage()
        setUser(undefined)
        setPendingUser(undefined)
        setLoadingUserData(false)
        setContactLink(Paths.CONTACT_US_PATH)

        const response = await api.post('/auth/signup', params)
        const { res_code, res_msg } = response.data;

        if(res_code === "SUCCESS") {
            setPendingUser({ email: params.email })
        }
        return { res_code, res_msg } as SignUpResponse;
    }

    useEffect(() => {
        const token = getToken();

        if (!token) {
            removeSessionStorage()
            setUser(undefined)
            setContactLink(Paths.CONTACT_US_PATH)
            setLoadingUserData(false)
        }

    }, [navigate, pathname, token])

    useEffect(() => {
        const token = getToken()

        async function getUserData() {
            setLoadingUserData(true)

            try {
                const response = await api.post('/auth/userinfo')

                if (response?.data) {
                    const { user_email, user_nick, last_update_password, user_lang } = response.data
                    setUser({ email: user_email, permissions: [], roles: [], lang: user_lang, nickname: user_nick, last_update_password })
                    setContactLink(Paths.AFTER_CONTACT_US_PATH)
                }
            } catch (error) {
                /**
                 * an error handler can be added here
                 */
            } finally {
                setLoadingUserData(false)
            }
        }

        if (token) {
            setAuthorizationHeader({ request: api.defaults, token })
            getUserData();
        }
    }, [])

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                pendingUser,
                loadingUserData,
                signIn,
                signOut,
                signUp,
                contactLink,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
