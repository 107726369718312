import React, {useEffect} from "react";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import FindPasswordBody from "../components/login/FindPasswordBody";
import RegistAccountBody from "../components/login/RegistAccountBody";

const RegistAccountScreen = () => {
    const [step, setStep] = React.useState(0);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{
            backgroundColor: "white",
            height: "100%"
        }}>
            <CmmnHeader backgroundImg={Sub_top_002} title="Create Account" />
            <RegistAccountBody />
        </div>
    );
}


export default RegistAccountScreen;