import React from "react";
import {Modal} from "react-bootstrap";
import TermOfServiceBody from "../privacy/TermOfServiceBody";

interface Props {
    isShow: boolean;
    setShow: (eulaShow: boolean) => void;
}
const EulaModal: React.FC<Props> = (props) => {
    return (
        <Modal
            size="lg"
            show={props.isShow}
            onHide={() => props.setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    TOS & EULA
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TermOfServiceBody />
            </Modal.Body>
        </Modal>
    );
}

export default EulaModal;