import React from "react";
import {Col, Row} from "react-bootstrap";

const GameOperationsPolicyBody = () => {
    return(
        <Row className="justify-content-center border-bottom" style={{
            paddingTop: 40,
            backgroundColor: "white",
        }}>
            <Col className="col-10 justify-content-center">
                <div >
                    <h2>Game Operations Policy</h2>
                    <br/>

                    <h4>Account Management</h4>
                    <ul>
                        1.	You are responsible for the security of your personal accounts.
                        <br/>
                        2. Your account may be banned without warning if you have sold or attempted to sell accounts, items, in-game currencies, or any other in-game services for cash (i.e. Real-Money Trading).
                        <br/>
                        3.	You are responsible for all consequences resulting from account or item sharing and real-world transactions.
                        <br/>
                        <br/>
                        <br/>
                    </ul>

                    <h4>Guidelines</h4>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th style={{backgroundColor: "#efefef"}}>CASE</th>
                            <th style={{backgroundColor: "#efefef"}}>Minimum</th>
                            <th style={{backgroundColor: "#efefef"}}>Maximum</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Harassment in Game / Discord</td>
                            <td rowSpan={3} valign="middle">Warning/ Muting/ Temporary Suspension (1-7 days)</td>
                            <td rowSpan={5} valign="middle">Permanent<br/>Suspension</td>
                        </tr>
                        <tr>
                            <td>Spreading Rumor</td>
                        </tr>
                        <tr>
                            <td>Spam Chat</td>
                        </tr>
                        <tr>
                            <td>False Report</td>
                            <td>Warning/ Temporary Suspension (1-7 days)</td>
                        </tr>
                        <tr>
                            <td>Bug Exploitation</td>
                            <td>Warning/ Temporary Suspension</td>
                        </tr>
                        <tr>
                            <td>Impersonating CBM Staff / GMs</td>
                            <td rowSpan={5} valign="middle">Permanent Suspension</td>
                            <td rowSpan={5} valign="middle"></td>
                        </tr>
                        <tr>
                            <td>Program Exploitation</td>
                        </tr>
                        <tr>
                            <td>Chargeback</td>
                        </tr>
                        <tr>
                            <td>Advertising 3rd Party (RMT)</td>
                        </tr>
                        <tr>
                            <td>Real Money Trading</td>
                        </tr>
                        </tbody>
                    </table>

                    <hr />
                    <h4>Harassment</h4>
                    <ul>
                        1.	Using extreme vulgar language and/or bullying a specific user for a period.
                        <br/>
                        2.	Shaming other users’ sexuality and/or gender identity
                        <br/>
                        3.	Degrading other users for their religion, place of residence, race, disabilities, etc.
                        <br/>
                        4.	Threatening and/or talking to others in a way that makes them feel concerned for their safety.
                        <br/>
                        5.	Defaming and/or releasing personal information of a user to the public, also known as doxing.
                        <br/>
                        6.	Pushing immoral views that are considered socially unacceptable.
                        <br/>
                        7.	Using inappropriate names in the context of the game such as character names, guild names, personal shop names, etc.
                        <br/>
                        ** If multiple offences are confirmed, you will receive multiple disciplinary actions.
                        <br/>

                        <br/>
                        <br/>

                    </ul>

                    <hr />
                    <h4>How To Report: ‘Harassment’ Cases</h4>
                    <ul>
                        1.	You must report all harassment cases within 2 days of the incident.
                        <br />
                        2.	Reporting users that have been provoked will not be accepted.
                        <br />
                    </ul>

                    <hr />
                    <h4>Spam</h4>
                    <ul>
                        1.	Posting the same message excessively, disturbing other users from chatting.
                        <br />
                    </ul>

                    <hr />
                    <h4>Bug Exploitation</h4>
                    <ul>
                        1.	Exploiting a bug to gain benefit.
                        <br />
                        2.	Teaching other users how to replicate bugs without reporting it to the GMs.
                        <br />
                        3.	Exploiting system errors to harm other users or to gain an unfair advantage.
                        <br />
                    </ul>

                    <hr />
                    <h4>Advertising 3rd Party</h4>
                    <ul>
                        1.	Attempting to sell or advertise any cash transactions.
                        <br />
                        <ul>
                            A.	Repetitively releasing a phone number or websites with the intent of advertising.
                            <br />
                            B.	Chat logs confirming the intent of selling items, in-game currency, or services for monetary compensation.
                            <br />
                            C.	Characters generated with a name that suggests and/or promote real-world trading.
                            <br />
                            D.	Creating chat rooms, forum posts, or personal shop names that suggests and/or promote real-world trading.
                            <br />
                        </ul>
                        2.	Advertising in-game for commercial purposes.
                        <br />
                        3.	Advertising trading or selling accounts.
                        <br />
                        4.	Advertising websites that sell items, in-game currencies, or accounts for cash.
                        <br />
                    </ul>

                    <hr />
                    <h4>RMT (Real Money Trading)</h4>
                    <ul>
                        1.	Trading any items and in-game currencies for real-world cash or goods.
                        <br />
                        2.	Trading any items and in-game currencies for other goods in a different game.
                        <br />
                        3.	Trading any accounts for real-world cash or goods.
                        <br />
                    </ul>

                    <hr />
                    <h4>Program Exploitation</h4>
                    <ul>
                        1.	Gaining an unfair advantage by using illegal programs.
                        <br />
                        2.	Creating characters using illegal programs to assist you or party members.
                        <br />
                        3.	Benefitting from characters using illegal programs, even if you are not in a party with them.
                        <br />
                        4.	Illegally acquiring items and/or in-game currencies by using illegal programs.
                        <br />
                        **If multiple offences are confirmed, you will receive multiple disciplinary actions.
                        <br />
                    </ul>

                    <hr />
                    <h4>Multiple Offence</h4>
                    <ul>
                        1.	In the case of multiple offences, multiple disciplinary actions (including a permanent ban) may be issued, depending on the number and duration of your previous offence.
                        <br />
                        2.	Even if it does not apply to the term above, you may face multiple disciplinary actions (including a permanent ban) if you were to disturb the game balance or affect other players negatively.
                        <br />
                    </ul>
                    <br />
                    <br />
                    <br />
                </div>
            </Col>
        </Row>


    );
}

export default GameOperationsPolicyBody;