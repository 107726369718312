import React from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import GameOperationsPolicyBody from "../components/privacy/GameOperationsPolicyBody";

export const GameOperationsPolicy = () => {
    return(
        <>
            <Helmet>
                <title>GAME OPERATIONS POLICY</title>
                <meta name="description" content="GAME OPERATIONS POLICY" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title={ "Game Operations Policy" } />
            <GameOperationsPolicyBody />
        </>
    )
}

export default GameOperationsPolicy;