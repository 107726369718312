import React from 'react';
import {MainScreen} from "./pages/MainScreen";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NoticeScreen} from "./pages/NoticeScreen";
import {TopNavi} from "./components/common/TopNavi";
import {Footer} from "./components/common/Footer";
import {NoticeDetailScreen} from "./pages/NoticeDetailScreen";
import {GuideScreen} from "./pages/GuideScreen";
import {DownloadScreen} from "./pages/DownloadScreen";
import {BanScreen} from "./pages/BanScreen";
import {Helmet, HelmetProvider} from "react-helmet-async";
import AuthProvider from "./providers/AuthProvider";
import PrivateRoute from "./router/PrivateRoute";
import paths from "./router/paths";
import {ProfileScreen} from "./pages/ProfileScreen";
import {AccountDetailScreen} from "./pages/AccountDetailScreen";
import {LoginHistoryScreen} from "./pages/LoginHistoryScreen";
import {TransactionHistoryScreen} from "./pages/TransactionHistoryScreen";
import {ShopScreen} from "./pages/ShopScreen";
import FindPasswordScreen from "./pages/FindPasswordScreen";
import RegistAccountScreen from "./pages/RegistAccountScreen";
import {PaymentSuccess} from "./pages/PaymentSuccess";
import {PaymentFail} from "./pages/PaymentFail";
import PrivatePolicyModal from "./components/login/PrivatePolicyModal";
import PrivacyPolicyScreen from "./pages/PrivacyPolicyScreen";
import {TermOfServiceScreen} from "./pages/TermOfServiceScreen";
import UgcScreen from "./pages/UgcScreen";
import ScrollToTop from "react-scroll-to-top";
import PasswordBlockScreen from "./pages/PasswordBlockScreen";
import LoginScreen from "./pages/LoginScreen";
import BanIPScreen from "./pages/BanIPScreen";
import PurchaseCoinHistoryScreen from "./pages/PurchaseCoinHistoryScreen";
import ValidCodeScreen from "./pages/ValidCodeScreen";
import {PasswordChangeScreen} from "./pages/PasswordChangeScreen";
import {EpinScreen} from "./pages/EpinScreen";
import "./modal-video.css";
import GameOperationsPolicy from "./pages/GameOperationsPolicy";
import {LogoutScreen} from "./pages/LogoutScreen";
import {GuildRankScreen} from "./pages/GuildRankScreen";
import {PvpRankDayScreen} from "./pages/PvpRankDayScreen";
import {PvpRankWeekScreen} from "./pages/PvpRankWeekScreen";

function App() {
    let buildEnv = process.env.REACT_APP_ENV ?? '';
    buildEnv = buildEnv.trim().toLowerCase();
    if(buildEnv === 'qa') {
        buildEnv = '[stag]';
    } else if(buildEnv === 'live') {
        buildEnv = '';
    } else {
        buildEnv = '[dev]';
    }

    let rootTitle = `${buildEnv} AIKA Online`;
  return (
    <BrowserRouter>
        <AuthProvider>
            <HelmetProvider>
                <Helmet>
                    <title>{rootTitle}</title>
                    <meta name="description" content="Aika Online" />
                </Helmet>
                <TopNavi />
                <ScrollToTop smooth />
                <Routes>
                    <Route path={paths.ROOT_PATH} element={<MainScreen />}></Route>
                    <Route path={paths.BAN_PATH} element={<BanScreen />}></Route>
                    <Route path={`${paths.ANNOUNCEMENT_PATH}/:ntype`} element={<NoticeScreen />}></Route>
                    <Route path={paths.ANNOUNCEMENT_DETAIL_PATH + "/:seq/:title"} element={<NoticeDetailScreen />}></Route>
                    <Route path={paths.DOWNLOAD_PATH} element={<DownloadScreen />}></Route>
                    <Route path={paths.PROFILE_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <ProfileScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.ACCOUNT_DETAIL_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <AccountDetailScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.LOGIN_HISTORY_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <LoginHistoryScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.TRANSACTION_HISTORY_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <TransactionHistoryScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.GUIDE_PATH} element={<GuideScreen />}></Route>

                    <Route path={paths.SHOP_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <ShopScreen />
                        </PrivateRoute>
                    }></Route>

                    <Route path={paths.PASSWORD_CHANGE_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <PasswordChangeScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.EPIN_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <EpinScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.LOGIN_PATH} element={<LoginScreen />}></Route>
                    <Route path={paths.FIND_PASSWORD_PATH} element={<FindPasswordScreen />}></Route>
                    <Route path={paths.REGISTER_PATH} element={<RegistAccountScreen />}></Route>
                    <Route path={paths.PAYMENT_SUCCESS_PATH} element={<PaymentSuccess />}></Route>
                    <Route path={paths.PAYMENT_FAIL_PATH} element={<PaymentFail />}></Route>
                    <Route path={paths.PRIVATE_POLICY_PATH} element={<PrivacyPolicyScreen />}></Route>
                    <Route path={paths.GAME_OPERATIONS_POLICY_PATH} element={ <GameOperationsPolicy />}></Route>
                    <Route path={paths.TERM_OF_SERVICE_PATH} element={<TermOfServiceScreen />}></Route>
                    <Route path={paths.UGC_PATH} element={<UgcScreen />}></Route>
                    <Route path={paths.PASS_BLOCK_PATH} element={<PasswordBlockScreen />}></Route>
                    <Route path={paths.BAN_IP_PATH} element={<BanIPScreen />}></Route>
                    <Route path={paths.TOPUP_COIN_HISTORY_PATH} element={
                        <PrivateRoute permissions={['']}>
                            <PurchaseCoinHistoryScreen />
                        </PrivateRoute>
                    }></Route>
                    <Route path={paths.VALID_CODE_PATH} element={<ValidCodeScreen />}></Route>
                    <Route path={paths.LOGOUT_PATH} element={<LogoutScreen />}></Route>

                    {/* ranking page start */}
                    <Route path={paths.GUILD_RANK_PATH} element={<GuildRankScreen />}></Route>
                    <Route path={paths.PVP_RANK_DAY_PATH} element={<PvpRankDayScreen />}></Route>
                    <Route path={paths.PVP_RANK_WEEK_PATH} element={<PvpRankWeekScreen />}></Route>
                    {/* ranking page end */}

                    <Route path="*" element={<MainScreen />}></Route> {/* 404 Page */}
                </Routes>
                <Footer />
            </HelmetProvider>
        </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
