import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import "./LoginHistoryBody.css";
import {useNavigate} from "react-router-dom";
import {api} from "../../services/api";
import {CmmnPaging} from "../common/CmmnPaging";
import {NoticeItem} from "../announcement/NoticeItem";
import {LoginHistItem} from "./profile_types";
import {TransactionHistoryRequest} from "../../contexts/AuthContext";
import {getPastDate} from "../../utils/commonUtils";

export const LoginHistoryBody = () => {
    const [list, setList] = React.useState<LoginHistItem[]>([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    async function getList(params: TransactionHistoryRequest) {
        try {
            const response = await api.post('/account/login_history', params);
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        const today = new Date();
        const todayStr = today.toISOString().split('T')[0];

        const day30before = getPastDate(30);
        const dateStr = day30before.toISOString().split('T')[0];

        setStartDate(dateStr);
        setEndDate(todayStr);

        getList(buildParams(1)).then((res) => {
            setList(res.data);
            setPage(res.page.current);
            setTotalCount(res.page.total);
            setPageSize(res.page.pageSize);
        }).catch((err) => {

        });
    }, []);

    const setPastDate = (day: number) => {
        const date = getPastDate(day);
        const dateStr = date.toISOString().split('T')[0];
        setStartDate(dateStr);

        const today = new Date();
        const todayStr = today.toISOString().split('T')[0];
        setEndDate(todayStr);
    }
    function buildParams(page: number): TransactionHistoryRequest {
        return {
            start_date: startDate,
            end_date: endDate,
            page: page,
        };
    }

    function handleSearch() {
        setList([]);
        setPage(1);
        setTotalCount(0);
        setPageSize(10);
        getList(buildParams(1)).then((res) => {
            if(res && res.data) {
                setList(res.data);
                setPage(res.page.current);
                setTotalCount(res.page.total);
                setPageSize(res.page.pageSize);
            }
        }).catch((err) => {

        });
    }

    return(
        <>
            <Row className="justify-content-center justify-content-between "
                 style={{
                     borderTop: "2px solid #D6D9E1",
                     marginTop: 33,
                 }}
            >
                <Col md={6} style={{textAlign: "left", paddingTop: 30}}>
                    <button className="term_button" onClick={() => { setPastDate(7);}}>1 week</button>
                    <button className="term_button" onClick={() => { setPastDate(30);}}>1 month</button>
                    <button className="term_button" onClick={() => { setPastDate(180);}}>6 months</button>
                </Col>
                <Col md={6} style={{textAlign: "right", paddingTop: 30}}>
                    <input className="term_date" name="term_start" type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value) }
                    />
                    -
                    <input className="term_date" name="term_end" type="date"
                           value={endDate}
                           onChange={(e) => setEndDate(e.target.value) }
                    />
                    &nbsp;
                    <button className="term_button" onClick={handleSearch}>Search</button>
                </Col>
            </Row>

            <div style={{width: "100%", height: 40}}></div>

            <Row fluid="true">
                <Col md={4} xs={4} style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Connection</Col>
                <Col md={4} xs={4} style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Access Type</Col>
                <Col md={4} xs={4} style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Date</Col>
            </Row>
            <div style={{width: "100%", height: 40}}></div>
            { list && list.length > 0 && list.map((item, index) => {
                return(
                    <Row fluid="true" key={index}>
                        <Col md={4} xs={4} style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{item.country}</Col>
                        <Col md={4} xs={4} style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{item.login_type}</Col>
                        <Col md={4} xs={4} style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{item.reg_date}</Col>
                    </Row>
                );
            })}
            <div style={{width: "100%", height: 20}}></div>
            <CmmnPaging
                currentPage={page}
                totalCount={totalCount}
                pageSize={pageSize}
                showSearch={false}
                clickPage={(page) => {
                    getList(buildParams(page)).then((res) => {
                        if(res && res.data) {
                            setList(res.data);
                            setPage(res.page.current);
                            setTotalCount(res.page.total);
                            setPageSize(res.page.pageSize);
                        }
                    }).catch((err) => {

                    });
                }}
                clickSearch={(text) => {
                }}
            />
        </>
    )
}