import {icon_surprise} from "../../const/ConstImages";
import React from "react";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";

export const PaymentFailBody = () => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(paths.SHOP_PATH)
    }
    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_surprise} alt="icon-surprise" />
            </div>
            <div className="center_content">
                Failed to purchase M-Coin!
                <br />
                <br />
                <br />
                <div className="black_button" style={{cursor: "pointer"}} onClick={clickHandler}>Back to Purchase</div>
            </div>
        </div>
    )
}