import React from "react";
import {Col, Row} from "react-bootstrap";

export const TermOfServiceBody = () => {
    return(
        <Row className="justify-content-center border-bottom" style={{
            paddingTop: 40,
            backgroundColor: "white",
        }}>
            <Col className="col-10 justify-content-center">
                <div >
                    <h2>TERMS OF SERVICE AND END USER LICENSE AGREEMENT</h2>
                    <br/>
                    Effective Date: 2023.09.21
                    <br/><br/>

                    These Terms of Service and End User License Agreement (the “Terms”) are an agreement between you and CBM Interactive Inc. and its subsidiaries and affiliates (collectively, “CBM”). They govern your access and use of the websites, games, mobile apps, and other interactive software products and services that we operate and that link to these Terms (collectively, our “Services”).

                    <br/><br/>Please review these Terms carefully before using the Services. We may change the Terms or modify, suspend, or terminate any features of the Services at any time in our sole discretion consistent with applicable law. We will notify you (including by posting the updated Terms on the Services) of changes to these Terms. By continuing to use the Services, you accept such changes to the Terms, which will take effect at the time they are posted or as otherwise required by applicable law.

                    <br/><br/>YOU CONSENT TO ENTERING INTO THESE TERMS ELECTRONICALLY, AND TO STORAGE OF RECORDS RELATED TO THESE TERMS IN ELECTRONIC FORM.
                    <br/><br/>

                    <h5>1.	USE OF THE SERVICES</h5><br/>

                    Limited License. Subject to these Terms, you may access and use the Services running operating systems on which the Services were designed to operate for your personal, non-commercial, entertainment use only. The Services are licensed, not sold, to you on a limited, revocable, non-sublicensable, nontransferable, and non-exclusive basis. You may not modify, copy (except for a single backup copy and transient copying required to operate the Services), distribute, transmit, display, perform, reproduce, publish, create derivative works from or adapt, sell, rent, lease, sublicense, or otherwise transfer or make available the Services or any portion thereof. Without limiting the generality of the foregoing, you agree that you have no right to sell or otherwise transfer any of the virtual items, virtual currency, points, or any other content or information from the Services, in whole or in part, on internet auction sites (such as eBay or IGE), hack sites, private server sites, gold farming sites, or any other third-party sites or services or in return for anything of value (whether real money or otherwise). You have no rights in or to the Services unless expressly granted to you under these Terms. Your license is automatically rescinded and terminated if you breach any provision of the Terms.
                    <br/><br/>Additional Terms. Some of the Services are subject to and governed by additional terms, conditions, rules, or guidelines on permissible use (“Additional Terms”). We will make these Additional Terms available to you through the applicable service, in which case your acceptance of such Additional Terms is required before you may use the applicable service. If any of the covenants of this agreement conflict with any terms and conditions of Additional Terms, the Additional Terms shall supersede this agreement with respect to the subject matter addressed in the Additional Terms.
                    <br/><br/>Eligibility. You represent that you are not a person barred from receiving the Services under applicable law. You also represent that you either are over the age of majority in your country or, consistent with applicable law, use the Services only with the permission and under the supervision of a parent or legal guardian that has reviewed and agreed to these Terms. Children under 13 (or as defined under applicable law) may not register for an account and may not provide any personal information to CBM or others through the Services, without parental consent.
                    <br/><br/>Account Registration. Some of our Services require you to create an account with us. If you are not signed into your account, you might not be able to access some portions or features of the Services. When creating an account, you will provide only accurate and complete information, and promptly update this information. You will keep your username and password confidential and secure, and will not use the same password that you use for other websites or services. You will uninstall and delete the Services before transferring any device on which they are installed. You will immediately notify us if you discover or otherwise suspect any unauthorized access to or use of your account.
                    <br/><br/>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN YOUR ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT OF CBM. We do not recognize or allow the transfer of accounts between players. You may not, and may not offer to, purchase, sell, gift, or trade any account. Any such attempt shall be null and void and may result in the termination and forfeiture of the account. Any account which has been dormant for more than one year may expire and be deleted.
                    <br/><br/>Updates. CBM may provide you with updates as they are made generally available by CBM in its sole discretion. You acknowledge and agree that CBM may provide updates to you remotely and automatically, including by (without limitation) accessing the device on which you use the Services. Any updates provided or made available by CBM are part of the Services and will be subject to these Terms.
                    <br/><br/>Export Controls. You will abide by all applicable export laws and regulations when using the Service. The Services, or any part thereof, may not be downloaded or otherwise exported or re-exported (a) into any country for which the Republic of Korea has a trade embargo, or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Commerce Department’s Table of Denial Orders or subject to EU or EU member state financial or other sanctions. You represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.
                    <br/><br/>Cross-border Transfers. By accepting these Terms you understand and agree that performance of these Terms and provision of the Services may require transfer of the information that we collect from your country to other countries, which may not provide the same level of protection as the European Union/European Economic Area or your home country.
                    <br/><br/>


                    <h5>2.	CONTENT POSTED BY USERS</h5><br/>

                    You are and will remain solely responsible for all communications, images, videos, sounds, content or part of the content of databases, databases, content and any other materials or information that you upload or transmit through the Services, including without limitation any text, voice transmissions or recordings, or gameplay clips/screenshots (“User Content”).
                    <br/><br/>You represent and warrant that the User Content that you post or transmit will not:
                    <ul>
                        <li>
                            Infringe the copyright, trademark, database or other intellectual property rights of a third party. You represent and warrant that you own or have all necessary licenses, rights, consents, and permissions to post or transmit the User Content on the Services and that the User Content does not violate any applicable laws, the Terms, or any third party’s right of privacy or publicity.
                        </li>
                        <li>
                            Promote unauthorized sweepstakes or contest; and will not promote a lottery, or gambling of any type.
                        </li>
                        <li>
                           Prevent or impede other users from finding information related to the Services.
                        </li>
                        <li>
                            Facilitate or promote conduct that in the sole discretion of CBM involves illegal content, pornography, piracy, online pharmacies, illegal drugs, underage drinking, or socially irresponsible behavior due to alcohol or drug consumption (such as drinking and driving).
                        </li>
                        <li>
                            Instruct users how to make bombs, weapons, drugs, illegal or illicit or dangerous items, or solicit involvement in behavior or organizations that are violent or illegal under applicable laws, including without limitation terrorist threats or activities .
                        </li>
                    </ul>
                    <br/><br/>
                    You acknowledge and agree that CBM may (but is not required to) moderate, monitor, review, and record any User Content (including the content of your oral or written communications) that you post, transmit, or make available on or through the Services and may, without prior notice to you and in its sole judgment, remove User Content for any or no reason, at any time. You agree that such monitoring or recording can be made using software that may be installed when you download software to access or use the Services. Unless required by law, CBM assumes no liability for any failure to remove, or any delay in removing, User Content and does not assume any responsibility or liability for the use and/or storage of User Content.
                    <br/><br/>You hereby grant CBM a royalty-free, perpetual, irrevocable, fully transferable and sublicensable, non-exclusive, worldwide right and license to use your User Content in any and all media in any manner and for any purpose (including without limitation commercial, advertising, and promotional purposes) to the greatest extent permitted by applicable law, including without limitation the rights to reproduce, copy, modify, perform, display, publish, distribute, broadcast, transmit, or otherwise communicate to the public, or create derivative works from or adapt, use or otherwise exploit by any means whether now known or unknown and without any further notice or compensation to you of any kind. To the extent permitted by applicable law, you waive any moral rights of paternity, publication, reputation, or attribution with respect to CBM’s and other players’ use and enjoyment of User Content in connection with the Services. The license grant to CBM, and the above waiver of any applicable moral rights, survives any termination or expiration of the Terms.

                    <br/><br/>

                    <h5>3.	INTELLECTUAL PROPERTY AND CLAIMS OF INFRINGEMENT</h5><br/>

                    The Services (including any updates thereto) are protected under the copyright and other intellectual property laws of the Republic of Korea, United States, European Union, and other countries. CBM and our associated logos and names are our trademarks and/or service marks. Other trademarks, service marks, names, and logos used on or through the Services are the trademarks, service marks or logos of their respective owners. You are granted no right or license with respect to any of these trademarks, service marks, or logos without our prior written consent. CBM owns and shall retain all right, title, and interest, including without limitation all intellectual property rights, in and to the Services and any portions thereof.
                    <br/><br/>Some content made available through the Services may contain watermarks and is controlled by other digital rights management technologies, which will restrict how you may access and use the Services. The Services may access and monitor your device (including without limitation the hard drive and other storage devices, central processing unit, random access memory, video card, and peripheral hardware, software and applications) for third-party programs or software that is prohibited under these Terms (“Unauthorized Third-Party Software”). In the event that we detect any Unauthorized Third-Party Software, CBM may collect additional information, as described in the CBM Privacy Policy including without limitation your account name, details about the Unauthorized Third-Party Software, and the time and date, and may use this information to enforce these Terms in accordance with the CBM Privacy Policy.
                    <br/><br/>CBM is always pleased to hear from our players and fans, and we welcome comments and feedback regarding our Services. Any ideas, suggestions, proposals, comments, feedback, or other submissions (including without limitation those that provide feedback on our Services or suggest new content, features, products, or similar ideas) (collectively, “Feedback”), regardless of whether you provide such Feedback in a public or private medium, will not be treated as confidential or proprietary. This means that if you provide Feedback to CBM, you are waiving all rights to it and are allowing it to be shared and used by us or others for any purpose, including without limitation using, sharing, and commercially exploiting the Feedback any way we see fit without any notice or compensation to you. CBM does not return or regularly acknowledge Feedback we receive, and does not have any obligation related to your Feedback.
                    <br/><br/>We respect the intellectual property rights of others. If you have a good faith belief that your copyrighted work or other intellectual property has been infringed and is accessible through our Services, you may notify our Designated Agent by contacting us as specified below by providing the following information in writing:
                    <ul>
                        <li>
                            Identification of the copyrighted work or intellectual property, or a representative list of works or property claimed to have been infringed.
                        </li>
                        <li>
                            Identification of the allegedly infringing material and information reasonably sufficient to permit us to locate the material.
                        </li>
                        <li>
                            Your name, address and daytime telephone number, and an e-mail address if available, so that we may contact you if necessary.
                        </li>
                        <li>
                            A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                        </li>
                        <li>
                            A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the owner of the intellectual property, its agent, or the law.
                        </li>
                        <li>
                            A physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.
                        </li>
                    </ul>

                    <br/><br/>
                    If the content is removed, the party that posted the content may receive a notice that it was removed because of claimed infringement. We may provide them with your contact information, including email address and the name of your organization or client, and the contents of your report. If the party that posted the content believes that the content shouldn’t have been removed, they’ll be encouraged to reach out to you to try to resolve the issue directly, and they may be able to submit a counter-notification under applicable law. We terminate the privileges of users who repeatedly infringe intellectual property laws.
                    <div style={{
                        paddingLeft: 30
                    }}>
                        CBM Interactive Inc.
                        <br />#268, 8, Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Zip code :  16837, Republic of Korea
                        <br/>privacy@cbminteractive.com
                    </div>

                    <br/><br/>

                    <h5>4.	VIRTUAL CURRENCY AND VIRTUAL ITEMS</h5><br/>

                    While using the Services, you may have the opportunity to visit online and in-game stores where you can obtain and use CBM’s virtual currency and digital, in-game items (including early access to games still in production, as may be offered from time to time). CBM also may make points available to you in connection with your use of the Services.
                    <br/><br/>VIRTUAL CONTENTS, INCLUDING VIRTUAL CONTENTS EXCHANGED FOR PREPAID PAYMENT INSTRUMENTS, ARE REGARDED AS GOODS OR SERVICES, AND ARE NOT PREPAID PAYMENT INSTRUMENTS. Virtual currency and points have no “real-world” value but may be exchanged for in-game items. Items from such stores are not purchased by you, but rather are licensed to you under these Terms. REGARDLESS OF THE CONSIDERATION OFFERED OR PAID IN EXCHANGE FOR VIRTUAL CURRENCY, YOU DO NOT HAVE ANY OWNERSHIP RIGHTS IN THE ITEMS OBTAINED WITH VIRTUAL CURRENCY.
                    <br/><br/>Any prices, quotations and descriptions made or referred to on our Services do not constitute an offer and may be withdrawn or revised at any time before CBM’s express acceptance of your order. To the extent permitted under applicable law, you agree to pay all charges, fees, and applicable taxes incurred by you or anyone else using your account or resulting from use of the Services on your device at the price(s) in effect when such charges occurred. CBM may change the pricing for the Services at any time consistent with applicable law. CBM reserves the right to notify you of any mistakes in product descriptions or errors in pricing prior to product dispatch. If that happens, and you choose to continue with fulfilment of the order, you acknowledge that the product or service will be provided in accordance with such revised description or corrected price.
                    <br/><br/>Different payment methods, such as credit card, direct debit, PayPal, etc., may be available to you when obtaining virtual currency through the Services. When you use third-party payment and billing providers, such as PayPal or Paymentwall, that provider’s additional terms, conditions, and costs, apply. It is your obligation to pay all federal, state, and local taxes associated with the receipt or use of the virtual items which you purchase from CBM.
                    <br/><br/>Certain minimums may apply to purchases of virtual currency, and certain maximums may apply depending on your transaction method. CBM reserves the right to change the maximum and minimum amounts applicable to virtual currency purchases at any time without notice, consistent with applicable law.
                    <br/><br/>YOU UNDERSTAND AND AGREE THAT CBM WILL NOT OFFER REFUNDS FOR VIRTUAL CURRENCY OR DIGITAL, IN-GAME ITEMS UNDER ANY CIRCUMSTANCES, INCLUDING WITHOUT LIMITATION TERMINATION OR EXPIRATION OF YOUR ACCOUNT, THESE TERMS, OR THE SERVICES, EXCEPT AS EXPRESSLY REQUIRED BY APPLICABLE LAW. FOR RESIDENTS OF THE EEA OR OF THE UNITED KINGDOM ONLY: YOU AGREE TO IMMEDIATELY RECEIVE THE DIGITAL PRODUCTS, INCLUDING VIRTUAL CURRENCY, DIGITAL IN-GAME ITEMS AND ANY OTHER VIRTUAL CONTENTS, AND TO WAIVE YOUR CANCELLATION AND REFUND RIGHT ONCE THE DOWNLOAD OR STREAMING OF THE DIGITAL PRODUCT HAS BEGUN. If an account is permanently banned, all content licenses and virtual currency balances associated with the suspended account are forfeited. CBM will have no obligation or responsibility to and will not reimburse you for any virtual currency, points, items, or experience lost due to your violation of these Terms.
                    <br/><br/>Items may expire. Each item that you obtain using virtual currency or points will be included in your account until the earlier of the item’s expiration date, termination or expiration of your account, these Terms, or the Services. Price and availability of items are subject to change without notice, consistent with applicable law.
                    <br/><br/>We may suspend or terminate your account if for any reason a charge you authorize us to make to your credit card or SMS/phone billing cannot be processed or is returned to us unpaid or refunded and, if such event occurs, you shall immediately remit to us payment for such charge through the use of another credit card or other payment mechanism. We are not responsible or liable for any credit card or bank-related charges or fees related to any of your transactions. We reserve the right, without prior notification, to limit the order quantity on any item or to refuse to provide you with any item. You may be required to verify your transaction information prior to our acceptance thereof.
                    <br/><br/>Notwithstanding these Terms, the laws in your country may apply to the purchase of virtual goods and services supplied by CBM to you and you may have rights or remedies as set out in such laws that apply in addition to, or, to the extent that they are inconsistent, instead of, the rights or remedies set out in these Terms.
                    <br/><br/>

                    <h5>5.	CODE OF CONDUCT</h5><br/>

                    You will not use the Services in any manner that, in CBM’s sole discretion, is unlawful or could damage, disable, overburden, or impair the Services or interfere with CBM’s or any others’ use and enjoyment of the Services. Without limiting the generality of the foregoing, prohibited conduct includes the following, subject to applicable law:
                    <br/><br/>
                    <ul>
                        <li>Using any hacks, cracks, bots, or third-party software that may modify, temporarily or
                            permanently, the code or the user experience of the Services, whether locally on your device
                            or on servers, or using any application, software or technology that is not expressly
                            authorized by us that enables cheating, power-leveling, or accomplishing game tasks that
                            cannot be accomplished without the use of such an application, software or technology.
                        </li>
                        <li>Reverse engineering, deriving source code, modifying, decompiling, disassembling the
                            Services or any portion thereof, or otherwise determining or attempting to determine any
                            source code, algorithms, methods or techniques used or embodied in the Services.
                        </li>
                        <li>Impersonating any person or entity or otherwise misrepresenting your affiliation,
                            identity,
                            or the origin of materials you transmit, or misrepresenting that your statements or actions
                            are endorsed by CBM.
                        </li>
                        <li>Using any robot, spider, site search/retrieval application or other manual or automatic
                            device or process to retrieve, index, data mine, or in any way reproduce or circumvent the
                            navigational structure or presentation of the Services, or any content contained
                            therein.
                        </li>
                        <li>Removing, altering, or concealing any copyright, trademark, patent or other proprietary
                            notices, legends, symbols, or labels (including any watermark or other digital rights
                            management technology or other information) contained on or within the Services.
                        </li>
                        <li>Taking any action that imposes an unreasonable or disproportionately large load on our
                            network or infrastructure.
                        </li>
                        <li>Hosting, providing, or developing matchmaking services for the Services without CBM’s
                            prior
                            written approval or creating, using, or maintaining any unauthorized connections to the
                            Services.
                        </li>
                        <li>Intercepting, emulating, or redirecting the communication protocols used by CBM or its
                            designees in any way, including without limitation through protocol emulation, tunneling,
                            packet sniffing, modifying or adding components to software, use of a data mining utility
                            program to intercept, collect, read or mine information generated by the Services, or in any
                            way utilize a technique now known or hereafter developed that would allow for or otherwise
                            make available unauthorized access or use of the Services.
                        </li>
                        <li>Using or attempting to use any viruses, malware, or any other computer code, files,
                            programs, software, routine, or device designed to interrupt, destroy, or limit the
                            functionality or proper working of the Services or CBM’s systems or networks, including by
                            engaging in, instigating, or facilitating any denial of service attack or similar conduct,
                            or attempt to probe, scan, test the vulnerability of, or breach the security of any system
                            or network.
                        </li>
                        <li>Using macros, auto-looting or robot play, or any other behavior that allows you (or any
                            character you are controlling) to automatically function or effect any action in a game with
                            or without your presence.
                        </li>
                        <li>Creating more than one account within twenty-four hours or more than five accounts within
                            thirty days, or otherwise exceeding your authorized access to any portion of the Services or
                            any database, computer, or device.
                        </li>
                        <li>Distributing unauthorized materials or advertising or promoting goods or services without
                            our permission (including, without limitation, by sending spam, promotional materials, junk
                            mail, chain letters, pyramid schemes or any other form of unauthorized solicitation).
                        </li>
                        <li>Engaging in conduct that is harmful, harassing, defamatory, vulgar, obscene, hateful,
                            threatening, abusive, inflammatory, intimidating, discriminatory, violent or encouraging of
                            violence (including self-harm), stalking, sexually explicit, or otherwise objectionable in
                            CBM’s sole discretion, including without limitation looting, kill stealing, making sexual
                            comments, or cursing.
                        </li>
                        <li>Selling, advertising, or posting information on hacks, private servers (including sources
                            thereof), or gold farming for the Services.
                        </li>
                        <li>Creating, utilizing, or transacting in any in-game item created or copied by exploiting a
                            design flaw, undocumented problem, or program bug.
                        </li>
                        <li>Publishing, or sharing in any way, the personal information of other users, or any
                            material, non-public information about companies without the authorization to do so.
                        </li>
                        <li>Engaging in any other conduct that, in CBM’s sole judgment, exposes us, any of our users,
                            or any other third party to any liability, damages, or detriment of any type.
                        </li>
                    </ul>
                    <br/><br/>Violations of system or network security or attempts to disrupt or undermine the operation of the Services may result in civil or criminal liability. We may investigate and work with law enforcement authorities to prosecute users who violate the Terms.
                    <br/><br/>WE MAY WITHHOLD, SUSPEND, MODIFY, OR TERMINATE YOUR ACCESS TO THE SERVICES FOR ANY OR NO REASON AT ANY TIME WITHOUT NOTICE UNLESS OTHERWISE REQUIRED BY APPLICABLE LAW. If your account is suspended while you are participating in a tournament or competition, CBM assumes no liability in the loss of any potential prizes or recognition related to the tournament or competition.

                    <br/><br/>


                    <h5>6.	THIRD-PARTY SERVICES, CONTENT, DEVICES, AND NETWORKS</h5><br/>

                    The Services may be made available to you through third-party services, such as Apple iTunes or Google Play, or devices, such as third-party consoles.  The Services also may link to or include third-party services or content (including without limitation User Content posted in forums). We do not control, endorse, sponsor, recommend, or otherwise accept responsibility for such third-party services, content, or devices. These third parties may require you to install additional software, register for additional accounts, agree to additional terms and conditions, or take other actions before using the Services. Use of any third-party services, content, or devices is at your own risk and is subject to the third party’s terms and conditions. Under no circumstances will CBM be responsible or liable in connection with your reliance on or use of third-party services, content, or devices.
                    <br/><br/>When using our Services, your device may connect to or utilize third-party networks, and you may incur fees based on your use of those networks. You are solely responsible for any and all costs and fees in connection with accessing and using the Services, including without limitation internet service provider fees, telecommunications fees, text messaging fees, excess broadband fees, and the costs of any and all devices and equipment used in connection with the Services.
                    <br/><br/>If the Services are being made available to you through the PlayStation™Store, the following additional terms will apply to you:
                    <br/><br/>For purchases in PlayStation™Store in North America
                    <br/><br/>Purchase and use of items are subject to the Network Terms of Service and User Agreement. This online service has been sublicensed to you by Sony Interactive Entertainment America.
                    <br/><br/>For purchases in PlayStation™Store in Europe:
                    <br/><br/>Any content purchased in an in-game store will be purchased from Sony Interactive Entertainment Network Europe Limited (“SIENE”) and be subject to PlayStation™Network Terms of Service and User Agreement which is available on the PlayStation™Store. Please check usage rights for each purchase as these may differ from item to item. Unless otherwise shown, content available in any in-game store has the same age rating as the game.
                    <br/><br/>For purposes of the foregoing Sections VI.A and VI.B, (i) “item,” “items,” and “content” mean the Services, and any virtual items, virtual currency, points, or any other content or information from the Services, the access and use of which is governed by these Terms notwithstanding anything to the contrary, (ii) “online service” means the PlayStation™Store, and (iii) “Network Terms of Service and User Agreement” means the PlayStation™Network Terms of Service and User Agreement that governs your purchases in the PlayStation™Store. In the event of any conflict between the Network Terms of Service and User Agreement and these Terms regarding your access and use of the Services, these Terms will control.
                    <br/><br/>


                    <h5>7.	DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h5><br/>

                    CBM AND ITS DEVELOPERS AND SERVICE PROVIDERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, ACCURACY, FITNESS, PERFORMANCE, OR INTEROPERABILITY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND ANY CONTENT CONTAINED WITHIN THE SERVICES FOR ANY PURPOSE. YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. CBM AND ITS DEVELOPERS AND SERVICE PROVIDERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you.
                    <br/><br/>YOU SPECIFICALLY AGREE THAT In no event will CBM, OUR REPRESENTATIVES, MANAGERS, PARTNERS, SHAREHOLDERS, JOINT VENTURERS, THIRD-PARTY CONTRACTORS, EMPLOYEES, LICENSEES, LICENSORS, ADVERTISERS, OR AGENTS be liable for any direct or indirect, special, incidental, consequential, or punitive damages, lost profits, or other damages whatsoever arising in connection with the use of THE SERVICES, any interruption in availability of THE SERVICES, delay in operation or transmission, computer virus, loss of data, or use, misuse, reliance, review, manipulation, or other utilization in any manner whatsoever of THE SERVICES or the data collected through THE SERVICES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, even if WE HAVE been advised of the possibility of such damages or loss. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL, INCIDENTAL, OR OTHER FORMS OF DAMAGES, SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES, OR WITH ANY OF THE TERMS OF THE APPLICABLE AGREEMENTS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICES. THE MAXIMUM AGGREGATE LIABILITY OF CBM FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, SHALL BE THE GREATER OF $100 OR THE AMOUNT YOU PAID TO ACCESS THE SERVICES, TO THE EXTENT PERMITTED BY APPLICABLE LAW.
                    <br/><br/>

                    <h5>8.	INDEMNIFICATION</h5><br/>
                    You agree to indemnify, defend and hold CBM, ALONG WITH OUR REPRESENTATIVES, MANAGERS, PARTNERS, SHAREHOLDERS, JOINT VENTURERS, THIRD-PARTY CONTRACTORS, EMPLOYEES, LICENSEES, LICENSORS, ADVERTISERS, AND AGENTS, harmless from and against any and all loss, costs, expenses (including reasonable attorneys’ fees and expenses), claims, damages and liabilities related to or associated with your use of our SERVICES and any alleged violation by you of these Terms. We reserve the right to assume the exclusive defense of any claim for which We are entitled to indemnification under this section. In such event, you shall provide us with such cooperation as we reasonably request.
                    <br/><br/>

                    <h5>9.	GOVERNING LAW</h5><br/>
                    Without regard to principles of conflicts of law, you agree that the application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded, regardless of your place of residence. To the fullest extent permitted by law, you agree that these Terms will be construed in accordance with, and were entered into under, the laws of the country specified in the table below, based on which CBM entity provides the relevant Services. You agree to submit to the personal and exclusive jurisdiction of the courts located within the specified forum:
                    <br/><br/>
                    CBM Interactive Inc.
                    <br/><br/>Seoul Central District Court of Korea, South Korea
                    <br/><br/>

                    <h5>10.	DISPUTE RESOLUTION</h5><br/>
                    If a dispute arises between you and CBM, our goal is to provide you with a neutral and cost-effective means of resolving the dispute quickly. Accordingly, you and CBM agree to resolve any claim or controversy at law or in equity that arises from or relates to this Agreement or our Service (a "Claim") in accordance with subsection below.
                    <br/><br/>
                    <ul>
                        <li>
                            This Agreement and the relationship between you and CBM shall be governed in all respects by the laws of the Republic of Korea without regard to conflict of law principles or the United Nations Convention on the International Sale of Goods.
                        </li>
                    </ul>
                    <br/><br/>

                    <h5>11.	MISCELLANEOUS</h5><br/>

                    Entire Agreement. These Terms constitute the entire agreement between you and us, superseding any prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us.
                    <br/><br/>Relationship of Parties. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or your access to and use of the Services.
                    <br/><br/>Severability. If any provision of these Terms is found to be illegal or unenforceable, that provision will be severed. The remainder of the Terms will remain in full force and effect. The severed provision will be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision.
                    <br/><br/>Assignment. This agreement may not be assigned, in whole or part, whether voluntarily, by operation of law, or otherwise, by you without the prior written consent of CBM. CBM may assign, license, delegate or otherwise transfer its rights or obligations hereunder to any third party without restriction. Subject to the preceding sentences, the rights and liabilities of the parties hereto are binding on, and shall inure to the benefit of, the parties and their respective successors and permitted assigns. Any attempted assignment other than in accordance with this Section shall be null and void.
                    <br/><br/>Captions and Headings. The captions and section and paragraph headings used in the Terms are inserted for convenience only and shall not affect the meaning or interpretation of the Terms.
                    <br/><br/>Waiver. Our failure to enforce any provisions of these Terms or respond to a violation by any party does not waive our right to subsequently enforce any terms or conditions of the Terms or respond to any violations.
                    <br/><br/>

                    <h5>12.	CONTACT US</h5><br/>
                    If you have questions about these Terms, please contact us as follows:
                    <br/><br/>Contact Information
                    <div style={{
                        paddingLeft: 30
                    }}>
                        <br/>CBM Interactive Inc.
                        <br/><br/>#268, 8, Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Zip code :  16837, Republic of Korea
                        <br/><br/>privacy@cbminteractive.com
                    </div>

                    <br/><br/>
                </div>
            </Col>
        </Row>
    );
}

export default TermOfServiceBody;