import React from "react";
import {main_thumb_001} from "../../const/ConstImages";
import {NoticeItems} from "../announcement/notice_types";
import {Link} from "react-router-dom";
import {_BASE_IMG_URL, _BASE_UPLOADED_IMG_URL} from "../../const/ConstBase";
import paths from "../../router/paths";

interface Prop {
    item: NoticeItems;
    type: string;
}

const MainNoticeItemWithImage: React.FC<Prop> = (prop: Prop) => {
  let url = encodeURI(paths.ANNOUNCEMENT_DETAIL_PATH + '/' + prop.item.n_seq + '/' + prop.item.n_title);
    return(
        <>
            <Link to={`${url}`} style={{textDecoration: "none"}}>
                <div id="mainnew" style={{backgroundColor: "white"}}>
                    <div
                        style={{
                            height: 269,
                            backgroundImage: `url(${_BASE_UPLOADED_IMG_URL + prop.item.n_summary_image})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    />
                    <div
                        className="text-center"
                        style={{
                            fontSize: 21,
                            fontFamily: '"Source Sans Pro", sans-serif',
                            paddingTop: 10,
                            paddingRight: 20,
                            paddingBottom: 40,
                            paddingLeft: 20
                        }}
                    >
                              <div
                                  id="noticetext"
                                  className="p2"
                                  style={{ paddingRight: 20, paddingLeft: 20,textDecoration: "none", fontWeight: "bold" }}
                              >
                                [{prop.item.reg_date}]&nbsp;
                                  {prop.item.n_title}
                              </div>
                        <div
                            id="noticetext2"
                            className="p4"
                            style={{ paddingRight: 50, paddingLeft: 50, marginTop: 5 }}
                        >{prop.item.n_title_summary}</div>
                    </div>
                </div>
            </Link>
        </>
    );
}

export default MainNoticeItemWithImage;