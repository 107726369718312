import React from "react";
import "./CmmnHeader.css";
import {Col, Container, Row} from "react-bootstrap";

type HeaderProps = {
    backgroundImg: string;
    title: string;
    subTitle?: string;
}

export const CmmnHeader = (data: HeaderProps) => {
    const {backgroundImg, title} = data;
    return(
        <Container fluid="true">
            <Row>
                <Col md={12} style={{
                    background: `url(${backgroundImg}) top / cover no-repeat`,
                    height: 260,
                }}>
                    <div className="title">{title}</div>
                    {data.subTitle && <div className="subTitle">{data.subTitle}</div>}
                </Col>
            </Row>

        </Container>
    )
}