import React, {useEffect, useState} from "react";
import {
    shop_box_1000M_off, shop_box_1000M_on, shop_box_11000M_off, shop_box_11000M_on,
    shop_box_2000M_off, shop_box_2000M_on,
    shop_box_500M_off, shop_box_500M_on,
    shop_box_5250M_off, shop_box_5250M_on
} from "../../const/ConstImages";
import {Col, Row} from "react-bootstrap";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import {useNavigate} from "react-router-dom";
import {api} from "../../services/api";
import {AxiosError} from "axios";
import Loading from "../common/Loading";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./ShopBody.css";
import {_BUILD_ENV} from "../../const/ConstBase";
import TermOfServiceModal from "../login/TermOfServiceModal";
import {numberWithCommas} from "../../utils/commonUtils";
import paths from "../../router/paths";
import {ActiveCoinItems} from "./type_types";

export const ShopBody = () => {
    const nav = useNavigate();
    const [balance, setBalance] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [agree, setAgree] = React.useState(_BUILD_ENV !== "live");
    const [showPopup, setShowPopup] = React.useState(false);
    const [widgetUrl, setWidgetUrl] = React.useState("about:blank");
    const [touShow, setTouShow] = useState(false);
    const [activeSaleItem, setActiveSaleItem] = useState<ActiveCoinItems[]>([]);

    async function getBalance() {
        try {
            const response = await api.post('/account/balance');
            return response.data;
        } catch (error) {
            return error as AxiosError;
        }
    }
    async function getPurchaseWidget(id: string) {
        try {
            const response = await api.post('/shop/widget', {itemId: id});
            return response.data;
        } catch (error) {
            return error as AxiosError;
        }
    }
    async function getActiveSaleItem() {
        try {
            const response = await api.post('/shop/sales_items');
            return response.data;
        } catch (error) {
            return error as AxiosError;
        }
    }

    useEffect(() => {
        getBalance().then((currentBalance) => {
            if(isNaN(currentBalance)) {
                setBalance(0);
            } else {
                setBalance(currentBalance);
            }
        }).catch((err) => {
        });

        getActiveSaleItem().then((res) => {
            if(res.data && res.data.length > 0) {
                setActiveSaleItem(res.data);
            } else {
                setActiveSaleItem([]);
            }
        }).catch((err) => {
        });
    }, []);

    function goPurchaseHistory() {
        nav(paths.TOPUP_COIN_HISTORY_PATH);
    }

    function clickPurchase(item: string) {
        if(!agree) {
            alert("Please check the Purchase Agreement.");
            return;
        }
        setLoading(true);
        getPurchaseWidget(item).then((res) => {
            // if(res.data.result === "OK") {
            if(res.data && res.data.result === "OK") {
                setWidgetUrl(res.data.url);
                setShowPopup(true);
                setLoading(false);
            } else {
                setShowPopup(false);
                setLoading(false);
                switch(res.message) {
                    case "BLOCK_USER":
                        alert("You are blocked.");
                        break;
                    case "BLOCK_IP":
                        alert("Your IP is blocked.");
                        break;
                    case "NOT_EXISTS_ITEM":
                        alert("Not exists item.");
                        break;
                    case "NOT_EXISTS_USER":
                        alert("Not exists user.");
                        break;
                    default:
                        alert("Paymentwall is currently experiencing difficulties, please check back later");
                }
                window.location.reload();
            }
        });
    }

    const handleChange = (e: any) => {
        setAgree(e.target.checked);
    }
    return(
        <>
            <div style={{backgroundColor: "white"}}>
                <div style={{width: "100%", height: 40}}></div>
                <Row className="justify-content-center">
                    <Col md={10}>
                        {_BUILD_ENV !== "live" ?
                            <>
                                <span style={{
                                    fontSize: 16,
                                }}>
                                    By placing your order, you agree to CBM Interactive's
                                    <span
                                        style={{cursor: "pointer", textDecoration: "underline", fontWeight: "bold"}}
                                        onClick={() => {setTouShow(true)}}>
                                        &nbsp;Terms of Service and End User License Agreement.
                                    </span>
                                </span>
                            </>
                            :
                            <>
                                <FormCheckInput type="checkbox" id="autoSizingCheck2" onChange={handleChange}
                                               checked={agree}
                                />&nbsp;
                                Purchase Agreement <br/>
                                <span style={{
                                    fontSize: 11,
                                }}>
                                    I consent to the payment and use of the service. And by accepting the EULA. I waive my right to a refund or withdrawal.
                                </span>
                            </>
                        }
                    </Col>
                    <div style={{width: "100%", height: 40}}></div>
                    <Col md={10}>
                        <div style={{width: "100%", textAlign: "right"}}>
                            M-Coin Balance: {numberWithCommas(balance)}
                            <button style={{
                                backgroundColor: "#4C2426",
                                border: "none",
                                width: 200,
                                height: 30,
                                marginLeft: 20,
                                color: "white",
                            }} onClick={goPurchaseHistory}>
                                Purchase History
                            </button>
                        </div>
                    </Col>
                    <div style={{width: "80%", height: 5, borderBottom: "#efefef 1px solid"}}></div>
                </Row>
                <Row className="justify-content-center">
                    {activeSaleItem && activeSaleItem.length > 0 && activeSaleItem.map((item, index) => {
                        let logo_off_url = item.logo_url.replace("-on", "-off");
                        return(
                            <Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30, cursor: "pointer"}} key={index}>
                                <img src={logo_off_url} alt={item.item_name} style={{width: "100%"}}
                                     onMouseOver={e => (e.currentTarget.src = item.logo_url)}
                                     onMouseOut={e => (e.currentTarget.src = logo_off_url)}
                                     onClick={() => {clickPurchase(item.item_id);}}
                                />
                            </Col>
                        );
                    })}
                    {/*<Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30}}>*/}
                    {/*    <img src={shop_box_500M_off} alt="shop_box_500M_off" style={{width: "100%"}}*/}
                    {/*        onMouseOver={e => (e.currentTarget.src = shop_box_500M_on)}*/}
                    {/*        onMouseOut={e => (e.currentTarget.src = shop_box_500M_off)}*/}
                    {/*        onClick={() => {clickPurchase("p500");}}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30}}>*/}
                    {/*    <img src={shop_box_1000M_off} alt="shop_box_1000M_off" style={{width: "100%"}}*/}
                    {/*            onMouseOver={e => (e.currentTarget.src = shop_box_1000M_on)}*/}
                    {/*            onMouseOut={e => (e.currentTarget.src = shop_box_1000M_off)}*/}
                    {/*         onClick={() => {clickPurchase("p1000");}}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30}}>*/}
                    {/*    <img src={shop_box_2000M_off} alt="shop_box_2000M_off" style={{width: "100%"}}*/}
                    {/*            onMouseOver={e => (e.currentTarget.src = shop_box_2000M_on)}*/}
                    {/*            onMouseOut={e => (e.currentTarget.src = shop_box_2000M_off)}*/}
                    {/*         onClick={() => {clickPurchase("p2000");}}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30}}>*/}
                    {/*    <img src={shop_box_5250M_off} alt="shop_box_5250M_off" style={{width: "100%"}}*/}
                    {/*            onMouseOver={e => (e.currentTarget.src = shop_box_5250M_on)}*/}
                    {/*            onMouseOut={e => (e.currentTarget.src = shop_box_5250M_off)}*/}
                    {/*         onClick={() => {clickPurchase("p5250");}}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*<Col md={2} sm={8} style={{maxWidth: 400, paddingTop: 30}}>*/}
                    {/*    <img src={shop_box_11000M_off} alt="shop_box_11000M_off" style={{width: "100%"}}*/}
                    {/*            onMouseOver={e => (e.currentTarget.src = shop_box_11000M_on)}*/}
                    {/*            onMouseOut={e => (e.currentTarget.src = shop_box_11000M_off)}*/}
                    {/*         onClick={() => {clickPurchase("p11000");}}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>
                <div style={{width: "100%", height: 200}}></div>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={"static"}
                    onHide={() => setShowPopup(false)}
                    show={showPopup}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                           Purchase M-Coin
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { loading && <Loading />}
                       <iframe src={widgetUrl} style={{width: "100%", height: 700}}/>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <TermOfServiceModal isShow={touShow} setShow={setTouShow} />
            </div>
        </>
    )
}