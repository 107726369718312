import React from "react";
import "./WidgetTransactionHistory.css";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faRightToBracket, faUnlockKeyhole, faWallet} from "@fortawesome/free-solid-svg-icons";
import {TransactionHistoryData} from "../profile_types";
import {numberWithCommas} from "../../../utils/commonUtils";
import {useNavigate} from "react-router-dom";
import paths from "../../../router/paths";

export const WidgetTransactionHistory: React.FC<TransactionHistoryData> = ({balance, transaction_log}) => {
    const navigate = useNavigate();

    const navigateToShop = () => {
        navigate(paths.SHOP_PATH);
    }
    const navigateToTransactionHistory = () => {
        navigate(paths.TRANSACTION_HISTORY_PATH);
    }
    return (
        <Col md={12}>
            <div style={{
                backgroundColor: "#DEE0E7",
                height: 50,
                width: "100%",
                marginTop: 10,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{
                    padding: "10px 5px 20px 10px",
                    minHeight: "50px",
                    height: "auto",
                    borderBottom: "1px solid #DEE0E7",
                    cursor: "pointer",
                }} onClick={navigateToTransactionHistory}>
                    <div style={{
                        float: "left",
                        paddingRight: 20,
                    }}>
                        <FontAwesomeIcon icon={faWallet} size="lg" />
                    </div>
                    <div style={{
                        width: "calc(100% - 70px)",
                        float: "left",
                        fontSize: "16px",
                        textAlign: "left",
                    }}>
                        In-game Purchase History
                    </div>
                    <div style={{
                        float: "left",
                        width: "20px",
                    }}>
                        <FontAwesomeIcon icon={faRightToBracket} size="xs" />
                    </div>
                </div>
            </div>
            <div style={{
                paddingLeft: 20,
                paddingRight: 20,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{width: "100%", height: 20}}></div>
                <div style={{width: "100%", height: 80}}>
                    <div style={{
                        backgroundColor: "#DEE0E7",
                        borderRadius: "50%",
                        height: 50,
                        paddingTop: 12,
                        width: 50,
                        float: "left",
                    }} className="col-md-2">
                        <FontAwesomeIcon icon={faDollarSign} size="lg" />
                    </div>
                    <div style={{textAlign: "left", float: "left", marginLeft: 20}}  className="col-md-8">
                        Current Balance<br /><span style={{fontWeight: "bold", fontSize: 24}}>{numberWithCommas(balance)}</span>
                    </div>
                </div>

                <div style={{
                    paddingBottom: 10,
                }}>
                    <button style={{
                        backgroundColor: "#DEE0E7",
                        borderRadius: 10,
                        border: "none",
                        width: "90%",
                        height: 40,
                        marginLeft: -20,
                    }} onClick={navigateToShop}>
                        Buy Coin
                    </button>
                </div>

                <hr />

                <div>
                    <Row fluid="true">
                        <Col md={4} xs={4} style={{fontWeight: "bold", fontSize: 13, backgroundColor: "#DEE0E7"}}>DATE</Col>
                        <Col md={4} xs={4} style={{fontWeight: "bold", fontSize: 13, backgroundColor: "#DEE0E7"}}>ITEM</Col>
                        <Col md={4} xs={4} style={{fontWeight: "bold", fontSize: 13, backgroundColor: "#DEE0E7"}}>PRICE</Col>
                    </Row>
                    { transaction_log.map((data, index) => {
                        return (
                            <Row fluid="true" key={index}>
                                <Col md={4} xs={4} style={{fontSize: 11}}>{data.reg_date}</Col>
                                <Col md={4} xs={4} style={{fontSize: 11}}>{data.item_name}</Col>
                                <Col md={4} xs={4} style={{fontSize: 11}}>{numberWithCommas(data.item_price)}</Col>
                            </Row>
                        );
                    })}
                </div>

            </div>
        </Col>
  );
}