import React from "react";
import {down_btn, Sub_top_001} from "../../const/ConstImages";

import "./DownloadHeader.css";
import paths from "../../router/paths";
export const DownloadHeader = () => {
    const clickDownload = () => {
        window.location.href = paths.DOWNLOAD_INSTALLER_PATH;
    }
    return(
        <div className="sub_top_image_download" style={{
            background: `url(${Sub_top_001}) no-repeat center center`,
            height: 450,
        }}>
            <div className="text_content">Your Journey Begins!</div>
            <div className="download_client_button">
                <img src={down_btn} alt="download-client-button" onClick={clickDownload} />
            </div>
        </div>
    );
}