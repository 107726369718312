import { AxiosError } from 'axios'
import { createContext } from 'react'

export type User = {
    email: string
    lang: string
    nickname: string
    last_update_password: string
    permissions: string[]
    roles: string[]
}

export type PendingUser = {
    email: string
}

export type SignInCredentials = {
    email: string
    password: string
    captcha: string
}

export type SignUpCredentials = {
    email: string
    nickname: string
    password: string
    passwordConfirm: string
    allowNewsletter: string
    captcha: string
}

export type ChangePasswordCredentials = {
    password: string
    newPassword: string
    newPasswordConfirm: string
}

export type TransactionHistoryRequest = {
    start_date: string
    end_date: string
    page: number
}

export type SignUpResponse = {
    res_code: string
    res_msg: string
}
export type AuthContextData = {
    user?: User
    pendingUser?: PendingUser
    isAuthenticated: boolean
    loadingUserData: boolean
    signIn: (credentials: SignInCredentials) => Promise<String | AxiosError>
    signUp: (credentials: SignUpCredentials) => Promise<SignUpResponse | AxiosError>
    signOut: () => void
    contactLink: string
}

export type RegisterEpinRequest = {
    ep_code: string
    ep_serial: string
    captcha: string
}
const AuthContext = createContext({} as AuthContextData)

export default AuthContext
