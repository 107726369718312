import React, {useEffect} from "react";
import useSession from "../hooks/useSession";
import {useNavigate} from "react-router-dom";
import paths from "../router/paths";

export const LogoutScreen = () => {
    const { user, signOut } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        signOut();
        navigate(paths.ROOT_PATH);
    }, []);
    return (
        <></>
    )
}