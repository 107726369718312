
let buildEnv = process.env.REACT_APP_ENV ?? '';

let base_img_url = "";
let base_cache_ver = "";
let base_url = "";
let base_uploaded_img_url = "";
let captcha_site_key = "";

if (buildEnv === 'live') {
    base_img_url = "https://img.cbmgames.com/www/";
    base_uploaded_img_url = "https://img.cbmgames.com/";
    base_cache_ver = "0.1";
    base_url = "https://external-api.cbmgames.com/api/v1/";
    captcha_site_key = "6LdPGKYoAAAAANjEpCpCZmiKLtTmfyA2aRckn-Ee";
} else if(buildEnv === 'qa') {
    base_img_url = "https://img.cbmgames.com/www_test2/";
    base_uploaded_img_url = "https://img.cbmgames.com/";
    base_cache_ver = "0.2";
    base_url = "https://external-api.cbmgames.org/api/v1/";
    captcha_site_key = "6LdkX4goAAAAANQN8-O5Jcxv5rHZP7mMExP-lzcz";
} else {
    base_img_url = "https://img.cbmgames.com/www_test2/";
    base_uploaded_img_url = "https://img.cbmgames.com/";
    base_cache_ver = "0.2";
    base_url = "http://localhost:8080/api/v1/";
    captcha_site_key = "6Lf8lCcoAAAAAJSuN6kE3GMMMpUdXWTddJTxi4Kn";
}


export const _BASE_IMG_URL: string = base_img_url
export const _BASE_UPLOADED_IMG_URL: string = base_uploaded_img_url
export const _BASE_CACHE_VER: string = base_cache_ver
export const _BASE_URL: string = base_url
export const _BUILD_ENV: string = buildEnv

export const _CAPTCHA_SITE_KEY: string = captcha_site_key

//qa
//https://external-api.cbmgames.org/api/v1/


export const TOKEN_COOKIE = 'cbm.token'
export const REFRESH_TOKEN_COOKIE = 'cbm.refreshToken'

// export const _GMT_TIMEZONE = 'EST'
export const _GMT_TIMEZONE = 'EDT'
export const _FRESHDESK_SUB_DOMAIN = 'aika-online';

export const _FRESHDESK_REDIRECT_DOMAIN = 'cbminteractiveinc-630275215186477089.myfreshworks.com';
export const _FRESHDESK_LOGOUT_URL = "https://aikaonlineglobal-help.freshdesk.com/logout";