import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {WidgetAccountInfo} from "./widget/WidgetAccountInfo";
import {WidgetAccount} from "./widget/WidgetAccount";
import {WidgetRecentLogin} from "./widget/WidgetRecentLogin";
import {WidgetTransactionHistory} from "./widget/WidgetTransactionHistory";
import {api} from "../../services/api";
import {AxiosError} from "axios/index";
import {RecentLoginDataArr} from "./profile_types";

export const ProfileBody = () => {
    const [recentLogin, setRecentLogin] = React.useState<RecentLoginDataArr>([]);
    const [balance, setBalance] = React.useState(0);
    const [transactionHistory, setTransactionHistory] = React.useState({
        balance: 0,
        transaction_history: [],
    });
    const [registerDate, setRegisterDate] = React.useState("")
    const [passwordChangeDate, setPasswordChangeDate] = React.useState("")

    async function getInfo() {
        try {
            const response = await api.get('/account/info');
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    useEffect(() => {
        getInfo().then((res) => {
            if(res && res.data) {
                let loginList = res.data.login_history || [];
                if(loginList.length > 2) {
                    loginList = loginList.slice(0, 2);
                }
                setRecentLogin(loginList);
                setBalance(res.data.balance || 0);
                setTransactionHistory({
                    balance: res.data.balance || 0,
                    transaction_history: res.data.spent_history || [],
                });
                setRegisterDate(res.data.reg_date);
                setPasswordChangeDate(res.data.last_update_password);
            }
        }).catch((err) => {
            console.error(err);
        });
    }, []); // Add necessary variables or remove it as per your requirement

    return (
        <Row className="justify-content-center justify-content-between"
             style={{ borderTop: "2px solid #575F6C", marginTop: 33 }}
        >
            <WidgetAccount registerDate={registerDate} />
            <Col md={6}>
                <WidgetAccountInfo passwordChangeDate={passwordChangeDate}/>
                <WidgetRecentLogin datas={recentLogin} />
            </Col>
            <Col md={6}>
                <WidgetTransactionHistory balance={transactionHistory.balance} transaction_log={transactionHistory.transaction_history} />
            </Col>
            <div style={{height: 100}}></div>
        </Row>
    );
}