import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

type Props = {
    title: string;
    icon: any;
    link: string;
}
export const ProfileMenuItem = (props: Props) => {
    return (
        <div style={{
            padding: "10px 5px 20px 10px",
            minHeight: "50px",
            height: "auto",
            borderBottom: "1px solid #DEE0E7",
        }}>
            <Link to={props.link} style={{color: "black"}}>
                <div style={{
                    float: "left",
                    width: "30px",
                }}>
                    <FontAwesomeIcon icon={props.icon} size="lg" />
                </div>
                <div style={{
                    width: "calc(100% - 50px)",
                    float: "left",
                    fontSize: "16px",
                }}>
                    {props.title}
                </div>
                <div style={{
                    float: "left",
                    width: "20px",
                }}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                </div>
            </Link>
        </div>
  );
}