import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {chapter_1, chapter_2, chapter_3, Sub_map_thumb, Sub_overwiew_page_002} from "../../const/ConstImages";
import "./GuideMid.css";

export const GuideMid = () => {
    const [tab1, setTab1] = React.useState("");
    const [tab2, setTab2] = React.useState("");
    const [tab3, setTab3] = React.useState("");
    const [title, setTitle] = React.useState("A Vast Open World");
    const [content, setContent] = React.useState("Ranger is a ranged class that uses the longbow and dagger as her main weapons. She has skills that makes advangage of her dealskdjfakl. Ranger is a ranged class that uses the longbow and dagger as her main weapons. She has skills that makes advangage of her dealskdjfakl. Ranger is a ranged class that uses the longbow and dagger as her main weapons. She has skills that makes advangage of her dealskdjfakl");
    const [thumb, setThumb] = React.useState(Sub_map_thumb);

    const switchTab = (tab: number) => {
        switch (tab) {
            case 1:
                setTab1("on");
                setTab2("");
                setTab3("");
                setTitle("The Saga of Arcan");
                setContent("In the beginning, the goddess Aika crafted Arcan, a utopian world. She populated it with the peaceful Scinic, but after centuries of complacency, they grew restless and blamed Aika for their lack of purpose.  The goddess then created Humans, hoping their balance of good and evil would bring meaning to their lives. However, humanity's hatred and destruction threatened Arcan.  In response, Aika created the Zereca, powerful arbiters to judge and protect her creation.  But her new race turned bloodthirsty, endangering the Humans.  Aika, filled with remorse, guided the Humans to the floating land of Lakia to escape the Zereca.  She and the Zereca fell into slumber.  Three centuries passed, and the Humans thrived on Lakia.  Aika and the Zereca have started to awaken, signaling trouble for Arcan.  To protect the Humans, Aika appointed Aitan as her emissaries and created Pran, elemental familiars.");
                setThumb(chapter_1);
                break;
            case 2:
                setTab1("");
                setTab2("on");
                setTab3("");
                setTitle("Exodus");
                setContent("After cleansing Prison Despero, Lakia discovered that Alan Sinclair was the Zereca demon Apos, leading to a fierce battle and Apos' retreat.  However, the corruption of Horma had deeply penetrated Lakia's core, risking its descent back to Arcan.  Lakia Congress dispatched expeditions to find new islands for colonization.  Captain Roberto Dawdric rediscovered Traband, but it had become a frozen wasteland, defiled by Skadi.  The Lakian Congress and Lord Marshal decided to confront the Zereca threat in Traband to prevent its fate mirroring Lakia's.  A foothold was established in the city Epheso.  What the Aitan didn't know was that Skadi's power bound the fractured dimensions of Arcan, leading to resource scarcity and conflicts among former allies in this harsh new land.");
                setThumb(chapter_2);
                break;
            case 3:
                setTab1("");
                setTab2("");
                setTab3("on");
                setTitle("Descent");
                setContent("The Zereca's sinister plan to bring down the sky islands, humanity's last refuge, has succeeded.  Their demon constructs have corrupted the island cores.  Humanity must now descend to Arcan, a twisted wasteland, to create a safe haven.  You'll face the Zereca directly in epic battles.  Returning to Arcan, you'll explore a dangerous land and encounter new monsters. Apos awaits in a perilous dungeon.  Settling in the corrupted land becomes a challenge, leading to World PvP battles for vital resources.  To survive on Arcan's surface, Aitan must grow stronger, reaching level 85 with new skills and powerful gear.  Training grounds prepare Aitan for fierce battles, including obstacles.  The Aitan Arena offers opportunities throughout the day, allowing you to earn titles and rewards.  The fate of humanity now rests on your shoulders.");
                setThumb(chapter_3);
                break;
        }
    }

    useEffect(() => {
        switchTab(1);
    }, []);

    return(
        <Container fluid="true" id="loc_story">
            <Row>
                <Col style={{
                    background: `url(${Sub_overwiew_page_002}) no-repeat center center`,
                    height: 1012,
                }}>
                    <Row className="justify-content-center">
                        <Col sm={14} md={8} className="guide2">
                            <div className="guide2_sub_title">II.  Story</div>
                            <div className="guide2_title">THE STORY OF AIKA ONLINE</div>
                            <div className="chapters">
                                <Row className="justify-content-center">
                                    <Col md={3}>
                                        <div className={`chapter ${tab1}`}
                                            onClick={() => {
                                                switchTab(1);
                                            }}
                                        >Chapter. 1</div>
                                    </Col>
                                    <Col md={3}>
                                        <div className={`chapter ${tab2}`}
                                            onClick={() => {
                                                switchTab(2);
                                            }}
                                        >Chapter. 2</div>
                                    </Col>
                                    <Col md={3}>
                                        <div className={`chapter ${tab3}`}
                                            onClick={() => {
                                                switchTab(3);
                                            }}
                                        >Chapter. 3</div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="book_title">{title}</div>
                            <div className="book_content">{content}</div>
                            <div id="chap_image" style={{
                                textAlign: "center",
                                background: `url(${thumb}) no-repeat center center`,
                                width: "100%",
                                height: 183
                            }}>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}