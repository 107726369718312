import React, {useEffect} from "react";
import {DownloadHeader} from "../components/download/DownloadHeader";
import {DownloadBody} from "../components/download/DownloadBody";
import {Helmet} from "react-helmet-async";

export const DownloadScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>Download!</title>
                <meta name="description" content="" />
            </Helmet>
            <DownloadHeader />
            <DownloadBody />
        </>
    )
}