import React, {useEffect, useState} from "react";
import "./GuideBottom.css";
import {
    c_Cleric,
    c_DualGunners,
    c_Paladins,
    c_princess,
    c_Rifleman,
    c_tab_Cleric_off,
    c_tab_Cleric_on,
    c_tab_DualGunners_off,
    c_tab_DualGunners_on,
    c_tab_Paladins_off,
    c_tab_Paladins_on,
    c_tab_princess_hold,
    c_tab_Rifleman_off,
    c_tab_Rifleman_on,
    c_tab_Warlocks_off,
    c_tab_Warlocks_on,
    c_tab_Warrior_off,
    c_tab_Warrior_on,
    c_Warlocks,
    c_Warrior,
    Sub_overwiew_page_001,
    Sub_overwiew_page_003,
    job_screenshot,
    warlock_thumb,
    warrior_thumb,
    paladin_thumb,
    riflemen_thumb,
    dual_gunner_thumb,
    cleric_thumb,
    list_thumb_warrior,
    list_thumb_paladin,
    list_thumb_riflemen,
    list_thumb_dual_gunner, list_thumb_cleric, list_thumb_warlock
} from "../../const/ConstImages";
import {Col, Container, Row} from "react-bootstrap";
import ModalImage from "react-modal-image";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export const GuideBottom = () => {
    const [currentTab, setCurrentTab] = React.useState(1);
    const [currentJob, setCurrentJob] = React.useState("");
    const [currentSubTitle, setCurrentSubTitle] = React.useState("");
    const [currentContent, setCurrentContent] = React.useState("");
    const [currentImage, setCurrentImage] = React.useState("");
    const [currentCharImage, setCurrentCharImage] = React.useState("");
    const [popupImages, setPopupImages] = React.useState<string[]>([]);

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const switchTab = (tab: number) => {
        if(currentTab === tab) {
            return;
        }

        switch (tab) {
            case 1:
                setCurrentJob("Warriors");
                setCurrentSubTitle("known as the \"Crusader\".");
                setCurrentContent("Warriors are powerful melee fighters known for their physical strength and durability.  They excel in close combat, wielding swords, axes, and other melee weapons to engage enemies in battle.  They are often the front-line tanks and damage dealers, with a focus on high health points (HP) and physical damage output.");
                setCurrentImage(warrior_thumb);
                setCurrentCharImage(c_Warrior);
                setPopupImages(list_thumb_warrior);
                break;
            case 2:
                setCurrentJob("Paladins");
                setCurrentSubTitle("known as the \"Templar\" in some regions.");
                setCurrentContent("Paladins are versatile hybrid characters capable of both tanking and healing roles.  They are often considered the backbone of a party due to their ability to protect their allies and provide healing support.  Paladins are characterized by Tanking Abilities, Healing Abilities, Melee Combat, Crowd Control, Blessings and Buffs and Versatility.");
                setCurrentImage(paladin_thumb);
                setCurrentCharImage(c_Paladins);
                setPopupImages(list_thumb_paladin);
                break;
            case 3:
                setCurrentJob("Riflemen");
                setCurrentSubTitle("known for their long-range combat abilities.");
                setCurrentContent("Riflemen specialize in dealing damage from a distance, making them a valuable asset in group battles and player versus environment (PvE) encounters.  Riflemen are characterized by Long-Range Attacks, High Damage Output, Crowd Control, Utility Skills, Critical Hits, Ranged AoE (Area of Effect), Squishy but Mobile and PvP Strength.");
                setCurrentImage(riflemen_thumb);
                setCurrentCharImage(c_Rifleman);
                setPopupImages(list_thumb_riflemen);
                break;
            case 4:
                setCurrentJob("Dual Gunners");
                setCurrentSubTitle("known for their dual-wielding firearms.");
                setCurrentContent("Dual Gunners specialize in delivering rapid, long-range attacks and excel in both player versus environment (PvE) and player versus player (PvP) scenarios.  Dual Gunners are characterized by Dual-Wielding Firearms, Rapid-Fire Attacks, High Mobility, Evasion and Dodging, Critical Hits, Crowd Control, Versatile Playstyle, Combo Attacks.");
                setCurrentImage(dual_gunner_thumb);
                setCurrentCharImage(c_DualGunners);
                setPopupImages(list_thumb_dual_gunner);
                break;
            case 5:
                setCurrentJob("Clerics");
                setCurrentSubTitle("known for its healing abilities and support skills");
                setCurrentContent("Cleric is a character class that specializes in support, healing, and protective abilities. Clerics are crucial members of any adventuring party, providing essential services to their allies, including healing, resurrection, and various buffs.  Clerics are characterized by Healing Abilities, Resurrection, Buffing, Debuff Removal, Protective Spells, Crowd Control and Versatile Playstyle.");
                setCurrentImage(cleric_thumb);
                setCurrentCharImage(c_Cleric);
                setPopupImages(list_thumb_cleric);
                break;
            case 6:
                setCurrentJob("Warlocks");
                setCurrentSubTitle("main AoE damage dealing class in Aika.");
                setCurrentContent("Warlocks excel in high magical damage, boasting strong AoE and single-target attacks, along with two shields.  They suffer from low physical defenses and a significant dependence on mana, especially due to their mana shield.  Primary attributes include Spirit for maximum MP, MP recovery, skill attack max, and status resist, and Intellect for magical attack, magical defense piercing, and cooldown reduction.");
                setCurrentImage(warlock_thumb);
                setCurrentCharImage(c_Warlocks);
                setPopupImages(list_thumb_warlock);
                break;
        }

        setCurrentTab(tab);
    }

    useEffect(() => {
        setCurrentJob("Warriors");
        setCurrentSubTitle("known as the \"Crusader\".");
        setCurrentContent("Warriors are powerful melee fighters known for their physical strength and durability.  They excel in close combat, wielding swords, axes, and other melee weapons to engage enemies in battle.  They are often the front-line tanks and damage dealers, with a focus on high health points (HP) and physical damage output.");
        setCurrentImage(warrior_thumb);
        setCurrentCharImage(c_Warrior);
        setPopupImages(list_thumb_warrior);
    }, []);
    return(
        <Container fluid="true" id="loc_character">
            <Row className="guide3">
                <Col style={{
                    background: `url(${Sub_overwiew_page_003}) no-repeat center center / cover`,
                    minHeight: 915,
                    height: "100%",
                }} className="guide3_bg">
                    <Row className="justify-content-center" style={{
                        height: "100%",
                        minHeight: 915,
                    }}>
                        <Col md={5} style={{
                            background: `url(${currentCharImage}) no-repeat top center`,
                            maxHeight: 965,
                            // backgroundSize: "100%",
                            minHeight: 500,
                        }} className="d-md-block">
                        </Col>
                        <Col md={7}>
                            <Row>
                                <Col md={7} className="top_sub_title">III. Classes</Col>
                                <Col md={7} className="top_big_title">CHARACTERS OF AIKA</Col>
                            </Row>
                            <Row>
                                <Col id="tab_container" style={{
                                    paddingTop: 90,
                                    minWidth: 400,
                                    maxWidth: 670
                                }}>
                                    <Row>
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 1 ? c_tab_Warrior_on : c_tab_Warrior_off} alt="c-tab-Warlocks-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(1);
                                                 }}
                                            />
                                        </Col>
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 2 ? c_tab_Paladins_on : c_tab_Paladins_off} alt="c-tab-Paladins-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(2);
                                                 }}
                                            />
                                        </Col>
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 3 ? c_tab_Rifleman_on : c_tab_Rifleman_off} alt="c-tab-Rifleman-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(3);
                                                 }}
                                            />
                                        </Col>
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 4 ? c_tab_DualGunners_on : c_tab_DualGunners_off} alt="c-tab-DualGunners-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(4);
                                                 }}
                                            />
                                        </Col>
                                        {/* 워락과 클레릭 이미지가 바뀌었다고 함. */}
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 5 ? c_tab_Cleric_on : c_tab_Cleric_off} alt="c-tab-Warlocks-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(5);
                                                 }}
                                            />
                                        </Col>
                                        <Col md={1} className="t_btn">
                                            <img src={currentTab == 6 ? c_tab_Warlocks_on : c_tab_Warlocks_off} alt="c-tab-Cleric-off" width="100%"
                                                 onClick={() => {
                                                     switchTab(6);
                                                 }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{
                                paddingTop: 30,
                            }}>
                                <Col md={10}>
                                    <Row>
                                        <Col md={12} className="job">{currentJob}</Col>
                                        <Col md={12} className="job_sub">{currentSubTitle}</Col>
                                        <Col md={12} className="job_desc">
                                            <Row>
                                                <Col md={6}>{currentContent}</Col>
                                                <Col md={6}>
                                                    <img src={currentImage} width="100%" onClick={() => setOpen(true)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal open={open} onClose={onCloseModal} center>
                <Carousel showArrows={true} showThumbs={false} >
                    {popupImages.map((image, index) => {
                        return (<div key={index}>
                            <img src={image} alt=""/>
                        </div>);
                    })}
                </Carousel>
            </Modal>

        </Container>
    )
}