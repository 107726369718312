import {_BASE_CACHE_VER, _BASE_IMG_URL} from './ConstBase';

export const test_11: string = "";
export const plus: string = _BASE_IMG_URL + 'plus.png?v=' + _BASE_CACHE_VER;
export const Sub_map_thumb: string = _BASE_IMG_URL + 'Sub-map-thumb.png?v=' + _BASE_CACHE_VER;
export const Sub_overwiew_movie: string = _BASE_IMG_URL + 'Sub-overwiew-movie.png?v=' + _BASE_CACHE_VER;
export const Sub_overwiew_page_001: string = _BASE_IMG_URL + 'Sub-overwiew-page-001.png?v=' + _BASE_CACHE_VER;
export const Sub_overwiew_page_002: string = _BASE_IMG_URL + 'Sub-overwiew-page-002.png?v=' + _BASE_CACHE_VER;
export const Sub_overwiew_page_003: string = _BASE_IMG_URL + 'Sub-overwiew-page-003.png?v=' + _BASE_CACHE_VER;
export const Sub_top_001: string = _BASE_IMG_URL + 'Sub-top-001.png?v=' + _BASE_CACHE_VER;
export const Sub_top_002: string = _BASE_IMG_URL + 'Sub-top-002.png?v=' + _BASE_CACHE_VER;
export const amd: string = _BASE_IMG_URL + 'amd.png?v=' + _BASE_CACHE_VER;
export const c_Cleric: string = _BASE_IMG_URL + 'c-Cleric.png?v=' + _BASE_CACHE_VER;
export const c_DualGunners: string = _BASE_IMG_URL + 'c-DualGunners.png?v=' + _BASE_CACHE_VER;
export const c_Paladins: string = _BASE_IMG_URL + 'c-Paladins.png?v=' + _BASE_CACHE_VER;
export const c_Rifleman: string = _BASE_IMG_URL + 'c-Rifleman.png?v=' + _BASE_CACHE_VER;
export const c_Warlocks: string = _BASE_IMG_URL + 'c-Warlocks.png?v=' + _BASE_CACHE_VER;
export const c_Warrior: string = _BASE_IMG_URL + 'c-Warrior.png?v=' + _BASE_CACHE_VER;
export const c_princess: string = _BASE_IMG_URL + 'c-princess.png?v=' + _BASE_CACHE_VER;
export const c_tab_Cleric_off: string = _BASE_IMG_URL + 'c-tab-Cleric-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_Cleric_on: string = _BASE_IMG_URL + 'c-tab-Cleric-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_DualGunners_off: string = _BASE_IMG_URL + 'c-tab-DualGunners-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_DualGunners_on: string = _BASE_IMG_URL + 'c-tab-DualGunners-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_Paladins_off: string = _BASE_IMG_URL + 'c-tab-Paladins-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_Paladins_on: string = _BASE_IMG_URL + 'c-tab-Paladins-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_Rifleman_off: string = _BASE_IMG_URL + 'c-tab-Rifleman-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_Rifleman_on: string = _BASE_IMG_URL + 'c-tab-Rifleman-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_Warlocks_off: string = _BASE_IMG_URL + 'c-tab-Warlocks-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_Warlocks_on: string = _BASE_IMG_URL + 'c-tab-Warlocks-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_Warrior_off: string = _BASE_IMG_URL + 'c-tab-Warrior-off.png?v=' + _BASE_CACHE_VER;
export const c_tab_Warrior_on: string = _BASE_IMG_URL + 'c-tab-Warrior-on.png?v=' + _BASE_CACHE_VER;
export const c_tab_bg: string = _BASE_IMG_URL + 'c-tab-bg.jpg?v=' + _BASE_CACHE_VER;
export const c_tab_princess_hold: string = _BASE_IMG_URL + 'c-tab-princess-hold.png?v=' + _BASE_CACHE_VER;
export const contactus: string = _BASE_IMG_URL + 'contactus.png?v=' + _BASE_CACHE_VER;
export const directx: string = _BASE_IMG_URL + 'direct.png?v=' + _BASE_CACHE_VER;
export const discord: string = _BASE_IMG_URL + 'discord.png?v=' + _BASE_CACHE_VER;
export const discord_d: string = _BASE_IMG_URL + 'discord_d.png?v=' + _BASE_CACHE_VER;
export const down_btn: string = _BASE_IMG_URL + 'down-btn-1.png?v=' + _BASE_CACHE_VER;
export const findus: string = _BASE_IMG_URL + 'findus.png?v=' + _BASE_CACHE_VER;
export const icon_check: string = _BASE_IMG_URL + 'icon-check.png?v=' + _BASE_CACHE_VER;
export const icon_surprise: string = _BASE_IMG_URL + 'icon-surprise.png?v=' + _BASE_CACHE_VER;
export const join_img: string = _BASE_IMG_URL + 'join-img.png?v=' + _BASE_CACHE_VER;
export const joinour: string = _BASE_IMG_URL + 'joinour.png?v=' + _BASE_CACHE_VER;
export const main_arrow: string = _BASE_IMG_URL + 'main-arrow.png?v=' + _BASE_CACHE_VER;
export const main_thumb_001: string = _BASE_IMG_URL + 'main-thumb-001.png?v=' + _BASE_CACHE_VER;
export const main_thumb_002: string = _BASE_IMG_URL + 'main-thumb-002.png?v=' + _BASE_CACHE_VER;
export const main_thumb_003: string = _BASE_IMG_URL + 'main-thumb-003.png?v=' + _BASE_CACHE_VER;
export const main_thumb_004: string = _BASE_IMG_URL + 'main-thumb-004.png?v=' + _BASE_CACHE_VER;
export const main_thumb_005: string = _BASE_IMG_URL + 'main-thumb-005.png?v=' + _BASE_CACHE_VER;
export const main_thumb_006: string = _BASE_IMG_URL + 'main-thumb-006.png?v=' + _BASE_CACHE_VER;
export const main_top_arrow: string = _BASE_IMG_URL + 'main-top-arrow.png?v=' + _BASE_CACHE_VER;
export const main_top_img: string = _BASE_IMG_URL + 'main-top-img.png?v=' + _BASE_CACHE_VER;
export const main_youtube_img: string = _BASE_IMG_URL + 'main-youtube-img.png?v=' + _BASE_CACHE_VER;
export const mcoin: string = _BASE_IMG_URL + 'mcoin.png?v=' + _BASE_CACHE_VER;
export const navi_logo: string = _BASE_IMG_URL + 'navi-logo.png?v=' + _BASE_CACHE_VER;
export const nvidia: string = _BASE_IMG_URL + 'nvidia.png?v=' + _BASE_CACHE_VER;
export const overview: string = _BASE_IMG_URL + 'overview2.png?v=' + _BASE_CACHE_VER;
export const play_now_ikon: string = _BASE_IMG_URL + 'play-now-ikon.png?v=' + _BASE_CACHE_VER;
export const sub_ch_movie_search: string = _BASE_IMG_URL + 'sub-ch-movie-search.png?v=' + _BASE_CACHE_VER;
export const sub_ch_movie_thumb: string = _BASE_IMG_URL + 'sub-ch-movie-thumb.png?v=' + _BASE_CACHE_VER;
export const sub_ch001: string = _BASE_IMG_URL + 'sub-ch001.png?v=' + _BASE_CACHE_VER;
export const sub_download_install: string = _BASE_IMG_URL + 'sub-download-install.png?v=' + _BASE_CACHE_VER;
export const sub_list__: string = _BASE_IMG_URL + 'sub-list--.png?v=' + _BASE_CACHE_VER;
export const sub_list_btn: string = _BASE_IMG_URL + 'sub-list-btn.png?v=' + _BASE_CACHE_VER;
export const sub_list_img: string = _BASE_IMG_URL + 'sub-list-img.png?v=' + _BASE_CACHE_VER;
export const sub_list_new: string = _BASE_IMG_URL + 'sub-list-new.png?v=' + _BASE_CACHE_VER;
export const sub_list_search: string = _BASE_IMG_URL + 'sub-list-search.png?v=' + _BASE_CACHE_VER;
export const sub_list_srrow: string = _BASE_IMG_URL + 'sub-list-srrow.png?v=' + _BASE_CACHE_VER;
export const sub_log_btn: string = _BASE_IMG_URL + 'sub_log-btn.png?v=' + _BASE_CACHE_VER;
export const sub_log_logo: string = _BASE_IMG_URL + 'sub_log-logo.png?v=' + _BASE_CACHE_VER;
export const temp_footer_logos: string = _BASE_IMG_URL + 'temp_footer_logos.png?v=' + _BASE_CACHE_VER;
export const temp_guide1_video: string = _BASE_IMG_URL + 'temp_guide1_video.png?v=' + _BASE_CACHE_VER;
export const job_screenshot: string = _BASE_IMG_URL + 'job_screenshot.jpg?v=' + _BASE_CACHE_VER;
export const the_game_of: string = _BASE_IMG_URL + 'the-game-of.png?v=' + _BASE_CACHE_VER;
export const youtube: string = _BASE_IMG_URL + 'youtube.png?v=' + _BASE_CACHE_VER;

export const shop_box_1000M_off: string = _BASE_IMG_URL + 'shop-box-1000M-off.png?v=' + _BASE_CACHE_VER;
export const shop_box_1000M_on: string = _BASE_IMG_URL + 'shop-box-1000M-on.png?v=' + _BASE_CACHE_VER;
export const shop_box_11000M_off: string = _BASE_IMG_URL + 'shop-box-11000M-off.png?v=' + _BASE_CACHE_VER;
export const shop_box_11000M_on: string = _BASE_IMG_URL + 'shop-box-11000M-on.png?v=' + _BASE_CACHE_VER;
export const shop_box_2000M_off: string = _BASE_IMG_URL + 'shop-box-2000M-off.png?v=' + _BASE_CACHE_VER;
export const shop_box_2000M_on: string = _BASE_IMG_URL + 'shop-box-2000M-on.png?v=' + _BASE_CACHE_VER;
export const shop_box_500M_off: string = _BASE_IMG_URL + 'shop-box-500M-off.png?v=' + _BASE_CACHE_VER;
export const shop_box_500M_on: string = _BASE_IMG_URL + 'shop-box-500M-on.png?v=' + _BASE_CACHE_VER;
export const shop_box_5250M_off: string = _BASE_IMG_URL + 'shop-box-5250M-off.png?v=' + _BASE_CACHE_VER;
export const shop_box_5250M_on: string = _BASE_IMG_URL + 'shop-box-5250M-on.png?v=' + _BASE_CACHE_VER;
export const shop_tab_card_off: string = _BASE_IMG_URL + 'shop-tab-card-off.png?v=' + _BASE_CACHE_VER;
export const shop_tab_card: string = _BASE_IMG_URL + 'shop-tab-card.png?v=' + _BASE_CACHE_VER;
export const shop_tab_other_off: string = _BASE_IMG_URL + 'shop-tab-other-off.png?v=' + _BASE_CACHE_VER;
export const shop_tab_other_on: string = _BASE_IMG_URL + 'shop-tab-other-on.png?v=' + _BASE_CACHE_VER;
export const shop_tab_paypal_off: string = _BASE_IMG_URL + 'shop-tab-paypal-off.png?v=' + _BASE_CACHE_VER;
export const shop_tab_paypal_on: string = _BASE_IMG_URL + 'shop-tab-paypal-on.png?v=' + _BASE_CACHE_VER;

//after qa
export const top_navi_user: string = _BASE_IMG_URL + 'user.png?v=' + _BASE_CACHE_VER;
export const top_navi_user_d: string = _BASE_IMG_URL + 'user_d.png?v=' + _BASE_CACHE_VER;

export const facebook: string = _BASE_IMG_URL + 'facebook.png?v=' + _BASE_CACHE_VER;
export const facebook_d: string = _BASE_IMG_URL + 'facebook_d.png?v=' + _BASE_CACHE_VER;
export const contact: string = _BASE_IMG_URL + 'contact.png?v=' + _BASE_CACHE_VER;
export const contact_d: string = _BASE_IMG_URL + 'contact_d.png?v=' + _BASE_CACHE_VER;
export const purchase: string = _BASE_IMG_URL + 'purchase.png?v=' + _BASE_CACHE_VER;
export const purchase_d: string = _BASE_IMG_URL + 'purchase_d.png?v=' + _BASE_CACHE_VER;

export const chapter_1: string = _BASE_IMG_URL + 'chapter_1.png?v=' + _BASE_CACHE_VER;
export const chapter_2: string = _BASE_IMG_URL + 'chapter_2.png?v=' + _BASE_CACHE_VER;
export const chapter_3: string = _BASE_IMG_URL + 'chapter_3.png?v=' + _BASE_CACHE_VER;

export const hanbit_logo: string = _BASE_IMG_URL + 'hanbit.png?v=' + _BASE_CACHE_VER;
export const cbm_games_logo: string = _BASE_IMG_URL + 'cbm_games.png?v=' + _BASE_CACHE_VER;
export const cbm_inter_logo: string = _BASE_IMG_URL + 'cbm_inter.png?v=' + _BASE_CACHE_VER;

export const warlock_thumb: string = _BASE_IMG_URL + 'warlocks/1_warlocks_thum.jpg?v=' + _BASE_CACHE_VER;
export const cleric_thumb: string = _BASE_IMG_URL + 'clerics/1_clerics_thum.jpg?v=' + _BASE_CACHE_VER;
export const dual_gunner_thumb: string = _BASE_IMG_URL + 'dual_gunners/1_dual_gunners_thum.jpg?v=' + _BASE_CACHE_VER;
export const riflemen_thumb: string = _BASE_IMG_URL + 'riflemen/1_riflemen_thum.jpg?v=' + _BASE_CACHE_VER;
export const paladin_thumb: string = _BASE_IMG_URL + 'paladins/1_paladins_thum.jpg?v=' + _BASE_CACHE_VER;
export const warrior_thumb: string = _BASE_IMG_URL + 'warriors/1_warriors_thum.jpg?v=' + _BASE_CACHE_VER;

export const list_thumb_warlock: string[] = [
    _BASE_IMG_URL + 'warlocks/warlocks_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warlocks/warlocks_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warlocks/warlocks_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warlocks/warlocks_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warlocks/warlocks_5.png?v=' + _BASE_CACHE_VER,
];

export const list_thumb_cleric: string[] = [
    _BASE_IMG_URL + 'clerics/clerics_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'clerics/clerics_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'clerics/clerics_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'clerics/clerics_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'clerics/clerics_5.png?v=' + _BASE_CACHE_VER,
];

export const list_thumb_dual_gunner: string[] = [
    _BASE_IMG_URL + 'dual_gunners/dual_gunners_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'dual_gunners/dual_gunners_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'dual_gunners/dual_gunners_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'dual_gunners/dual_gunners_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'dual_gunners/dual_gunners_5.png?v=' + _BASE_CACHE_VER,
];

export const list_thumb_riflemen: string[] = [
    _BASE_IMG_URL + 'riflemen/riflemen_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'riflemen/riflemen_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'riflemen/riflemen_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'riflemen/riflemen_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'riflemen/riflemen_5.png?v=' + _BASE_CACHE_VER,
];

export const list_thumb_paladin: string[] = [
    _BASE_IMG_URL + 'paladins/paladins_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'paladins/paladins_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'paladins/paladins_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'paladins/paladins_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'paladins/paladins_5.png?v=' + _BASE_CACHE_VER,
];

export const list_thumb_warrior: string[] = [
    _BASE_IMG_URL + 'warriors/warriors_1.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warriors/warriors_2.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warriors/warriors_3.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warriors/warriors_4.png?v=' + _BASE_CACHE_VER,
    _BASE_IMG_URL + 'warriors/warriors_5.png?v=' + _BASE_CACHE_VER,
];