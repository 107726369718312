import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../../services/api";
import {AxiosError} from "axios";
import paths from "../../router/paths";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {getPastDate, numberWithCommas} from "../../utils/commonUtils";
import {CmmnPaging} from "../common/CmmnPaging";
import {PurchaseCoinHistItem} from "./profile_types";
import {TransactionHistoryRequest} from "../../contexts/AuthContext";

const PurchaseCoinHistoryBody = () => {
    const [balance, setBalance] = React.useState(0);
    const [list, setList] = React.useState<PurchaseCoinHistItem[]>([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    async function getBalance() {
        try {
            const response = await api.post('/account/balance');
            return response.data;
        } catch (error) {
            return error as AxiosError;
        }
    }

    async function getList(params: TransactionHistoryRequest) {
        try {
            const response = await api.post('/account/topup_history', params);
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    }

    function statusCodeToText(statusCode: string) {
        switch (statusCode.toUpperCase()) {
            case "Y":
                return "Confirmed";
            case "C":
                return "Chargeback";
            default:
                return statusCode;
        }
    }
    const navigateToShop = () => {
        navigate(paths.SHOP_PATH);
    }
    useEffect(() => {
        const today = new Date();
        const todayStr = today.toISOString().split('T')[0];

        const day30before = getPastDate(180);
        const dateStr = day30before.toISOString().split('T')[0];

        setStartDate(dateStr);
        setEndDate(todayStr);

        getBalance().then((balance) => {
            if(isNaN(balance)) {
                setBalance(0);
            } else {
                setBalance(balance as  number);
            }
        }).catch((err) => {
        });

        getList(buildParams(1, dateStr, todayStr)).then((res) => {
            if(res && res.data) {
                setList(res.data);
                setPage(res.page.current);
                setTotalCount(res.page.total);
                setPageSize(res.page.pageSize);
            }
        }).catch((err) => {

        });
    }, []);

    function buildParams(page: number, sdate?: string, edate?: string): TransactionHistoryRequest {
        return {
            start_date: sdate ? sdate : startDate,
            end_date: edate ? edate: endDate,
            page: page,
        };
    }
    function handleSearch() {
        setList([]);
        setPage(1);
        setTotalCount(0);
        setPageSize(10);
        getList(buildParams(1)).then((res) => {
            if(res && res.data) {
                setList(res.data);
                setPage(res.page.current);
                setTotalCount(res.page.total);
                setPageSize(res.page.pageSize);
            }
        }).catch((err) => {

        });
    }
    return(
        <>

            <Row className="justify-content-center justify-content-between "
                 style={{
                     borderTop: "2px solid #D6D9E1",
                     marginTop: 33,
                 }}
            >
                <Col md={12}>
                    <div style={{
                        marginTop: 30,
                        paddingLeft: 20,
                        paddingRight: 20,
                        border: "1px solid #DEE0E7",
                    }}>
                        <div style={{width: "100%", height: 20}}></div>
                        <div style={{width: "100%", height: 80}}>
                            <div style={{
                                backgroundColor: "#DEE0E7",
                                borderRadius: "50%",
                                height: 50,
                                paddingTop: 12,
                                width: 50,
                                float: "left",
                            }} className="col-md-2">
                                <FontAwesomeIcon icon={faDollarSign} size="lg" />
                            </div>
                            <div style={{textAlign: "left", float: "left", marginLeft: 20}}  className="col-md-4">
                                Current Balance<br /><span style={{fontWeight: "bold", fontSize: 24}}>{numberWithCommas(balance)}</span>
                            </div>

                            <div style={{
                                paddingBottom: 10,
                                float: "right",
                            }} className="col-md-4 col-sm-4">
                                <button style={{
                                    backgroundColor: "#DEE0E7",
                                    borderRadius: 10,
                                    border: "none",
                                    width: "100%",
                                    height: 40,
                                    marginLeft: -20,
                                }} onClick={navigateToShop}>
                                    Buy Coin
                                </button>
                            </div>

                        </div>

                    </div>
                </Col>
                <Col md={8} style={{textAlign: "left", paddingTop: 30}}>
                    <input className="term_date" name="term_start" type="date"
                           value={startDate}
                           onChange={(e) => setStartDate(e.target.value)}  />
                    -
                    <input className="term_date" name="term_end" type="date"
                           value={endDate}
                           onChange={(e) => setEndDate(e.target.value)}  />
                    &nbsp;
                    <button className="term_button" onClick={handleSearch}>Search</button>
                </Col>
                {/*<Col md={4} style={{textAlign: "right", paddingTop: 30}}>*/}
                {/*    <button className="term_button" style={{width: "80%"}}>Transaction Tickets</button>*/}
                {/*</Col>*/}
            </Row>

            <div style={{width: "100%", height: 40}}></div>

            <Row fluid="true">
                <Col style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Date</Col>
                <Col  style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Coin</Col>
                <Col  style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>TID</Col>
                <Col  style={{fontWeight: "bold",  backgroundColor: "#DEE0E7", minHeight: 50, paddingTop: 15}}>Status</Col>
            </Row>
            { list.length > 0 && list.map((item, index) => {
                return (
                    <Row fluid="true" key={index}>
                        <Col  style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{item.reg_date}</Col>
                        <Col  style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{numberWithCommas(item.p_coin)}</Col>
                        <Col  style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{item.paymentwall_tid}</Col>
                        <Col  style={{minHeight: 50, paddingTop: 15, borderBottom: "#efefef 1px solid"}}>{statusCodeToText(item.p_status)}</Col>
                    </Row>
                )
            })}


            <div style={{width: "100%", height: 20}}></div>
            <CmmnPaging
                currentPage={page}
                totalCount={totalCount}
                pageSize={pageSize}
                showSearch={false}
                clickPage={(page) => {
                    getList(buildParams(page, startDate, endDate)).then((res) => {
                        if(res && res.data) {
                            setList(res.data);
                            setPage(res.page.current);
                            setTotalCount(res.page.total);
                            setPageSize(res.page.pageSize);
                        }
                    }).catch((err) => {

                    });
                }}
                clickSearch={(text) => {
                }}
            />
        </>
    )
}

export default PurchaseCoinHistoryBody;