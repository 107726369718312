import React, {useEffect, useState} from "react";
import "./BanBody.css";
import {icon_surprise} from "../../const/ConstImages";
import {getBanReasonStorage} from "../../utils/tokenStorage";
export const BanBody = () => {
    const [reason, setReason] = useState<string>("");

    useEffect(() => {
        window.scrollTo(0, 0);

        const reason = getBanReasonStorage();
        if(reason === null) {
            setReason("");
        } else {
            setReason(reason);
        }
    }, []);

    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_surprise} alt="icon-surprise" />
            </div>
            <div className="center_content">
                Account has been permanently banned!
                <br />
                <br />
                {reason === "" ? null : <strong>{`Reason : ${reason}`}</strong>}
                {/*<br />*/}
                {/*<br />*/}
            </div>
        </div>
    )
}