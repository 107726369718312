import React, {FormEvent, useState} from "react";
import useSession from "../../hooks/useSession";
import {ToastContainer} from "react-toastify";
import {sub_log_logo} from "../../const/ConstImages";
import {useNavigate} from "react-router-dom";
import {_BASE_URL} from "../../const/ConstBase";
import paths from "../../router/paths";


const ValidCodeBody = () => {
    let {pendingUser} = useSession();
    const [validCode, setValidCode] = useState('');
    const [loginRequestStatus, setLoginRequestStatus] = useState('success')
    const navigate = useNavigate();

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()
        if(validCode.length < 8) {
            alert("Please input Verification code.");
            return;
        }

        const fetchData = async () => {
            try {
                let data = {
                    user_email: pendingUser?.email,
                    valid_code: validCode,
                }
                const response = await fetch(`${_BASE_URL}auth/valid_code`,
                    {
                        method : "POST",
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }
                );
                const res = await response.json();
                switch(res.res_code) {
                    case "SUCCESS":
                        alert("Valid Code Success");
                        navigate("/login");
                        break;
                    case "EXPIRED":
                        alert("Valid Code Expired. We sent new valid code to your email.");
                        break;
                    case "NOT_MATCH":
                        alert("Valid Code Not Match.");
                        break;
                    case "NOT_FOUND":
                        alert("Not Found Valid Request Or User.");
                        break;
                    default:
                        alert(res.res_msg);
                        break;
                }
            } catch (error) {

            } finally {
                setLoginRequestStatus('success')
            }
        }
        setLoginRequestStatus('loading')
        fetchData();

    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const {  value } = event.target
        setValidCode(value);
    }

    return(
        <div className="sub_content">
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                <div style={{ width: "100%", height: 40 }} />
                <div className="login_box" style={{
                    height: 500,
                }}>
                    <div className="logo">
                        <img src={sub_log_logo} alt="sub_log-logo" />
                    </div>
                    <div>
                        <div style={{
                            textAlign: "center",
                        }}><h4>Input Verification Code</h4></div>
                        {pendingUser && <div style={{
                            textAlign: "center",
                        }}><h4>{pendingUser.email}</h4></div>
                        }
                    </div>
                    <div className="input_container">
                        <input type="text" className="login_input"
                               value={validCode}
                               name="valid_code"
                               id="valid_code"
                               disabled={loginRequestStatus === 'loading'}
                               onChange={handleChange}
                               maxLength={8}
                               style={{
                                   fontSize: 40,
                                   textAlign: "center",
                               }}
                        /><br />
                    </div>
                    <div style={{
                        textAlign: "center",
                        paddingBottom: 20,
                    }}>
                        Please check your registered email for a Verification Code.
                        <br/>
                        Look in your "spam" folder if you don't see it in a few minutes.
                    </div>
                    <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                        <button type="submit" disabled={loginRequestStatus === 'loading'}>
                            {loginRequestStatus === 'loading' ? 'Loading...' : 'Valid Code'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ValidCodeBody;