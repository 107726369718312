import React, {useEffect} from "react";
import {Sub_top_002} from "../const/ConstImages";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {NoticeDetail} from "../components/announcement/NoticeDetail";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";

export const NoticeDetailScreen = () => {
    const {title} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div style={{
            backgroundColor: "white"
        }}>
            <Helmet>
                <title>Aika Online : {title}</title>
                <meta name="description" content={title} />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title="Announcement" />
            <NoticeDetail />
        </div>
    );
}