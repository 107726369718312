import React, {ReactNode, Suspense} from "react";
import useSession from "../hooks/useSession";
import {ErrorBoundary} from "react-error-boundary";
import Loader from "../components/loader/Loader";
import LoginScreen from "../pages/LoginScreen";

type Props = {
    permissions?: string[]
    roles?: string[]
    redirectTo?: string
    children: ReactNode
}

function PrivateRoute(props: Props) {
    const { children } = props
    const { isAuthenticated, user, loadingUserData } = useSession()

    if (loadingUserData) {
        return null
    }

    if (!isAuthenticated) {
        // eslint-disable-next-line react/jsx-no-undef
        return <LoginScreen redirectTo={window.location.pathname} />
    }

    return (
        <ErrorBoundary
            fallback={<LoginScreen />}
        >
            <Suspense fallback={<Loader />}>{children}</Suspense>
        </ErrorBoundary>
    )
}

export default PrivateRoute
