import React from "react";

export const LoginHistoryHeader = () => {
    return(
        <div style={{
            width: "100%",
        }} >
            <h1>My Login History</h1>
        </div>
    )
}