import React, {useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {navi_logo, play_now_ikon, top_navi_user, top_navi_user_d} from "../../const/ConstImages";
import {Link, useNavigate} from "react-router-dom";
import "./TopNavi.css";
import {api} from "../../services/api";
import useSession from "../../hooks/useSession";
import paths from "../../router/paths";
import HoverImage from "./HoverImage";

export const TopNavi = () => {
    const navigate = useNavigate();
    const {user, signOut, contactLink} = useSession();
    const [showAnnounce, setShowAnnounce] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const [showSupport, setShowSupport] = useState(false);
    const [showCommunity, setShowCommunity] = useState(false);

    async function logout() {
        try {
            await api.post('/auth/logout');
        } catch(error) {
            // console.log(error);
        }
    }

    const onLogoutHandler = () => {
        logout().then((res) => {
            signOut();
        }).catch((err) => {
        });
    }

    return(
        <Navbar expand="lg" className="navbar-dark bg-dark" fixed={"top"}>
            <Container fluid style={{
                maxWidth: 1920,
                // minWidth: 420,
            }} className="navi_container">
                <Navbar.Brand>
                    <Link to={paths.ROOT_PATH}>
                        <img src={navi_logo} alt="navi_logo" width="144" height="40" className="d-inline-block align-top" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown title="Announcements" className="item"
                                     // show={showAnnounce}
                                     // onMouseEnter={() => setShowAnnounce(true)}
                                     // onMouseLeave={() => setShowAnnounce(false)}
                                     // onClick={() => navigate(paths.ANNOUNCEMENT_PATH_ALL)}
                        >
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.ANNOUNCEMENT_PATH + "/update"} className="sub_item" id="id_update"><div>Updates</div></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.ANNOUNCEMENT_PATH + "/event"} className="sub_item" id="id_event"><div>Events</div></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.ANNOUNCEMENT_PATH + "/notice"} className="sub_item" id="id_notice"><div>Notices</div></Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Game Features" className="item"
                                     // show={showFeatures}
                                     // onMouseEnter={() => setShowFeatures(true)}
                                     // onMouseLeave={() => setShowFeatures(false)}
                                     // onClick={() => navigate(paths.GUIDE_PATH + "#top")}
                        >
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.GUIDE_PATH + "#top"} className="sub_item" id="id_overview"><div>Overview</div></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.GUIDE_PATH + "#loc_story"} className="sub_item" id="id_story"><div>Story</div></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item className="drop_item">
                                <Link to={paths.GUIDE_PATH + "#loc_character"} className="sub_item" id="id_characters"><div>Characters</div></Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        {/*<Link to={"#"} className="item top10">Ranking</Link>*/}
                        <NavDropdown title="Support" className="item" id="id_support"
                                     // show={showSupport}
                                     // onMouseEnter={() => setShowSupport(true)}
                                     // onMouseLeave={() => setShowSupport(false)}
                        >
                            <div className="drop_item">
                                <Link to={paths.FAQ_PATH} target="_blank" className="sub_item" id="id_support_faq"><div>FAQ</div></Link>
                            </div>
                            <div className="drop_item">
                                <Link to={contactLink} target="_blank" className="sub_item" id="id_support_contact_us"><div>Contact us</div></Link>
                            </div>
                        </NavDropdown>
                        <Link to={paths.DOWNLOAD_PATH} className="item top10">Download</Link>
                        <NavDropdown title="Community" className="item" id="id_community"
                                     // show={showCommunity}
                                     // onMouseEnter={() => setShowCommunity(true)}
                                     // onMouseLeave={() => setShowCommunity(false)}
                        >
                            <div className="drop_item">
                                <Link to={paths.DISCORD_PATH} target={"_blank"} className="sub_item"><div>Discord</div></Link>
                            </div>
                            <div className="drop_item">
                                <Link to={paths.FACEBOOK_PATH} target={"_blank"} className="sub_item"><div>Facebook</div></Link>
                            </div>
                            <div className="drop_item">
                                <Link to={paths.WIKI_PATH} target={"_blank"} className="sub_item"><div>Wiki</div></Link>
                            </div>
                        </NavDropdown>
                        <Link to={paths.SHOP_PATH} className="item top10">Shop</Link>
                    </Nav>
                </Navbar.Collapse>
                <div
                    className="d-flex align-items-center"
                    id="userdiv"
                    style={{ height: "100%", marginTop: 0 }}
                >
                    <div className="dropleft">
                        <NavDropdown id="basic-nav-dropdown"
                                     align="end"
                                     title={
                                        <HoverImage src={top_navi_user} hoverSrc={top_navi_user_d} style={{ width: 30, marginTop: 1 }}  />
                                     }
                        >
                            <NavDropdown.Item>
                                { user ? (
                                    <div
                                        id="dropdown-user"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: 15,
                                            background: "rgba(3,3,3,0.9)",
                                            borderRadius: 4,
                                            display: "flex",
                                        }}
                                    >
                                        <Link to={paths.PROFILE_PATH} className="btn btn-primary d-flex justify-content-center align-items-center"
                                              role="button"
                                              id="btn_profile"
                                              style={{ marginRight: 15 }}
                                        >My Page</Link>
                                        <div className="btn btn-primary d-flex justify-content-center align-items-center" onClick={onLogoutHandler}>
                                            Logout
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        id="dropdown-user"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: 15,
                                            background: "rgba(3,3,3,0.9)",
                                            borderRadius: 4,
                                            display: "flex",
                                        }}
                                    >
                                        <Link to={paths.LOGIN_PATH} className="btn btn-primary d-flex justify-content-center align-items-center"
                                              role="button"
                                              id="btn_login"
                                              style={{ marginRight: 15 }}
                                        >Login</Link>
                                        <Link to={paths.REGISTER_PATH} className="btn btn-primary d-flex justify-content-center align-items-center"
                                              role="button"
                                              id="btn_create"
                                        >Create Account</Link>
                                    </div>

                                )}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div>
                        {/*<Link to={paths.DOWNLOAD_PATH} className={"playnow"}>PLAY NOW</Link>*/}
                    </div>
                </div>
            </Container>
        </Navbar>
    );
}