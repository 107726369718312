import React from 'react';
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import {GuildRankBody} from "../components/ranking/GuildRankBody";

export const GuildRankScreen = () => {
    return(
        <div style={{width: "100%", height: "100%", backgroundColor: "white", paddingTop: 20}}>
            <Helmet>
                <title>Guild Ranking</title>
                <meta name="description" content="Guild Ranking" />
            </Helmet>
            <CmmnHeader
                backgroundImg={Sub_top_002}
                title="Guild Ranking"
                subTitle="Aika Online Global Guild Ranking"
            />
            <GuildRankBody />
        </div>
    )

}