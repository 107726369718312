import React from "react";

const PurchaseCoinHistoryHeader = () => {
    return(
        <div style={{
            width: "100%",
        }} >
            <h1>M-Coin Purchase History</h1>
        </div>
    )
}

export default PurchaseCoinHistoryHeader;