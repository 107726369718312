import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import PrivacyPolicyBody from "../components/privacy/PrivacyPolicyBody";

export const PrivacyPolicyScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title="Privacy Policy" />
            <PrivacyPolicyBody />
        </>
    );
}

export default PrivacyPolicyScreen;