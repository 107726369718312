import React, {useRef, useState} from "react";
import {sub_log_logo} from "../../const/ConstImages";
import useInput from "../../hooks/useInput";
import {api} from "../../services/api";
import {AxiosError} from "axios";
import {RegisterEpinRequest} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";
import {Col, Row} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import {_CAPTCHA_SITE_KEY} from "../../const/ConstBase";

import "./EpinBody.css";

export const EpinBody = () => {
    const [epin_code, setEpin_code] = React.useState("");
    let epin_serial = useInput("");
    let navigate = useNavigate();
    const [epinRequestStatus, setEpinRequestStatus] = useState('success')

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [values, setValues] = useState("")

    function onReCaptchaChange(value: any) {
        setValues(value);
        setIsCaptcha(true);
    }

    function onReCaptchaError() {
        setValues("");
        setIsCaptcha(false);
    }

    function onReCaptchaExpire() {
        setValues("");
        setIsCaptcha(false);
    }

    function formatStringAsCardNumber(input: string): string {
        let output = input.replace(/-/g, '').replace(/\s+/g, '');

        if (output.length > 16) {
            // trim the string to 16 characters
            output = output.substr(0, 16);
        }

        return output.match(/.{1,4}/g)?.join('-') || '';
    }

    const handleEpinCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setEpin_code(formatStringAsCardNumber(value));
    }

    const fetchRegisterEpin = async (params: RegisterEpinRequest) => {
        try {
            const response = await api.post('/account/register_epin_item', params);
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    }
    const handleRegister = () => {
        if(epin_code === "") {
            alert("Please input epin code");
            return;
        }
        if(epin_code.length !== 19) {
            alert("Please input epin code");
            return;
        }
        if(epin_serial.value === "") {
            alert("Please input epin serial");
            return;
        }

        setEpinRequestStatus('loading')
        fetchRegisterEpin({ep_code: epin_code, ep_serial: epin_serial.value, captcha: values}).then((res) => {
            if(res.res_code === "SUCCESS") {
                setEpinRequestStatus('success')
                navigate(paths.PAYMENT_SUCCESS_PATH);
            } else {
                setEpinRequestStatus('success')
                recaptchaRef.current?.reset();
                setIsCaptcha(false);
                setEpin_code("");
                epin_serial.setValue("");
                alert(res.res_msg);
                return;
            }
        });
    }
    return(
        <div className="sub_content">
            <Row className="justify-content-center justify-content-between h-100">
                <div style={{ width: "100%", height: 40 }} />
                <Col md={2} xxl={2}></Col>
                <Col md={3} xxl={2} style={{marginTop: "auto", marginBottom: "auto"}}>
                    <div style={{ width: "100%", textAlign: "center"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="56"><g><path fill="#222" stroke="rgba(0,0,0,0)" d="m207.817 42-5.321-6h-6.321l-.866 6h-8.252l4.064-28h28.877l-3.2 22h-3.227l4.863 5.986-4.783.013Zm-.741-14h2.631l.887-6h-12.376l-.887 6h9.745Zm-30.353 14 .866-6h-12.377l-.866 6h-8.249l4.064-28h28.876l-4.063 28Zm-10.354-14h12.376l.886-6h-12.375Zm-51.523 14 .867-6h-12.379l-.867 6h-8.248l4.064-28h28.878l-4.063 28Zm-10.355-14h12.376l.887-6h-12.371ZM63.278 42l4.064-28H96.22l-3.2 22H72.396l-.866 6Zm10.273-14h12.376l.886-6H74.438Zm51.607 14 .826-5.66L142.855 22h-14.787l1.155-8H158.1l-.824 5.659L140.401 34h14.79l-1.156 8Z"></path><path fill="#ffcb2d" d="M76.847 21.563a1.174 1.174 0 0 0-1.021-.594H60.454l2.577-19.668a1.18 1.18 0 0 0-2.163-.766L42.174 33.248a1.172 1.172 0 0 0 1 1.786h15.141l-2.042 19.7a1.181 1.181 0 0 0 2.174.721l18.385-32.709a1.17 1.17 0 0 0 .014-1.18Z"></path><path fill="#222" d="m34.5 28.676.974-6.678h12.375l-1.749 12h-7.13Zm-5.475-14.678h-5.837l-11.777 10h-.561l1.465-10H4.063L0 41.998h8.252l1.443-10h.562l8.869 10h5.837l4.783-.013-11.362-13.987 9.316-9.466-1.859 12.358 9.213 11.1h18.138l4.063-27.992h-28.23Z"></path></g></svg>
                        <br />
                        Epin satın almak için Kopazar'1 ziyaret edin
                        <br />
                        <a href="https://kopazar.com" target="_blank" rel="noreferrer">https://kopazar.com</a>
                        <br />
                    </div>
                </Col>
                <Col md={5} xxl={6}>
                    <div className="epin_box">
                        <div style={{ width: "100%", height: 20 }} />
                        <div className="input_container">
                            <span style={{fontSize: 30, fontWeight: "bold"}}>Epin Yükleme</span>
                            <br />
                            Yetkili satıcılarımızdan aldığınız E-PIN'leri bu ekrandan yükleyebilirsiniz
                        </div>
                        <div className="input_container">
                            <input type="text" className="login_input"
                                   name="epin_code"
                                   id="epin_code"
                                   placeholder="XXXX-XXXX-XXXX-XXXX"
                                   onInput={handleEpinCode}
                                   value={epin_code}
                                   maxLength={19}
                            /><br />
                        </div>
                        <div className="input_container">
                            <input type="text" className="login_input"
                                   name="epin_serial"
                                   id="epin_serial"
                                   placeholder="Seri Numarası"
                                   {...epin_serial}
                            /><br />
                        </div>
                        <div className="input_container">
                            <ReCAPTCHA
                                sitekey={_CAPTCHA_SITE_KEY}
                                ref={recaptchaRef}
                                onChange={onReCaptchaChange}
                                onExpired={onReCaptchaError}
                                onErrored={onReCaptchaExpire}
                                style={{display: "inline-block"}}
                            />
                        </div>
                        <div className="input_container">
                            <button type="button" onClick={handleRegister} id="btnEpin" disabled={epinRequestStatus === 'loading' || !isCaptcha}>YÜKLE</button>
                        </div>
                        <div className="input_container">
                            Epin yükleme ve satın alma ile ilgili herhangi bir sorununuz varsa, lütfen destek bölümünden bize bilet gönderin.
                        </div>
                    </div>

                </Col>
                <Col md={2} xxl={2}></Col>
            </Row>
        </div>
    )
}