import React from "react";
import {Modal} from "react-bootstrap";
import PrivacyPolicyBody from "../privacy/PrivacyPolicyBody";
interface Props {
    isShow: boolean;
    setShow: (eulaShow: boolean) => void;
}
const PrivatePolicyModal: React.FC<Props> = (props) => {
    return(
        <Modal
            size="lg"
            show={props.isShow}
            onHide={() => props.setShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Privacy Policy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PrivacyPolicyBody />
            </Modal.Body>
        </Modal>
    );
}

export default PrivatePolicyModal;