import React, {useEffect, useState} from "react";
import {MainTopBanner} from "../components/main/MainTopBanner";
import {MainMidContainer} from "../components/main/MainMidContainer";
import {MainNoticeContainer} from "../components/main/MainNoticeContainer";
import {MainYoutubeContainer} from "../components/main/MainYoutubeContainer";
import {MainOverview} from "../components/main/MainOverview";
import ModalVideo from "react-modal-video";
import {MainJoinUs} from "../components/main/MainJoinUs";
import {Col, Row} from "react-bootstrap";
import {MainYoutubeStaticContainer} from "../components/main/MainYoutubeStaticContainer";

export const MainScreen = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [youtubeId, setYoutubeId] = useState("");

    const fnOpenYoutube = (videoId: string) => {
        setIsOpen(true)
        setYoutubeId(videoId)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <>
            <MainTopBanner />
            <MainMidContainer />
            <MainNoticeContainer />
            {/*<MainYoutubeContainer handleOpen={fnOpenYoutube} />*/}
            <MainYoutubeStaticContainer />
            <MainOverview />
            <MainJoinUs />
            <ModalVideo channel="youtube" isOpen={isOpen} videoId={youtubeId} onClose={() => setIsOpen(false)} />
        </>
    );
}