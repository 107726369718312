import React from "react";
import "./ProfileSignout.css";
import useSession from "../../../hooks/useSession";
import {api} from "../../../services/api";

export const ProfileSignout = () => {
    const {user, signOut} = useSession();

    async function logout() {
        try {
            signOut();
        } catch(error) {
            // console.log(error);
        }
    }

    const onLogoutHandler = () => {
        logout().then((res) => {
            signOut();
        }).catch((err) => {
        });
    }

    return (
        <div>
            <div className="profile_signout_button" style={{cursor: "pointer"}} onClick={onLogoutHandler}>Sign Out</div>
            <br />
        </div>
    );
}