import React from "react";
import {Col, Row} from "react-bootstrap";
import {sub_list_img} from "../../const/ConstImages";
import "./NoticeItem.css";
import {NoticeItems} from "./notice_types";
import {_BASE_IMG_URL, _BASE_UPLOADED_IMG_URL, _GMT_TIMEZONE} from "../../const/ConstBase";
import {newlineToBr} from "../../utils/commonUtils";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";

interface Props {
    item: NoticeItems
}

export const NoticeItem: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();

    let summary_image = _BASE_UPLOADED_IMG_URL + props.item.n_summary_image;

    const handleClickItem = () => {
        let url = encodeURI(paths.ANNOUNCEMENT_DETAIL_PATH + '/' + props.item.n_seq + '/' + props.item.n_title);
        // navigate(paths.ANNOUNCEMENT_DETAIL_PATH + '/' + props.item.n_seq + '/' + props.item.n_title);
        navigate(url);
    }
    return(
        <>
            <Row className="justify-content-center" style={{paddingTop: 30, backgroundColor: "white"}}>
                <Col md={2} className="left_item rounded"
                     style={{
                         background: `url(${summary_image}) top / cover no-repeat`,
                         height: 200,
                     }}
                >
                </Col>
                <Col md={6} onClick={handleClickItem} style={{cursor: "pointer"}}>
                    <Row>
                        <Col className="list_title"><strong>{props.item.n_title}</strong></Col>
                    </Row>
                    <Row>
                        <Col className="list_summary" >
                            <div dangerouslySetInnerHTML={{__html: newlineToBr(props.item.n_title_summary)}} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="list_summary" >
                            <div dangerouslySetInnerHTML={{__html: newlineToBr(props.item.n_list_summary)}} />
                        </Col>
                    </Row>
                </Col>
                <Col md={3} className="list_date">{props.item.reg_date} ({_GMT_TIMEZONE})</Col>
            </Row>
            <div className="notice_under_line" style={{backgroundColor: "white"}} />
        </>
    )
}