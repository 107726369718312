import React, {useEffect, useRef, useState} from "react";
import {sub_log_logo} from "../../const/ConstImages";
import ReCAPTCHA from "react-google-recaptcha";
import EulaModal from "./EulaModal";
import PrivatePolicyModal from "./PrivatePolicyModal";
import AgreePromotionModal from "./AgreePromotionModal";
import {_BASE_URL, _CAPTCHA_SITE_KEY} from "../../const/ConstBase";
import "./RegistAccountBody.css";
import {useNavigate} from "react-router-dom";
import useSession from "../../hooks/useSession";
import {SignUpCredentials, SignUpResponse} from "../../contexts/AuthContext";
import {isPasswordValid} from "../../utils/commonUtils";

const RegistAccountBody = () => {
    const [email, setEmail] = useState("");
    const [forum_name, setForumName] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [agree_eula, setAgreeEula] = useState(true);
    const [agree_pp, setAgreePp] = useState(true);
    const [allow_email, setAllowEmail] = useState(true);

    const [err_email, setErrEmail] = useState("");
    const [err_forum_name, setErrForumName] = useState("");
    const [err_password, setErrPassword] = useState("");
    const [err_confirm_password, setErrConfirmPassword] = useState("");

    const [requestStatus, setRequestStatus] = useState('success')
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [eulaShow, setEulaShow] = useState(false);
    const [ppShow, setPpShow] = useState(false);
    const [promoShow, setPromoShow] = useState(false);
    const navigate = useNavigate()
    const {signUp} = useSession();

    function onReCaptchaChange(value: any) {
        setIsCaptcha(true);
    }
    function onReCaptchaError() {
        setIsCaptcha(false);
    }
    function onReCaptchaExpire() {
        setIsCaptcha(false);
    }
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        switch (name) {
            case "email":
                setEmail(value.toLowerCase());
                break;
            case "forum_name":
                setForumName(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "confirm_password":
                setConfirmPassword(value);
                break;
            case "agree_eula":
                setAgreeEula(event.target.checked);
                break;
            case "agree_pp":
                setAgreePp(event.target.checked);
                break;
            case "allow_email":
                setAllowEmail(event.target.checked);
                break;
            default:
                break;

        }
    }

    const checkEmailDuplication = () => {
        if(email === "") {
            return;
        }
        if(email.length < 8 || email.length > 40) {
            setErrEmail("Length of an email should be between 8 and 40 words.");
            return;
        }
        fetch(`${_BASE_URL}auth/check_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        })
        .then(response => response.json())
        .then(data => {
            let err_msg = "";
            if(data.res_code !== "SUCCESS") {
                switch(data.res_msg) {
                    case "ALREADY_EXISTS":
                        err_msg = "Email has already been registered.";
                        break;
                    case "NOT_ALLOW_VENDOR":
                        err_msg = "Please use a valid email address to create an account.";
                        break;
                    case "BAD_PARAMETER":
                        err_msg = "Missing or invalid parameter.";
                        break;
                    default:
                        err_msg = data.res_msg;
                        break;
                }
            }
            setErrEmail(err_msg);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const checkNicknameDuplication = () => {
        if(forum_name === "") {
            return;
        }
        fetch(`${_BASE_URL}auth/check_nickname`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ nickname: forum_name}),
        })
        .then(response => response.json())
        .then(data => {
            let err_msg = "";
            if(data.res_code !== "SUCCESS") {
                if(data.res_msg === "ALREADY_EXISTS") {
                    err_msg = "Nickname has already been registered.";
                } else {
                    err_msg = data.res_msg;
                }
            }
            setErrForumName(err_msg);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const checkCountryCode = () => {
        fetch(`${_BASE_URL}auth/check_valid_country`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            let err_msg = "";
            if(data.res_code !== "SUCCESS") {
                if(data.res_code === "NOT_ALLOW_COUNTRY") {
                    alert("Unable to create an account at your location.");
                } else {
                    alert(data.res_msg);
                }
                navigate("/");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const isReadyToSubmit = () => {
        //all warnings are empty
        if(err_email !== "" || err_forum_name !== "" || err_password !== "" || err_confirm_password !== "") {
            return false;
        }
        if(email === "" || forum_name === "" || password === "" || confirm_password === "") {
            return false;
        }
        if(password !== confirm_password) {
            return false;
        }
        if(!agree_eula || !agree_pp) {
            return false;
        }

        return isCaptcha;

    }

    const validateForm = () => {
        if(email.length < 8 || email.length > 40) {
            setErrEmail("Length of an email should be between 8 and 40 words.");
        } else {
            setErrEmail("");
        }

        if(password !== "" || confirm_password !== "") {
            if (!isPasswordValid(password)) {
                setErrPassword("Password must include 8-16 capital & lower case English letters, numbers, and special characters.");
            } else {
                setErrPassword("");
                if (password !== confirm_password) {
                    setErrConfirmPassword("Password and Confirm Password are not matched.");
                } else {
                    setErrConfirmPassword("");
                }
            }
        }
        if(forum_name !== "") {
            if(forum_name.length < 8 || forum_name.length > 50) {
                setErrForumName("Nickname must be 8-50 characters long.");
            } else {
                setErrForumName("");
            }
        }
        // isReadyToSubmit();
    }

    const handleSubmit = () => {
        setRequestStatus('loading')

        let params: SignUpCredentials = {
            email: email,
            nickname: forum_name,
            password: password,
            passwordConfirm: confirm_password,
            allowNewsletter: allow_email ? "Y" : "N",
            captcha: recaptchaRef.current?.getValue() || "",
        }

        signUp(params).then((data) => {
            let res_data = data as SignUpResponse;
            if(res_data.res_code === "SUCCESS") {
                //success, move to valid code page
                navigate("/valid_code");
            } else {
                //fail
                if(res_data.res_msg === "ALREADY_EXISTS_EMAIL") {
                    alert("Email has already been registered.");
                } else if(res_data.res_msg === "ALREADY_EXISTS_NICKNAME") {
                    alert("Nickname has already been registered.");
                } else if(res_data.res_msg === "BAD_PARAMETER") {
                    alert("Missing or invalid parameter.");
                } else if(res_data.res_msg === "NOT_ALLOW_VENDOR") {
                    alert("Please use a valid email address to create an account.");
                } else {
                    alert("Fail to register. Please try again.");
                }
            }

            setRequestStatus('success')
        });
    }

    useEffect(() => {
        //check country
        checkCountryCode();
    }, []);

    useEffect(() => {
        validateForm();
    }, [email, forum_name, password, confirm_password, allow_email, isCaptcha]);

    return (
        <div className="sub_content">
            <div style={{ width: "100%", height: 40 }} />
            <div className="login_box" style={{height: "100%"}}>
                <div className="logo">
                    <img src={sub_log_logo} alt="sub_log-logo" />
                </div>
                <div className="input_container">
                    <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Email</label>
                    <input type="email" className="login_input"
                           value={email}
                           name="email"
                           id="email"
                           placeholder="Email"
                           disabled={requestStatus === 'loading'}
                           onChange={handleChange}
                           onBlur={checkEmailDuplication}
                           maxLength={40}
                    /><br />
                    <ul style={{paddingLeft: 50, textAlign: "left"}}>
                        <li>Only lowercase letters are allowed.</li>
                        {err_email && <li style={{color: "red"}}>{err_email}</li>}
                    </ul>
                </div>
                <div className="input_container">
                    <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Nickname</label>
                    <input type="forum_name" className="login_input"
                           value={forum_name}
                           name="forum_name"
                           id="forum_name"
                           placeholder="Nickname"
                           disabled={requestStatus === 'loading'}
                           onChange={handleChange}
                           onBlur={checkNicknameDuplication}
                    /><br />
                    <ul style={{paddingLeft: 50, textAlign: "left"}}>
                        <li>Must be 8-50 characters long.</li>
                    {err_forum_name && <li style={{color: "red"}}>{err_forum_name}</li>}
                    </ul>
                </div>
                <div className="input_container">
                    <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Password</label>
                    <input type="password" className="login_input"
                           value={password}
                           name="password"
                           id="password"
                           placeholder={"Password"}
                           disabled={requestStatus === 'loading'}
                           maxLength={16}
                           onChange={handleChange}
                    /><br />
                    <ul style={{paddingLeft: 50, textAlign: "left"}}>
                        <li>Include 8-16 capital and lower case letters, numbers, and special characters.</li>
                        <li>Usable special characters are !@#$%^&*()+=~_-</li>
                        {err_password && <li style={{color: "red"}}>{err_password}</li>}
                    </ul>
                </div>
                <div className="input_container">
                    <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Confirm Password</label>
                    <input type="password" className="login_input"
                           value={confirm_password}
                           name="confirm_password"
                           id="confirm_password"
                           placeholder={"Confirm Password"}
                           disabled={requestStatus === 'loading'}
                           maxLength={16}
                           onChange={handleChange}
                    /><br />
                    <ul style={{paddingLeft: 50, textAlign: "left"}}>
                        {err_confirm_password && <li style={{color: "red"}}>{err_confirm_password}</li>}
                    </ul>
                </div>
                <div style={{textAlign: "left", paddingLeft: 20}}>
                    <div style={{padding: "5px 0"}}>
                        <input type="checkbox" id="agree_eula" name="agree_eula" onChange={handleChange} checked={agree_eula} />&nbsp;
                        <label htmlFor="agree_eula" style={{cursor: "pointer"}}>I agree to the terms of TOS & EULA</label>
                        <div style={{float: "right", paddingRight: 30, cursor: "pointer"}}><strong onClick={() => {setEulaShow(true)}}>[Required]</strong></div>
                    </div>
                    <div style={{padding: "5px 0"}}>
                        <input type="checkbox" id="agree_pp" name="agree_pp" onChange={handleChange} checked={agree_pp} />&nbsp;
                        <label htmlFor="agree_pp" style={{cursor: "pointer"}}>I agree to the terms of Privacy Policy</label>
                        <div style={{float: "right", paddingRight: 30, cursor: "pointer"}}><strong onClick={() => {setPpShow(true)}}>[Required]</strong></div>
                    </div>
                    <div style={{padding: "5px 0"}}>
                        <input type="checkbox" id="allow_email" name="allow_email" onChange={handleChange} checked={allow_email} />&nbsp;
                        <label htmlFor="allow_email" style={{cursor: "pointer"}}>I agree to receive email newsletters</label>
                        <div style={{float: "right", paddingRight: 30, cursor: "pointer"}}><strong onClick={() => {setPromoShow(true)}}>[Optional]</strong></div>
                    </div>
                </div>
                <div style={{width: "100%", height: 20}}></div>
                <div className="input_container">
                    <ReCAPTCHA
                        sitekey={_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        onChange={onReCaptchaChange}
                        onExpired={onReCaptchaError}
                        onErrored={onReCaptchaExpire}
                        style={{ display: "inline-block" }}
                    />
                </div>
                <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                    <button id="btnSignup" type="submit" disabled={requestStatus === 'loading' || !isReadyToSubmit()} onClick={handleSubmit}>
                        {requestStatus === 'loading' ? 'Loading...' : 'Create Account'}
                    </button>
                </div>
            </div>

            <EulaModal isShow={eulaShow} setShow={setEulaShow} />
            <PrivatePolicyModal isShow={ppShow} setShow={setPpShow} />
            <AgreePromotionModal isShow={promoShow} setShow={setPromoShow} />
        </div>
    );
}

export default RegistAccountBody;