import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import React, {useEffect} from "react";
import {PaymentFailBody} from "../components/payment/PaymentFailBody";

export const PaymentFail = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Purchase M-Coin</title>
                <meta name="description" content="Success Purchase M-Coin" />
            </Helmet>
            <CmmnHeader
                backgroundImg={Sub_top_002}
                title="M-coin Purchase Fail"
                subTitle="Aika Online uses M-coin as its currency for the purchase of in-game items"
            />
            <PaymentFailBody />
        </>
    )
}