import {REFRESH_TOKEN_COOKIE, TOKEN_COOKIE} from "../const/ConstBase";
import {setAuthorizationHeader} from "../services/interceptors";
import {api} from "../services/api";

type CreateSessionCookiesParams = {
    token?: string
    refreshToken?: string
}

export function createSessionStorage(params: CreateSessionCookiesParams) {
    const { token, refreshToken } = params

    if (token) {
        // window.sessionStorage.setItem(TOKEN_COOKIE, token);
        window.localStorage.setItem(TOKEN_COOKIE, token);
    }

    if (refreshToken) {
        // window.sessionStorage.setItem(REFRESH_TOKEN_COOKIE, refreshToken);
        window.localStorage.setItem(REFRESH_TOKEN_COOKIE, refreshToken);
    }
}

export function removeSessionStorage() {
    // window.sessionStorage.removeItem(TOKEN_COOKIE);
    // window.sessionStorage.removeItem(REFRESH_TOKEN_COOKIE);
    window.localStorage.removeItem(TOKEN_COOKIE);
    window.localStorage.removeItem(REFRESH_TOKEN_COOKIE);
    setAuthorizationHeader({ request: api.defaults, token: '' })
}

export function getToken() {
    // return window.sessionStorage.getItem(TOKEN_COOKIE);
    return window.localStorage.getItem(TOKEN_COOKIE);
}

export function getRefreshToken() {
    // return window.sessionStorage.getItem(REFRESH_TOKEN_COOKIE);
    return window.localStorage.getItem(REFRESH_TOKEN_COOKIE);
}

export function createBanReasonStorage(reason: string) {
    window.localStorage.setItem('banReason', reason);
}

export function getBanReasonStorage() {
    return window.localStorage.getItem('banReason');
}

export function removeBanReasonStorage() {
    window.localStorage.removeItem('banReason');
}