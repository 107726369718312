import React, {useEffect} from "react";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import FindPasswordBody from "../components/login/FindPasswordBody";

const FindPasswordScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div style={{
            backgroundColor: "white",
            height: "100%"
        }}>
            <CmmnHeader backgroundImg={Sub_top_002} title="Find Password" />
            <FindPasswordBody />
        </div>
    );
}

export default FindPasswordScreen;