import React from "react";
import "./WidgetAccountInfo.css";
import {Card, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket, faUnlockKeyhole} from "@fortawesome/free-solid-svg-icons";
import CardHeader from "react-bootstrap/CardHeader";
import {useNavigate} from "react-router-dom";
import paths from "../../../router/paths";
import useSession from "../../../hooks/useSession";

interface Props {
    passwordChangeDate: string
}
export const WidgetAccountInfo: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const {user} = useSession();

    const navigateToAccountDetail = () => {
        navigate(paths.ACCOUNT_DETAIL_PATH);
    }

    return (
        <Col md={12}>
            <div style={{
                backgroundColor: "#DEE0E7",
                height: 50,
                width: "100%",
                marginTop: 10,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{
                    padding: "10px 5px 20px 10px",
                    minHeight: "50px",
                    height: "auto",
                    borderBottom: "1px solid #DEE0E7",
                    cursor: "pointer",
                }} onClick={navigateToAccountDetail}>
                    <div style={{
                        float: "left",
                        paddingRight: 20,
                    }}>
                        <FontAwesomeIcon icon={faUnlockKeyhole} size="lg" />
                    </div>
                    <div style={{
                        width: "calc(100% - 70px)",
                        float: "left",
                        fontSize: "16px",
                        textAlign: "left",
                    }}>
                        Account
                    </div>
                    <div style={{
                        float: "left",
                        width: "20px",
                    }}>
                        <FontAwesomeIcon icon={faRightToBracket} size="xs" />
                    </div>
                </div>
            </div>
            <div style={{
                paddingLeft: 20,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{
                    paddingTop: 10,
                    textAlign: "left",
                    minHeight: 40,
                }}>
                    {user?.email}
                </div>
                {props.passwordChangeDate ? <div style={{ fontSize: 12, textAlign: "left", minHeight: 30, }}> Password changed on {props.passwordChangeDate} </div>
                    : null }
                <div style={{
                    paddingBottom: 15,
                }}>
                    <button
                        style={{
                            backgroundColor: "#DEE0E7",
                            borderRadius: 10,
                            border: "none",
                            width: "90%",
                            height: 40,
                            marginLeft: -20,
                        }}
                        onClick={() => {
                            navigate(paths.PASSWORD_CHANGE_PATH);
                        }}
                    >
                        Change Password
                    </button>
                </div>
            </div>
        </Col>
  );
}