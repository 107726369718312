import React from "react";
import "./Footer.css";
import {Dropdown} from "react-bootstrap";
import {cbm_games_logo, cbm_inter_logo, hanbit_logo, temp_footer_logos} from "../../const/ConstImages";
import {Link} from "react-router-dom";
import {_BUILD_ENV} from "../../const/ConstBase";
import paths from "../../router/paths";
import {getCurrentYear} from "../../utils/commonUtils";

export const Footer = () => {
    return(
        <div
            className="maindiv"
            style={{ width: "100%", height: "100%", background: "#000000" }}
        >
            <div
                className="text-center"
                style={{
                    paddingTop: 40,
                    marginTop: 0,
                    paddingBottom: 45,
                    borderTop: "1px solid rgb(18,18,18)"
                }}
            >
                <Link to={paths.PRIVATE_POLICY_PATH} id="footer-link">
                    PRIVACY POLICY
                </Link>
                |
                <Link to={paths.TERM_OF_SERVICE_PATH} id="footer-link">
                    { _BUILD_ENV !== "live" ? "TERMS OF SERVICE" : "TOS & EULA" }
                </Link>
                |
                <Link to={paths.UGC_PATH} id="footer-link">
                    UGC
                </Link>
                { _BUILD_ENV !== "live" ?
                    <>| <Link to={paths.TERM_OF_SERVICE_PATH} id="footer-link">
                        REFUND POLICY
                    </Link></> : <>| <Link to={paths.GAME_OPERATIONS_POLICY_PATH} id="footer-link">
                        GAME OPERATIONS POLICY
                    </Link></>
                }
                <p
                    style={{
                        marginBottom: 0,
                        marginTop: 23,
                        color: "rgb(48,48,48)",
                        paddingTop: 12
                    }}
                >
                    ⓒ {getCurrentYear()} CBM Interactive Inc. All Right Reserved <br />
                    <br />
                </p>
                <div>
                    <img src={cbm_inter_logo} alt="cbm_inter_logo" width={200} />
                    <img src={cbm_games_logo} alt="cbm_games_logo" width={250} />
                    <img src={hanbit_logo} alt="hanbit_logo" width={200}
                         style={{ cursor: "pointer" }}
                         onClick={() => {window.open('https://www.hanbitsoft.com/', '_blank');}}
                    />
                </div>
                <br />
                <br />
                { _BUILD_ENV === "live" ?
                    undefined :
                    <div style={{
                        color: "rgb(90,90,90)",
                    }}>
                        #268, 8, Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Zip code :  16837, Republic of Korea
                        <br/>
                        Tel :  +82 031-889-9781   │  Fax :  +82 031-889-9782   │  Support email :  cs@cbminteractive.com
                        <br />
                        Company Registration :  474-81-02848   │   Online Business Registration :  2023-YONGINSUJI-1841
                    </div>
                }
            </div>
        </div>
    );
}