import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import TermOfServiceBody from "../components/privacy/TermOfServiceBody";
import {_BUILD_ENV} from "../const/ConstBase";

export const TermOfServiceScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>
                    { _BUILD_ENV !== "live" ? "TERMS OF SERVICE" : "TOS & EULA" }
                </title>
                <meta name="description" content="Term of Service" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title={ _BUILD_ENV !== "live" ? "TERMS OF SERVICE" : "TOS & EULA" } />
            <TermOfServiceBody />
        </>
    )
}

export default TermOfServiceScreen;