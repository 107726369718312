import React from "react";
import paths from "../../router/paths";
import {discord, discord_d, facebook, facebook_d, join_img} from "../../const/ConstImages";
import "./MainJoinUs.css";
import HoverImage from "../common/HoverImage";
import {Col, Row} from "react-bootstrap";

export const MainJoinUs = () => {
    const handleClick = (path: string) => {
        window.open(path, "_blank")
    }

    return(
        <Row>
            <Col className="main_join_community"
                 style={{
                     backgroundImage: `url("${join_img}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover",
                 }}
            >
                <div className="text_content">Join our community</div>
                <div className="sns_logos">
                    <HoverImage src={discord} hoverSrc={discord_d} onClick={() => handleClick(paths.DISCORD_PATH)} />
                    <HoverImage src={facebook} hoverSrc={facebook_d} onClick={() => handleClick(paths.FACEBOOK_PATH)} />
                </div>
            </Col>

        </Row>
    )
}
