import React from "react";
import {Col} from "react-bootstrap";
import "./WidgetAccount.css";

interface Props {
    registerDate: string
}
export const WidgetAccount:React.FC<Props> = (props) => {
    return (
        <Col md={12}>
            <div style={{
                width: "100%",
                height: 180,
                backgroundColor: "#2A313F",
                marginTop: 10,
                borderRadius: 15,
            }}>
                <div className="w_account_content">
                    <div className="w_account_circle"> </div>
                </div>
                <div style={{
                    color: "white",
                    height: 180,
                    paddingTop: 80,
                    float: "left",
                }}>Member Since, {props.registerDate}</div>
            </div>
        </Col>
  );
}