import {icon_surprise} from "../../const/ConstImages";
import React from "react";

const BanIPBody = () => {
    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_surprise} alt="icon-surprise" />
            </div>
            <div className="center_content">
                IP has been permanently banned!
                <br />
                <br />
            </div>
        </div>
    )
}

export default BanIPBody;