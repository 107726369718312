import React from "react";

export const AccountDetailHeader = () => {
    return (
        <div style={{
            width: "100%",
        }} >
            <h1>Account Details</h1>
        </div>
    );
}