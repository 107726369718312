import React from "react";
import {youtube} from "../../const/ConstImages";

interface Props {
    youtube_link: string;
    title: string;
}
export const MainYoutubeStaticItem: React.FC<Props> = (props) => {
    const extractVideoId = (url: string) => {
        const urlObj = new URL(url);
        return urlObj.searchParams.get('v') ?? '';
    }

    return(
        <div
            className="card"
            style={{
                borderRadius: 0,
                borderStyle: "none",
                borderRightStyle: "solid",
                borderRightColor: "rgba(0,0,0,0.06)",
                padding: 20,
                height: "100%",
                marginBottom: 1,
            }}
        >
            <iframe
                allowFullScreen
                frameBorder={0}
                src={`https://www.youtube.com/embed/${extractVideoId(props.youtube_link)}?controls=0`}
                style={{ width: "100%", height: 210 }}
            />
            <div style={{ marginTop: 18 }}>
                <span style={{ color: "rgb(227,43,43)", fontSize: 17 }}>
                  <img
                      src={youtube}
                      style={{ width: 25, marginTop: "-2px" }}
                  />
                    &nbsp;
                </span>
                {props.title}
            </div>
        </div>
    )
}