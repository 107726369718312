
export function numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function getCurrentYear(): number {
    return new Date().getFullYear();
}

export function newlineToBr(input: string): string {
    return input.replace(/\n/g, "<br/>");
}

export function getFutureDate(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function getPastDate(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}

export function isPasswordValid(password: string): boolean {
    // Regex breakdown:
    // ^                 Start anchor
    // (?=.*[A-Z])      Ensure password has at least one uppercase letter.
    // (?=.*[a-z])      Ensure password has at least one lowercase letter.
    // (?=.*\d)         Ensure password has at least one digit.
    // (?=.*[!@#$%^&*()+=~_-]) Ensure password has at least one special character.
    // .{8,16}          Ensure password is between 8 and 16 characters.
    // $                End anchor.

    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()+=~_-]).{8,16}$/;
    let isSuccess = regex.test(password);
    if(!isSuccess) {
        return false;
    }

    //allow only !@#$%^&*()+=~_- a-z A-Z 0-9
    const regex2 = /^[a-zA-Z0-9!@#$%^&*()+=~_-]*$/;
    isSuccess = regex2.test(password);
    return isSuccess;
}