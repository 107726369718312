import React, {useEffect} from "react";
import {Sub_overwiew_page_001, Sub_top_002} from "../const/ConstImages";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {GuideTop} from "../components/guide/GuideTop";
import {GuideMid} from "../components/guide/GuideMid";
import {GuideBottom} from "../components/guide/GuideBottom";
import {useLocation} from "react-router-dom";

export const GuideScreen = () => {
    const location = useLocation();

    useEffect(() => {
        const element = document.querySelector(location.hash);
        if (element) {
            window.setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return(
        <>
            <GuideTop />
            <GuideMid />
            <GuideBottom />
        </>
    )
}