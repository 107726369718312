import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ProfileLeftMenu} from "../components/profile/ProfileLeftMenu";
import {AccountDetailHeader} from "../components/profile/AccountDetailHeader";
import {AccountDetailBody} from "../components/profile/AccountDetailBody";
import {PasswordChangeDetailBody} from "../components/profile/PasswordChangeDetailBody";

export const PasswordChangeScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "white", paddingTop: 20}}>
            <Container fluid="true">
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center left_menu_top_padding">
                        <ProfileLeftMenu />
                    </Col>
                    <Col md={7} className="align-middle text-center"
                         style={{
                             paddingTop: 120,
                         }}
                    >
                        <div style={{
                            width: "100%",
                        }} >
                            <h1>Password Change</h1>
                        </div>
                        <PasswordChangeDetailBody />
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    )
}