import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {MainNoticeItem} from "./MainNoticeItem";
import paths from "../../router/paths";
import {MainYoutubeStaticItem} from "./MainYoutubeStaticItem";

export const MainYoutubeStaticContainer = () => {
    return(
        <div style={{width: "100%", height: "100%", backgroundColor: "#E6E6E6", paddingTop: 20}}>
            <Container
                style={{
                    paddingTop: 20,
                }}
                fluid
            >
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col>
                        <div className="notice_title" style={{paddingLeft: 35}}>Aika on YouTube</div>
                    </Col>
                </Row>
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeStaticItem youtube_link={"https://www.youtube.com/watch?v=nZY7v2oc3uI"} title={"Story of Vulcan - Update Trailer"}/>
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeStaticItem youtube_link={"https://www.youtube.com/watch?v=7NNORgT_1Dk"} title={"Gun Slayer - New Class Trailer"}/>
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeStaticItem youtube_link={"https://www.youtube.com/watch?v=E0xaaI_lwDw"} title={"Story of Elhwynna - Update Trailer"}/>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <div style={{height: 40}}></div>
        </div>
    )
}