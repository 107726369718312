import React from "react";
import "./MainNoticeItem.css";
import {main_thumb_001 } from "../../const/ConstImages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import {NoticeItems} from "../announcement/notice_types";
import {Link} from "react-router-dom";
import MainNoticeItemWithImage from "./MainNoticeItemWithImage";
import MainNoticeItemNoImage from "./MainNoticeItemNoImage";

interface Props {
    item: NoticeItems[];
    title: string;
    link: string;
    type: string;
    pkey: string;
}
export const MainNoticeItem: React.FC<Props> = (props) => {
    return (
        <>
            <h1 className="d-flex justify-content-between h1 notice_title">
                <div>{props.title}</div>
                <Link to={props.link}>
                    <FontAwesomeIcon icon={faPlus} style={{
                        paddingRight: 10,
                    }} />
                </Link>
            </h1>
            {props.item.length > 0 && props.item.map((item, index) => {
                return ( index === 0 ?
                    (<MainNoticeItemWithImage key={props.pkey + "-" + index} type={props.type} item={item}/>)
                    : (<MainNoticeItemNoImage key={props.pkey + "-" + index} type={props.type} item={item} />))
            })}
        </>
    );
}