import React, {useEffect, useRef, useState} from "react";
import {sub_log_logo} from "../../const/ConstImages";
import ReCAPTCHA from "react-google-recaptcha";
import useInput from "../../hooks/useInput";
import {_BASE_URL, _CAPTCHA_SITE_KEY} from "../../const/ConstBase";
import {isPasswordValid} from "../../utils/commonUtils";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";

const FindPasswordBody = () => {
    const email = useInput("");
    const [requestStatus, setRequestStatus] = useState('success')
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [step, setStep] = useState(1);
    const validCode = useInput("");
    const [err_password, setErrPassword] = useState("");
    const [err_confirm_password, setErrConfirmPassword] = useState("");

    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    function onReCaptchaChange(value: any) {
        setIsCaptcha(true);
    }
    function onReCaptchaError() {
        setIsCaptcha(false);
    }
    function onReCaptchaExpire() {
        setIsCaptcha(false);
    }

    const isReadyToSubmit = () => {
        //all warnings are empty
        if(err_password !== "" || err_confirm_password !== "") { return false; }
        if(password === "" || confirm_password === "") { return false; }
        if(password !== confirm_password) { return false; }

        return isPasswordValid(password);
    }

    const fetchUpdatePasswordRequest = async (user_email: string, valid_code: string, password: string, password_confirm: string) => {
        try {
            let param = {
                user_email: user_email,
                valid_code: valid_code,
                password: password,
                password_confirm: password_confirm,
            };

            const response = await fetch(`${_BASE_URL}auth/reset_password`,
                {
                    method : "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(param),
                }
            );
            const res = await response.json();
            switch(res.res_code) {
                case "SUCCESS":
                    alert("Update Password Success");
                    navigate("/login");
                    break;
                case "NOT_FOUND":
                    alert("Not Found Matched Data.(User or Valid Code)");
                    break;
                default:
                    alert(res.res_msg);
                    break;
            }
        } catch(error) {
            // console.log(error);
        }
    }
    const handleSubmit = () => {
        fetchUpdatePasswordRequest(email.value, validCode.value, password, confirm_password);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target
        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "confirm_password":
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    }
    const fetchRequest = async (email: string) => {
        try {
            let param = {
                email: email,
            };
            const response = await fetch(`${_BASE_URL}auth/find_password`,
                {
                    method : "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(param),
                }
            );
            const res = await response.json();
            switch(res.res_code) {
                case "SUCCESS":
                    setStep(2);
                    break;
                case "NOT_FOUND":
                    alert("Not Found User.");
                    break;
                default:
                    alert(res.res_msg);
                    break;
            }
        } catch(error) {
            // console.log(error);
        }
    }
    const handleRequest = () => {
        if(email.value === "") {
            alert("Please input email.");
            return;
        }
        fetchRequest(email.value);
    }

    const fetchValidCheckRequest = async (email: string, validCode: string) => {
        try {
            let param = {
                user_email: email,
                valid_code: validCode,
            };
            const response = await fetch(`${_BASE_URL}auth/valid_code_reset_password`,
                {
                    method : "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(param),
                }
            );
            const res = await response.json();
            switch(res.res_code) {
                case "SUCCESS":
                    setStep(3);
                    break;
                case "EXPIRED":
                    alert("Too many incorrectly entered codes.  You may reset your password after 10 minutes.");
                    break;
                case "EXPIRED_1HOUR":
                    alert("Expired valid code. Please request again.");
                    break;
                case "NOT_MATCH":
                    alert("Valid Code Not Match.");
                    break;
                case "NOT_FOUND":
                    alert("Not Found Valid Request Or User.");
                    break;
                default:
                    alert(res.res_msg);
                    break;
            }
        } catch(error) {
            // console.log(error);
        }
    }
    const handleValidCheck = () => {
        if(validCode.value === "") {
            alert("Please Input Verification code.");
            return;
        }

        fetchValidCheckRequest(email.value, validCode.value);
    }

    const validateForm = () => {
        if(password !== "" || confirm_password !== "") {
            if (!isPasswordValid(password)) {
                setErrPassword("Password must include 8-16 capital & lower case English letters, numbers, and special characters.");
            } else {
                setErrPassword("");
                if (password !== confirm_password) {
                    setErrConfirmPassword("Password and Confirm Password are not matched.");
                } else {
                    setErrConfirmPassword("");
                }
            }
        }
    }

    useEffect(() => {
        validateForm();
    }, [password, confirm_password]);
    return (
        <div className="sub_content" >
            {step === 1 &&
                <>
                    <div style={{ width: "100%", height: 40 }} />
                    <div className="login_box">
                        <div className="logo">
                            <img src={sub_log_logo} alt="sub_log-logo" />
                        </div>
                        <div className="input_container">
                            <div style={{width: "100%", height: 20}}></div>
                            <input type="email" className="login_input"
                                   {...email}
                                   name="email"
                                   id="email"
                                   placeholder="Email"
                                   disabled={requestStatus === 'loading'}
                            /><br />
                        </div>

                        <div className="input_container">
                            <ReCAPTCHA
                                sitekey={_CAPTCHA_SITE_KEY}
                                ref={recaptchaRef}
                                onChange={onReCaptchaChange}
                                onExpired={onReCaptchaError}
                                onErrored={onReCaptchaExpire}
                                style={{ display: "inline-block" }}
                            />
                        </div>
                        <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                            <button type="button" onClick={handleRequest} disabled={!isCaptcha}>Find Password</button>
                        </div>
                    </div>
                </>
            }
            {step === 2 &&
                <>
                    <div style={{ width: "100%", height: 40 }} />
                    <div className="login_box" style={{
                        height: 500,
                    }}>
                        <div className="logo">
                            <img src={sub_log_logo} alt="sub_log-logo" />
                        </div>
                        <div>
                            <div style={{
                                textAlign: "center",
                            }}>
                                <h4>Input Verification Code</h4>
                            </div>
                        </div>
                        <div className="input_container">
                            <input type="text" className="login_input"
                                   {...validCode}
                                   name="valid_code"
                                   id="valid_code"
                                   maxLength={8}
                                   style={{
                                       fontSize: 40,
                                       textAlign: "center",
                                   }}
                            /><br />
                        </div>
                        <div style={{
                            textAlign: "center",
                            paddingBottom: 20,
                        }}>
                            Please check your registered email for a Verification Code.
                            <br/>
                            Look in your "spam" folder if you don't see it in a few minutes.
                        </div>
                        <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                            <button type="button" onClick={handleValidCheck} >Valid Code</button>
                        </div>
                    </div>
                </>
            }
            {step === 3 &&
                <>
                    <div style={{ width: "100%", height: 40 }} />
                    <div className="login_box" style={{height: "100%", minWidth: 460}}>
                        <div className="logo">
                            <img src={sub_log_logo} alt="sub_log-logo" />
                        </div>
                        <div className="input_container">
                            <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Password</label>
                            <input type="password" className="login_input"
                                   value={password}
                                   name="password"
                                   id="password"
                                   placeholder={"Password"}
                                   onChange={handleChange}
                            /><br />
                            <ul style={{paddingLeft: 50, textAlign: "left"}}>
                                <li>Include 8-16 capital and lower case letters, numbers, and special characters.</li>
                                <li>Usable special characters are !@#$%^&*()+=~_-</li>
                                {err_password && <li style={{color: "red"}}>{err_password}</li>}
                            </ul>
                        </div>
                        <div className="input_container">
                            <label style={{textAlign: "left", paddingLeft: 30, width: "100%"}}>* Confirm Password</label>
                            <input type="password" className="login_input"
                                   value={confirm_password}
                                   name="confirm_password"
                                   id="confirm_password"
                                   placeholder={"Confirm Password"}
                                   onChange={handleChange}
                            /><br />
                            <ul style={{paddingLeft: 50, textAlign: "left"}}>
                                {err_confirm_password && <li style={{color: "red"}}>{err_confirm_password}</li>}
                            </ul>
                        </div>

                        <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                            <button id="btnSignup" type="submit" disabled={requestStatus === 'loading' || !isReadyToSubmit()} onClick={handleSubmit}>
                                {requestStatus === 'loading' ? 'Loading...' : 'Update Password'}
                            </button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default FindPasswordBody;