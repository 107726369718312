import React, {useEffect, useState} from "react";
import {_BASE_URL} from "../../const/ConstBase";
import {UserRankingItems} from "./RankingItems";
import "./RankBody.css";

interface Props {
    tp: string
}
export const PvpRankBody: React.FC<Props> = (props: Props) => {
    let fetchUrl = `${_BASE_URL}rank/user/daily`;
    if(props.tp === "week") {
        fetchUrl = `${_BASE_URL}rank/user/weekly`;
    }

    const [list, setList] = useState<UserRankingItems[]>();
    const fetchData = async() => {
        try {
            setList([]);
            const response = await fetch(fetchUrl, { method : "POST" });
            const data = await response.json();
            if(data && data.data) {
                setList(data.data);
            }
        } catch(error) {

        }
    }

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="sub_content_rank">
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nation</th>
                    <th scope="col">Character</th>
                    <th scope="col">Level</th>
                    <th scope="col">Kill Point</th>
                    <th scope="col">Death Point</th>
                    <th scope="col">Guild</th>
                    <th scope="col">Change</th>
                </tr>
                </thead>
                <tbody>
                { list && list.length > 0 && list.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td className="r_cen">{item.rank}</td>
                            <td className="r_cen">{item.nation}</td>
                            <td>{item.char_name}</td>
                            <td className="r_cen">{item.level}</td>
                            <td className="r_cen">{item.kill_point}</td>
                            <td className="r_cen">{item.death_point}</td>
                            <td className="r_cen">{item.guild}</td>
                            <td className="r_cen">{item.change_rank}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}