import React from "react";
import "./ProfileHeader.css";

export const ProfileHeader = () => {
    return (
    <div style={{
        width: "100%",
    }} >
      <h1>Account Information</h1>
    </div>
  );
}