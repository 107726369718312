import React, {useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';
import {main_top_img, overview} from "../../const/ConstImages";
import "./MainTopBanner.css";
import {_BASE_IMG_URL, _BASE_URL} from "../../const/ConstBase";
import {Link} from "react-router-dom";
import paths from "../../router/paths";

export const MainTopBanner = () => {
    const [line_1, setLine_1] = React.useState("");
    const [line_2, setLine_2] = React.useState("");

    const fetchData = async () => {
        try {
            const response = await fetch(`${_BASE_URL}notice/main_banner`, { method : "GET" });
            const data = await response.json();
            if(data && data.data) {
                setLine_1(data.data.line_1);
                setLine_2(data.data.line_2);
            }
        } catch (error) {
            // console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="top_video_container">
            <video className="top_video" autoPlay loop muted>
                <source
                    src={`${_BASE_IMG_URL}main-top-img_animation.mp4`}
                    type="video/mp4"
                />
            </video>
            <div className={"top_text_lines"}>
                <div className="banner_txt">
                    <p>{line_1}</p>
                    <p>{line_2}</p>
                </div>
            </div>
            {/*<Carousel className="top_carousel">*/}
            {/*    <Carousel.Item>*/}
            {/*        <img src={main_top_img} alt="main_top_img" className="d-block w-100" />*/}
            {/*        <Carousel.Caption>*/}
            {/*            <p>Open Beta : October 11th, 2023</p>*/}
            {/*            <p>Join us today and prepare to Rumble!</p>*/}
            {/*        </Carousel.Caption>*/}
            {/*    </Carousel.Item>*/}
            {/*</Carousel>*/}
        </div>
    );
}