import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./NoticeDetail.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import paths from "../../router/paths";
import {_BASE_URL, _GMT_TIMEZONE} from "../../const/ConstBase";
import {NoticeItems} from "./notice_types";

export const NoticeDetail = () => {
    const {seq, title} = useParams();
    const [n_title, setNTitle] = useState("");
    const [n_content, setNContent] = useState("");
    const [n_type, setNType] = useState("");
    const [reg_date, setNReg_date] = useState("");

    // [{seq: 1, title: "test", content: "test", date: "2021-07-06"}]
    const [n_list, setNList] = useState<NoticeItems[]>([]);
    const navigate = useNavigate();

    const handleClickList = () => {
        navigate(paths.ANNOUNCEMENT_PATH_ALL);
    }

    const noticeTypeToText = (type: string) => {
        switch (type.toUpperCase()) {
            case "U":
                return "Updates";
            case "E":
                return "Events";
            case "N":
                return "Notices";
            default:
                return "";
        }
    }
    useEffect(() => {
        if (!seq) {
            navigate(paths.ANNOUNCEMENT_PATH_ALL);
        } else {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${_BASE_URL}notice/detail/${seq}`, {method: "GET"});
                    const data: {
                        data: {
                            n_type: string,
                            n_title: string,
                            n_content: string,
                            reg_date: string,
                            list: []
                        }
                    } = await response.json();

                    if (data && data.data) {
                        // switch(data.data.n_type.toLowerCase()) {
                        //     case "update":
                        //         setNType("Updates");
                        //         break;
                        //     case "event":
                        //         setNType("Events");
                        //         break;
                        //     case "notice":
                        //         setNType("Notices");
                        //         break;
                        // }
                        setNTitle(data.data.n_title);
                        setNType(data.data.n_type);
                        setNContent(data.data.n_content);
                        setNReg_date(data.data.reg_date);
                        setNList(data.data.list)
                        window.scrollTo(0, 0);
                    }
                } catch (error) {
                    // console.log(error);
                }
            };
            fetchData();
        }
    }, [seq]);


    return (
        <Container className="justify-content-center">
            <Row>
                <Col className="border-bottom detail_title">
                    <div>
                        <div style={{
                            backgroundColor: "#9f0099",
                            color: "white",
                            fontSize: 14,
                            fontWeight: "bold",
                            width: 100,
                            textAlign: "center",
                            float: "left",
                        }}>
                            {n_type}
                        </div>
                        <div style={{
                            fontSize: 14,
                            color: "gray",
                            marginLeft: 120,
                        }}>
                            {reg_date} ({_GMT_TIMEZONE})
                        </div>
                    </div>
                    {n_title}
                </Col>
            </Row>
            <Row className="border-bottom">
                <Col md={8} className="detail_content">
                    <div dangerouslySetInnerHTML={{__html: n_content}}></div>
                </Col>
                <Col md={3} className="border-start">
                    <div
                        style={{
                            position: "sticky",
                            top: 120,
                        }}
                    >
                        {n_list && n_list.length > 0 && n_list.map((item, index) => {
                            let url = encodeURI(paths.ANNOUNCEMENT_DETAIL_PATH + '/' + item.n_seq + '/' + item.n_title);
                            return (
                                <Link to={`${url}`}
                                      key={index}
                                      className="side_link"
                                      style={{
                                          textDecoration: "none",
                                          color: "black"
                                      }}
                                >
                                    <div className="side_list">
                                        <div style={{
                                            backgroundColor: "#9f0099",
                                            color: "white",
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            width: 60,
                                            textAlign: "center",
                                        }}>
                                            {noticeTypeToText(item.n_type)}
                                        </div>
                                        <div>{item.n_title}</div>
                                        <div className="content">{item.n_title_summary}</div>
                                        <div className="date" style={{
                                            color: "gray",
                                            fontSize: 11,
                                        }}>
                                            {item.reg_date} ({_GMT_TIMEZONE})
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input type="button" value="View List" className="list_back_btn" onClick={handleClickList}/>
                </Col>
            </Row>
        </Container>
    );
}