import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import {BanBody} from "../components/ban/BanBody";
import {ShopBody} from "../components/profile/ShopBody";
import useSession from "../hooks/useSession";
import {EpinBody} from "../components/profile/EpinBody";
import {_BASE_URL} from "../const/ConstBase";
import {api} from "../services/api";
import {useNavigate} from "react-router-dom";

export const ShopScreen = () => {
    const {user} = useSession();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await api.post('/shop/can_buy', {});
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    };

    useEffect(() => {
        fetchData().then((res) => {
            if(res.code != "10000") {
                if(res.message === "block_account") {
                    alert("Due to being banned, you are unable to purchase.");
                } else if(res.message === "not_enough_level") {
                    alert("Your Aika Character Level must be 53 or higher to purchase M-coins");
                } else {
                    alert("You are unable to purchase M-coins.");
                }
                navigate("/");
            }
        });
        window.scrollTo(0, 0)
    }, [])

    return(
        <div style={{width: "100%", height: "100%", backgroundColor: "white", paddingTop: 20}}>
            <Helmet>
                <title>Purchase M-Coin</title>
                <meta name="description" content="Purchase M-Coin" />
            </Helmet>
            <CmmnHeader
                backgroundImg={Sub_top_002}
                title="M-Coin Purchase"
                subTitle="Aika Online Global uses M-Coin as its currency for the purchase of in-game items"
            />
            {
                user && user.lang === "EN" ? <ShopBody /> : <EpinBody />
            }
        </div>
    )
}
