import React from "react";
import "./MainOverview.css";
import {overview} from "../../const/ConstImages";
import paths from "../../router/paths";
import {Link, useNavigate} from "react-router-dom";
import {_BASE_IMG_URL} from "../../const/ConstBase";

export const MainOverview = () => {
    return(
        <div className="overview_video_container">
            <video className="overview_video" autoPlay loop muted>
                <source
                    src={`${_BASE_IMG_URL}main-overview-img_animation-1015.mp4`}
                    type="video/mp4"
                />
            </video>
            <div id="overview-section">

                <div className="text-center maindiv">
                    <h1 id="heading-overview">The Game Feature of Aika</h1>
                    <Link id="overview-button" to={paths.GUIDE_PATH + "#top"}>
                        <img
                            src={overview}
                         alt=""/>
                    </Link>
                </div>
            </div>
        </div>
    );
}