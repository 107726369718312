import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import paths from "../../router/paths";
import useInput from "../../hooks/useInput";
import {ChangePasswordCredentials} from "../../contexts/AuthContext";
import useSession from "../../hooks/useSession";
import {api} from "../../services/api";
import {useNavigate} from "react-router-dom";
import {isPasswordValid} from "../../utils/commonUtils";

export const PasswordChangeDetailBody = () => {

    const navigate = useNavigate();
    const password = useInput("");
    const newPassword = useInput("");
    const confirmPassword = useInput("");
    const [err_password, setErrPassword] = useState("");
    const [err_current_password, setErrCurrentPassword] = useState("");
    const [err_confirm_password, setErrConfirmPassword] = useState("");

    async function updatePassword(params: ChangePasswordCredentials) {
        try {
            const response = await api.post('/account/change_password', params);
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    }

    const handleUpdate = () => {
        if(err_password !== "" || err_current_password !== "" || err_confirm_password !== "") {
            return;
        }

        let params: ChangePasswordCredentials = {
            password: password.value,
            newPassword: newPassword.value,
            newPasswordConfirm: confirmPassword.value,
        }

        updatePassword(params).then((res) => {
            if(res.res_code === "SUCCESS") {
                alert("Password has been changed successfully.");
                navigate(paths.ACCOUNT_DETAIL_PATH);
            } else {
                alert(res.res_msg);
            }
        }).catch((err) => {
            // console.log("err = ", err);
            alert("Failed to change password.");
        });
    }
    // function isPasswordValid(password: string): boolean {
    //     // Regex breakdown:
    //     const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()+=~_-]).{8,16}$/;
    //     return regex.test(password);
    // }
    const validateForm = () => {
        if(password.value === "") {
            setErrCurrentPassword("Password is required.");
        } else {
            setErrCurrentPassword("");
        }
        if(newPassword.value === "") {
            setErrPassword("Password is required.");
        }
        if(newPassword.value !== "" || confirmPassword.value !== "") {
            if (!isPasswordValid(newPassword.value)) {
                setErrPassword("Password must include 8-16 capital & lower case English letters, numbers, and special characters.");
            } else {
                setErrPassword("");
                if (newPassword.value !== confirmPassword.value) {
                    setErrConfirmPassword("Password and Confirm Password are not matched.");
                } else {
                    setErrConfirmPassword("");
                }
            }
        }
    }

    useEffect(() => {
        validateForm();
    }, [password.value, newPassword.value, confirmPassword.value]);

    return (
        <Row className="justify-content-center justify-content-between "
             style={{
                 borderTop: "2px solid #D6D9E1",
                 marginTop: 33,
             }}
        >
            <div style={{
                marginTop: 20,
                width: "100%",
                height: "100%",
            }} className="detail_table">
                <table>
                    <tbody>
                    <tr>
                        <th>Current Password</th>
                        <td>
                            <input type="password" className="login_input"
                                   name="password"
                                   id="password"
                                   placeholder={"Password"}
                                   {...password}
                            /><br />
                            <ul style={{paddingLeft: 5, textAlign: "left", fontSize: 12}}>
                                {err_current_password && <li style={{color: "red"}}>{err_current_password}</li>}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>New Password</th>
                        <td>
                            <div style={{paddingTop: 20}}></div>
                            <input type="password" className="login_input"
                                   name="new_password"
                                   id="new_password"
                                   placeholder={"Password"}
                                   {...newPassword}
                            /><br />
                            <ul style={{paddingLeft: 5, textAlign: "left", fontSize: 12}}>
                                <li>Include 8-16 capital and lower case letters, numbers, and special characters.</li>
                                <li>Usable special characters are !@#$%^&*()+=~_-</li>
                                {err_password && <li style={{color: "red"}}>{err_password}</li>}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Confirm Password</th>
                        <td>
                            <input type="password" className="login_input"
                                   name="new_confirm_password"
                                   id="new_confirm_password"
                                   placeholder={"Password"}
                                   {...confirmPassword}
                            /><br />
                            <ul style={{paddingLeft: 50, textAlign: "left"}}>
                                {err_confirm_password && <li style={{color: "red"}}>{err_confirm_password}</li>}
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{height: 20}}></div>
            <div>
                <button
                    style={{
                        backgroundColor: "#DEE0E7",
                        borderRadius: 10,
                        border: "none",
                        height: 60,
                        width: "30%",
                    }}
                    onClick={handleUpdate}
                >
                    Update Password
                </button>
            </div>
            <div style={{height: 360}}></div>
        </Row>
    );
}