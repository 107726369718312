import {icon_check, icon_surprise} from "../../const/ConstImages";
import React from "react";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";

export const PaymentSuccessBody = () => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(paths.TOPUP_COIN_HISTORY_PATH)
    }
    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_check} alt="icon-surprise" />
            </div>
            <div className="center_content">
                M-Coin purchase was successful!
                <br />
                <br />
                <div className="black_button" style={{cursor: "pointer", width: 400}} onClick={clickHandler}>M-Coin Purchase History</div>
            </div>
        </div>
    )
}