import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import "./MainMidContainer.css";
import {
    contact,
    contact_d,
    contactus,
    discord,
    discord_d,
    facebook,
    facebook_d,
    findus,
    mcoin, purchase, purchase_d
} from "../../const/ConstImages";
import HoverImage from "../common/HoverImage";
import paths from "../../router/paths";
import {useNavigate} from "react-router-dom";

export const MainMidContainer = () => {
    const navigate = useNavigate();
    const handleNewWindow = (url: string) => {
        window.open(url, "_blank");
    }
    return (
        <div style={{
            // height: 70,
            backgroundColor: "white",
        }}>
            <Container>
                <Row className="justify-content-md-center" >
                    <Col md={3} className="mid_btn align-middle text-center">
                        <HoverImage src={discord} hoverSrc={discord_d} style={{cursor: "pointer"}} onClick={() => handleNewWindow(paths.DISCORD_PATH)} />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <HoverImage src={facebook} hoverSrc={facebook_d} style={{cursor: "pointer"}} onClick={() => handleNewWindow(paths.FACEBOOK_PATH)} />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <HoverImage src={contact} hoverSrc={contact_d} style={{cursor: "pointer"}} onClick={() => handleNewWindow(paths.CONTACT_US_PATH)} />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <HoverImage src={purchase} hoverSrc={purchase_d} style={{cursor: "pointer"}} onClick={() => navigate(paths.SHOP_PATH)} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}