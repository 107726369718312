const ROOT_PATH = '/'
const LOGIN_PATH = '/login'
const LOGOUT_PATH = '/logout'
const REGISTER_PATH = '/register'
const PASS_BLOCK_PATH = '/pass_block'
const PROFILE_PATH = '/profile'
const SHOP_PATH = '/shop'
const ACCOUNT_DETAIL_PATH = '/account_detail'
const LOGIN_HISTORY_PATH = '/login_history'
const TRANSACTION_HISTORY_PATH = '/transaction_history'
const BAN_PATH = '/ban'
const ANNOUNCEMENT_PATH = '/announcement';
const ANNOUNCEMENT_PATH_ALL = '/announcement/all';
const ANNOUNCEMENT_DETAIL_PATH = '/announcement_detail'
const DOWNLOAD_PATH = '/download'
const GUIDE_PATH = '/guide'
const FIND_PASSWORD_PATH = '/find_password'
const PAYMENT_SUCCESS_PATH = '/paymentSuccess'
const PAYMENT_FAIL_PATH = '/paymentFail'
const PRIVATE_POLICY_PATH = '/private_policy'
const TERM_OF_SERVICE_PATH = '/term_of_service'
const GAME_OPERATIONS_POLICY_PATH = '/game_operations_policy'
const UGC_PATH = '/ugc'
const BAN_IP_PATH = '/ban_ip'
const TOPUP_COIN_HISTORY_PATH = '/topup_coin_history'
const VALID_CODE_PATH = '/valid_code'
const PASSWORD_CHANGE_PATH = '/password_change'
const EPIN_PATH = '/epin'
// const SUPPORT_PATH = 'https://aikaonlineglobal-help.freshdesk.com/support/home';
const FAQ_PATH = 'https://aikaonlineglobal-help.freshdesk.com/support/solutions';
// const CONTACT_US_PATH = 'https://aikaonlineglobal-help.freshdesk.com/support/tickets/new';
const CONTACT_US_PATH = 'https://aikaonlineglobal-help.freshdesk.com/support/home';
const AFTER_CONTACT_US_PATH = 'https://cbminteractiveinc-630275215186477089.myfreshworks.com/login/auth/1696985655392?client_id=451979510707337272&redirect_uri=https://aikaonlineglobal-help.freshdesk.com/freshid/customer_authorize_callback';
const DISCORD_PATH = 'https://DISCORD.com/invite/FqmnW5m5Cb';
const FACEBOOK_PATH = 'https://www.facebook.com/aika.online.official/';
const WIKI_PATH = 'https://aikaonlinena.fandom.com/wiki/AikaOnlineNA_Wiki';
const DOWNLOAD_INSTALLER_PATH = 'https://patch-aikaonline.cbmgames.com/fullclient/AikaOnlineGlobal_Setup.exe';

const GUILD_RANK_PATH = '/guild_rank';
const PVP_RANK_DAY_PATH = '/pvp_rank_day';
const PVP_RANK_WEEK_PATH = '/pvp_rank_week';

const paths = {
    ROOT_PATH,
    LOGIN_PATH,
    REGISTER_PATH,
    PASS_BLOCK_PATH,
    PROFILE_PATH,
    SHOP_PATH,
    ACCOUNT_DETAIL_PATH,
    LOGIN_HISTORY_PATH,
    TRANSACTION_HISTORY_PATH,
    BAN_PATH,
    ANNOUNCEMENT_PATH,
    ANNOUNCEMENT_PATH_ALL,
    ANNOUNCEMENT_DETAIL_PATH,
    DOWNLOAD_PATH,
    GUIDE_PATH,
    FIND_PASSWORD_PATH,
    PAYMENT_SUCCESS_PATH,
    PAYMENT_FAIL_PATH,
    PRIVATE_POLICY_PATH,
    TERM_OF_SERVICE_PATH,
    UGC_PATH,
    BAN_IP_PATH,
    TOPUP_COIN_HISTORY_PATH,
    VALID_CODE_PATH,
    PASSWORD_CHANGE_PATH,
    EPIN_PATH,
    // SUPPORT_PATH,
    FAQ_PATH,
    CONTACT_US_PATH,
    AFTER_CONTACT_US_PATH,
    DISCORD_PATH,
    FACEBOOK_PATH,
    WIKI_PATH,
    GAME_OPERATIONS_POLICY_PATH,
    LOGOUT_PATH,
    DOWNLOAD_INSTALLER_PATH,
    GUILD_RANK_PATH,
    PVP_RANK_DAY_PATH,
    PVP_RANK_WEEK_PATH,
} as const

export default paths
