import React from "react";
import "./DownloadBody.css";
import {amd, directx, nvidia} from "../../const/ConstImages";

export const DownloadBody = () => {
    return(
        <div className="sub_content_download">
            <div className="download_title">System Requirements</div>
            <table>
                <tbody>
                <tr>
                    <th>Category</th>
                    <th>Minimum</th>
                    <th>Recommended</th>
                </tr>
                <tr>
                    <th>CPU</th>
                    <td>Pentium4 1.8GHz</td>
                    <td>Pentium dual core</td>
                </tr>
                <tr>
                    <th>Hard Drive</th>
                    <td colSpan={2}>Over 2GB</td>
                </tr>
                <tr>
                    <th>Memory</th>
                    <td>1 GB Ram</td>
                    <td>2 GB Ram</td>
                </tr>
                <tr>
                    <th>GPU</th>
                    <td>Over 256 MB 3D</td>
                    <td>Over 512 MB 3d</td>
                </tr>
                <tr>
                    <th>OS</th>
                    <td colSpan={2}>Windows 2000, XP, Vista, Windows 7, 10 &amp; 11</td>
                </tr>
                </tbody>
            </table>
            <div className="desc">
                * For the best Aika Online Global experience, please install the latest DirectX service pack.
            </div>
            <div className="driver_title">Latest Drivers &amp; Direct X Install</div>
            <div className="driver_buttons" style={{
                height: 200
            }}>
                <div className="item">
                    <div className="driver_img">
                        <a href="https://www.nvidia.co.kr/Download/index.aspx?lang=en" target="_blank" rel="noreferrer noopener">
                            <img src={nvidia} width={"100%"} />
                        </a>
                    </div>
                </div>
                <div className="space" />
                <div className="item">
                    <div className="driver_img">
                        <a href="https://www.amd.com/en/support" target="_blank" rel="noreferrer noopener">
                            <img src={amd} width={"100%"} alt=""/>
                        </a>
                    </div>
                    {/*<div className="install_text">Install</div>*/}
                </div>
                <div className="space" />
                <div className="item">
                    <div className="driver_img">
                        <a href="https://www.microsoft.com/en-us/download/details.aspx?id=35" target="_blank" rel="noreferrer noopener">
                            <img src={directx} width={"100%"} />
                        </a>
                    </div>
                    {/*<div className="install_text">Install</div>*/}
                </div>
            </div>
        </div>

    )
}