import React, {useEffect} from "react";
import {Sub_top_002} from "../const/ConstImages";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {BanBody} from "../components/ban/BanBody";
import {Helmet} from "react-helmet-async";
import exp from "constants";
import BanIPBody from "../components/ban/BanIPBody";

const BanIPScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>Banned!</title>
                <meta name="description" content="Player Banned!" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title="Banned!" />
            <BanIPBody />
        </>
    )
}

export default BanIPScreen;