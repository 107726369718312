import React from "react";
import "./CmmnPaging.css";
import {sub_list_search} from "../../const/ConstImages";
import useInput from "../../hooks/useInput";
import {Col, Container, Row} from "react-bootstrap";

interface Props {
    currentPage: number;
    totalCount: number;
    pageSize: number;
    showSearch?: boolean;
    clickPage(page: number): void;
    clickSearch(text: string): void;
}
export const CmmnPaging: React.FC<Props> = (props: Props) => {
    const searchInput = useInput('');

    let totalPage = Math.ceil(props.totalCount / props.pageSize);
    let startPage = Math.floor((props.currentPage - 1) / 10) * 10 + 1;
    let endPage = startPage + 9;
    if(endPage > totalPage) {
        endPage = totalPage;
    }
    let showSearch = props.showSearch === undefined ? true : props.showSearch;

    return(
        <div style={{backgroundColor:"white"}}>
            <Row className="justify-content-center notice_pg_navi">
                {Array(endPage - startPage + 1).fill(0).map((_, index) => {
                    //bold style page if currentPage
                    return(
                        <span key={index}
                              style={{
                                  cursor: "pointer",
                                  fontWeight: props.currentPage === startPage + index ? "bold" : "normal",
                                  color: props.currentPage === startPage + index ? "darkgray" : "black"
                              }}
                              onClick={() => {
                            props.clickPage(startPage + index);
                        }}>{startPage + index}</span>
                    );
                })}
            </Row>
            {showSearch &&
            <Row className="justify-content-center d-flex">
                    {/*<Col md={1} className="justify-content-end justify-content-sm-center nopadding">*/}
                    {/*    <select name="" id="" style={{*/}
                    {/*        height: 40,*/}
                    {/*        width: "100%",*/}
                    {/*        maxWidth: 300*/}
                    {/*    }}>*/}
                    {/*        <option value="title">Title</option>*/}
                    {/*    </select>*/}
                    {/*</Col>*/}
                    <Col md={3} className="justify-content-center">
                        <input type="text" placeholder="Search" style={{
                            height: 40,
                            width: "80%",
                            maxWidth: 300
                        }}
                               {...searchInput}
                               onKeyDown={(e) => {
                                   if(e.key === 'Enter') {
                                       props.clickSearch(searchInput.value);
                                   }
                               }}
                        />
                        <img src={sub_list_search} alt="search-icon"
                             onClick={() => {
                                 props.clickSearch(searchInput.value);
                             }}
                             style={{ cursor: "pointer", marginTop: -5}}
                        />
                    </Col>
            </Row>
            }
            <div style={{height: 40}}></div>
        </div>
    )
}