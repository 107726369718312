import {icon_surprise} from "../../const/ConstImages";
import React from "react";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";

const PasswordBlockBody = () => {
    const navigate = useNavigate();

    const handleResetPassword = () => {
        navigate(paths.FIND_PASSWORD_PATH);
    }
    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_surprise} alt="icon-surprise" height={200} />
            </div>
            <div className="center_content">
                Account has been blocked!
                <br />
                <strong>Reason : </strong> Wrong password attempts on multiple occasions
                <br />
                <br />
                <div className="black_button" onClick={handleResetPassword} style={{cursor: "pointer"}}>Reset password</div>
            </div>
        </div>
    )
}

export default PasswordBlockBody;