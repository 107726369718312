import React from "react";
import {Row} from "react-bootstrap";
import "./AccountDetailBody.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import useSession from "../../hooks/useSession";
import {useNavigate} from "react-router-dom";
import paths from "../../router/paths";


export const AccountDetailBody = () => {
    const {user} = useSession();
    const navigate = useNavigate();

    return (
        <Row className="justify-content-center justify-content-between "
             style={{
                 borderTop: "2px solid #D6D9E1",
                 marginTop: 33,
             }}
        >
            <div style={{
                marginTop: 20,
                width: "100%",
                height: "100%",
            }} className="detail_table">
                <table>
                    <tbody>
                    <tr>
                        <th>Email</th>
                        <td>{user?.email}</td>
                        <td style={{
                            width: "40%",
                            textAlign: "center",
                            fontSize: 11,
                        }}>
                            <FontAwesomeIcon icon={faCheck} size="xs" /><br />Verified
                        </td>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                        <th>Nickname</th>
                        <td>
                            {user?.nickname}
                        </td>
                        <td style={{
                            width: "40%",
                            textAlign: "center",
                            fontSize: 11,
                        }}>
                            <FontAwesomeIcon icon={faCheck} size="xs" /><br />
                            Can't be changed
                        </td>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                        <th>Password</th>
                        <td>
                            Latest Change: {user?.last_update_password}
                        </td>
                        <td style={{
                            width: "40%",
                            textAlign: "center",
                            fontSize: 11,
                        }}>
                            <button
                                style={{
                                    backgroundColor: "#DEE0E7",
                                    borderRadius: 10,
                                    border: "none",
                                    height: 40,
                                    width: "100%",
                                    fontSize: 12,
                                }}
                                onClick={() => {
                                    navigate(paths.PASSWORD_CHANGE_PATH);
                                }}
                            >
                                Change Password
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{height: 20}}></div>
            {/*<div>*/}
            {/*    <button style={{*/}
            {/*        backgroundColor: "#DEE0E7",*/}
            {/*        borderRadius: 10,*/}
            {/*        border: "none",*/}
            {/*        height: 60,*/}
            {/*        width: "30%",*/}
            {/*    }}>*/}
            {/*        Go Back*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div style={{height: 360}}></div>
        </Row>
    );
}