import axios from 'axios'

import { setupInterceptors } from './interceptors'
import {_BASE_URL} from "../const/ConstBase";

export const api = setupInterceptors(
    axios.create({
        baseURL: _BASE_URL,
    })
)
