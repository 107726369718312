import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ProfileLeftMenu} from "../components/profile/ProfileLeftMenu";
import {LoginHistoryHeader} from "../components/profile/LoginHistoryHeader";
import {LoginHistoryBody} from "../components/profile/LoginHistoryBody";
import {TransactionHistoryHeader} from "../components/profile/TransactionHistoryHeader";
import {TransactionHistoryBody} from "../components/profile/TransactionHistoryBody";

export const TransactionHistoryScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "white", paddingTop: 20}}>
            <Container fluid="true">
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center left_menu_top_padding">
                        <ProfileLeftMenu />
                    </Col>
                    <Col md={7} className="align-middle text-center"
                         style={{
                             paddingTop: 120,
                         }}
                    >
                        <TransactionHistoryHeader />
                        <TransactionHistoryBody />

                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    )
}