import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import PasswordBlockBody from "../components/ban/PasswordBlockBody";

const PasswordBlockScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>Banned!</title>
                <meta name="description" content="Account has been blocked!" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title="Account has been blocked!" />
            <PasswordBlockBody />
        </>
    )
}

export default PasswordBlockScreen;