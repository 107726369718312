import React, {useEffect, useRef, useState} from "react";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import {NoticeItem} from "../components/announcement/NoticeItem";
import {CmmnPaging} from "../components/common/CmmnPaging";
import {_BASE_URL} from "../const/ConstBase";
import {NoticeItems} from "../components/announcement/notice_types";
import {NoticeCate} from "../components/announcement/NoticeCate";
import {useNavigate, useParams} from "react-router-dom";



export const NoticeScreen = () => {
    let params = useParams();
    let navigate = useNavigate();
    const [list, setList] = useState<NoticeItems[]>([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [cate, setCate] = useState(params.ntype ?? "all");
    const [searchText, setSearchText] = useState("");

    // let ntype = params.ntype ?? "all"
    const callback_cate = (new_cate: string) => {
        if(new_cate !== cate) {
            // console.log("new cate = ", new_cate, cate)
            setPage(1);
            setCate(new_cate);
            navigate(`/announcement/${new_cate}`);
            // window.history.pushState(null, "", `/announcement/${new_cate}`)
            // fetchData(1, new_cate, searchText);
        }
    }
    const fetchData = async (page: number, cate: string, stext: string) => {
        try {
            setList([]);
            const response = await fetch(`${_BASE_URL}notice/list/${page}/${cate}/${stext}`, { method : "GET" });
            const data = await response.json();
            if(data && data.data) {
                setList(data.data);
                setPage(data.page.current);
                setTotalCount(data.page.total);
                setPageSize(data.page.pageSize);
            }
        } catch (error) {
            // console.log(error);
        }
    };
    useEffect(() => {
        fetchData(page, cate, searchText);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCate(cate);
        fetchData(page, cate, searchText);
    }, [cate]);

    return(
        <>
            <CmmnHeader backgroundImg={Sub_top_002} title="Announcement" />
            <NoticeCate callback={callback_cate} />
            { list && list.length > 0 && list.map((item, index) => {
                if(item.n_list_summary === null) { item.n_list_summary = ""; }
                if(item.n_title_summary === null) { item.n_title_summary = ""; }
                return(
                    <NoticeItem key={index} item={item}/>
                );
            })}
            <CmmnPaging
                currentPage={page}
                totalCount={totalCount}
                pageSize={pageSize}
                clickPage={(page) => {
                    setPage(page);
                    fetchData(page, cate, searchText);
                }}
                clickSearch={(text) => {
                    setSearchText(text);
                    fetchData(page, cate, text);
                }}
            />
        </>
    );
}