import React from "react";
import "./WidgetRecentLogin.css";
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe, faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {RecentLoginDataArr} from "../profile_types";
import {useNavigate} from "react-router-dom";
import paths from "../../../router/paths";

export const WidgetRecentLogin: React.FC<{datas: RecentLoginDataArr}> = (datas) => {
    const navigate = useNavigate();
    const navigateToLoginHistory = () => {
        navigate(paths.LOGIN_HISTORY_PATH);
    }

    return (
        <Col md={12}>
            <div style={{
                backgroundColor: "#DEE0E7",
                height: 50,
                width: "100%",
                marginTop: 10,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{
                    padding: "10px 5px 20px 10px",
                    minHeight: "50px",
                    height: "auto",
                    borderBottom: "1px solid #DEE0E7",
                    cursor: "pointer",
                }} onClick={navigateToLoginHistory}>
                    <div style={{
                        float: "left",
                        paddingRight: 20,
                    }}>
                        <FontAwesomeIcon icon={faGlobe} size="lg" />
                    </div>
                    <div style={{
                        width: "calc(100% - 70px)",
                        float: "left",
                        fontSize: "16px",
                        textAlign: "left",
                    }}>
                        Recent Login
                    </div>
                    <div style={{
                        float: "left",
                        width: "20px",
                    }}>
                        <FontAwesomeIcon icon={faRightToBracket} size="xs" />
                    </div>
                </div>
            </div>
            <div style={{
                paddingLeft: 20,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}>
                <div style={{
                    paddingTop: 10,
                    textAlign: "left",
                    minHeight: 40,
                }}>
                   <table>
                       <thead>

                       </thead>
                       <tbody>
                       { datas.datas.map((data, index) => {
                            return (
                                 <tr key={index}>
                                      <td style={{
                                        borderRight: "3px solid #DEE0E7",
                                        width: 10,
                                        paddingRight: 10,
                                      }}>
                                      </td>
                                      <td style={{
                                        paddingLeft: 20,
                                      }}
                                      >
                                        {data.city} {data.country}<br />
                                        <span style={{fontSize:12, color:"gray"}}>{data.country_code}</span>
                                      </td>
                                 </tr>
                            );
                       })}
                       </tbody>
                   </table>
                    <div style={{height: 20}}></div>
                </div>
            </div>

        </Col>
  );
}