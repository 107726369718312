import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Row} from "react-bootstrap";
import "./NoticeCate.css";
import {useParams} from "react-router-dom";

interface Props {
    callback: (type: string) => void;
}
export const NoticeCate: React.FC<Props> = (props: Props, ref) => {
    let params = useParams();
    const [cateAll, setCateAll] = useState("cate_on");
    const [cateUpdate, setCateUpdate] = useState("");
    const [cateEvent, setCateEvent] = useState("");
    const [cateNotice, setCateNotice] = useState("");

    let ntype = params.ntype ?? "all"
    useEffect(() => {
        setCategory(ntype);
    }, [ntype]);
    const setCategory = (type: string) => {
        setCateAll("");
        setCateUpdate("");
        setCateEvent("");
        setCateNotice("");
        switch(type) {
            case "all":
                setCateAll("cate_on");
                break;
            case "update":
                setCateUpdate("cate_on");
                break;
            case "event":
                setCateEvent("cate_on");
                break;
            case "notice":
                setCateNotice("cate_on");
                break;
        }
        props.callback(type);
    }
    return (
        <Row className="justify-content-center border-bottom" style={{
            paddingTop: 40,
            backgroundColor: "white",
        }}>
            <Col className={`notice_menu_item ${cateAll}`} md={2}
                 style={{
                     cursor: "pointer",
                 }}
                onClick={() => {
                    setCategory("all");
                }}
            >Show All</Col>
            <Col className={`notice_menu_item ${cateUpdate}`} md={2}
                 style={{
                     cursor: "pointer",
                 }}
                 onClick={() => {
                     setCategory("update");
                 }}
            >Updates</Col>
            <Col className={`notice_menu_item ${cateEvent}`} md={2}
                 style={{
                     cursor: "pointer",
                 }}
                 onClick={() => {
                     setCategory("event");
                 }}
            >Events</Col>
            <Col className={`notice_menu_item ${cateNotice}`} md={2}
                 style={{
                     cursor: "pointer",
                 }}
                 onClick={() => {
                     setCategory("notice");
                 }}
            >Notices</Col>
        </Row>
    )
}