import React, {FormEvent, useEffect, useRef, useState} from "react";
import "./LoginBody.css";
import {sub_log_logo} from "../../const/ConstImages";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useSession from "../../hooks/useSession";
import ReCAPTCHA from "react-google-recaptcha";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    _BASE_URL,
    _BUILD_ENV,
    _CAPTCHA_SITE_KEY,
    _FRESHDESK_REDIRECT_DOMAIN,
    _FRESHDESK_SUB_DOMAIN
} from "../../const/ConstBase";
import paths from "../../router/paths";
import Loading from "../common/Loading";
import {createBanReasonStorage, getToken, removeBanReasonStorage} from "../../utils/tokenStorage";
import {api} from "../../services/api";

function initialFormValues() {
    return {
        email: _BUILD_ENV === "" ? 'test@test.com' : '',
        password: _BUILD_ENV === "" ? '12asAS!@' : '',
        captcha: "",
    }
}

interface Props {
    //redirect page after login
    redirectTo?: string
}

type ErrDetails = {
    status: number,
    title: string,
    details: string,
}

type freshdeskTokenResponse = {
    res_code: string,
    res_msg: string,
    url: string,
}
const LoginBody: React.FC<Props> = ({redirectTo}) => {
    const location = useLocation();
    const [values, setValues] = useState(initialFormValues)
    const [loginRequestStatus, setLoginRequestStatus] = useState('success')
    const navigate = useNavigate();
    const {signIn, user} = useSession();
    const token = getToken();

    const clientURL = location.search.split("?")[1];

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target

        setValues({
            ...values,
            [name]: value
        })
    }

    async function requestFreshdeskToken(clientURL: string, email: string) {
        const response = await api.get(`${_BASE_URL}auth/fd_token?${clientURL}&email=${email}`, {});
        return response.data;
    }
    const fetchFreshdeskToken = async (clientURL: string, email: string) => {
        requestFreshdeskToken(clientURL, email).then((res) => {
            if(res && res.res_code) {
                if(res.res_code === "SUCCESS") {
                    let domain = (new URL(res.url));
                    if(domain) {
                        let afterDomain = domain.hostname.toLowerCase();
                        if(afterDomain == _FRESHDESK_REDIRECT_DOMAIN) {
                            // console.log(" fetch success ", res.url);
                            window.location.href = res.url;
                        } else {
                            navigate(paths.ROOT_PATH);
                        }
                    } else {
                        navigate(paths.ROOT_PATH);
                    }
                } else {
                    alert("Fail to sso login. Please try again.");
                    navigate(paths.ROOT_PATH);
                }
            }
        }).catch((err) => {
        });
        // try {
        //     const response = await fetch(`${_BASE_URL}auth/fd_token?${clientURL}&email=${email}`);
        //     const res: freshdeskTokenResponse = await response.json();
        //     if(res.res_code && res.res_code === "SUCCESS") {
        //         let domain = (new URL(res.url));
        //         if(domain) {
        //             let afterDomain = domain.hostname.toLowerCase();
        //             if(afterDomain == _FRESHDESK_REDIRECT_DOMAIN) {
        //                 // console.log(" fetch success ", res.url);
        //                 window.location.href = res.url;
        //             } else {
        //                 navigate(paths.ROOT_PATH);
        //             }
        //         } else {
        //             navigate(paths.ROOT_PATH);
        //         }
        //     } else {
        //         alert("Fail to sso login. Please try again.");
        //         navigate(paths.ROOT_PATH);
        //     }
        // } catch(err) {
        //     // console.log(" fetch error ", err);
        // }
    };

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        setLoginRequestStatus('loading')
        removeBanReasonStorage();

        await signIn(values).then((status) => {
            if (status === "E") {
                navigate(paths.VALID_CODE_PATH);
            } else if (status === "V") {
                if(clientURL) {
                    //get freshdesk jwt token
                    fetchFreshdeskToken(clientURL, values.email);
                } else {
                    navigate(redirectTo || paths.PROFILE_PATH);
                }
            } else {
                navigate(paths.PROFILE_PATH);
            }
        }).catch((err) => {
            let data = err.response?.data;
            if (data) {
                let err_data = data as ErrDetails;
                if(err_data.details.startsWith("BAN_ACCOUNT")) {
                    //split ::
                    let err_details = err_data.details.split("::");
                    if(err_details.length > 1) {
                        //
                        createBanReasonStorage(err_details[1]);
                    }
                    navigate(paths.BAN_PATH);
                    return;
                } else {
                    switch (err_data.details) {
                        case "PASS_BLOCK":
                            navigate(paths.PASS_BLOCK_PATH);
                            break;
                        case "BLOCK_IP":
                            navigate(paths.BAN_IP_PATH);
                            break;
                        // case "BAN_ACCOUNT":
                        //     navigate(paths.BAN_PATH);
                        //     break;
                        default:
                            alert("Fail to login. Please check your email and password.");
                            recaptchaRef.current?.reset();
                            setIsCaptcha(false);
                            break;
                    }
                }
            }
        }).finally(() => {
            setLoginRequestStatus('success')
        });

    }

    useEffect(() => {
        // clean the function to prevent memory leak
        setLoginRequestStatus('success')
    }, [])

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [isCaptcha, setIsCaptcha] = useState(false);

    function onReCaptchaChange(value: any) {
        setValues({
            ...values,
            ["captcha"]: value
        });
        setIsCaptcha(true);
    }

    function onReCaptchaError() {
        setValues({
            ...values,
            ["captcha"]: "",
        });
        setIsCaptcha(false);
    }

    function onReCaptchaExpire() {
        setValues({
            ...values,
            ["captcha"]: "",
        });
        setIsCaptcha(false);
    }

    if(user) {
        if(clientURL) {
            //get freshdesk jwt token
            fetchFreshdeskToken(clientURL, user.email);
        } else {
            navigate(paths.ROOT_PATH);
        }
    }

    return !token ? (
        <div className="sub_content">
            <ToastContainer/>
            <form onSubmit={handleSubmit}>
                <div style={{width: "100%", height: 40}}/>
                <div className="login_box">
                    <div className="logo">
                        <img src={sub_log_logo} alt="sub_log-logo"/>
                    </div>
                    <div className="input_container">
                        <input type="email" className="login_input"
                               value={values.email}
                               name="email"
                               id="email"
                               placeholder={"Email"}
                               disabled={loginRequestStatus === 'loading'}
                               onChange={handleChange}
                               maxLength={40}
                        /><br/>
                    </div>
                    <div className="input_container">
                        <input type="password" className="login_input"
                               value={values.password}
                               name="password"
                               id="password"
                               placeholder={"Password"}
                               disabled={loginRequestStatus === 'loading'}
                               onChange={handleChange}
                        /><br/>
                    </div>
                    <div className="input_container">
                        <ReCAPTCHA
                            sitekey={_CAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                            onChange={onReCaptchaChange}
                            onExpired={onReCaptchaError}
                            onErrored={onReCaptchaExpire}
                            style={{display: "inline-block"}}
                        />
                    </div>
                    <div style={{width: "100%", height: 100, textAlign: "center"}}>
                        <button type="submit" id="btnLogin" disabled={loginRequestStatus === 'loading' || !isCaptcha}>
                            {loginRequestStatus === 'loading' ? 'Loading...' : 'Login'}
                        </button>
                    </div>
                    <div style={{width: "100%", height: 200, textAlign: "center"}}>
                        <Link to={paths.FIND_PASSWORD_PATH} style={{textDecoration: "none", color: "black"}}>Find
                            Password</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={paths.REGISTER_PATH} style={{
                        textDecoration: "none",
                        color: "black"
                    }}>Sign up for Free</Link>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <div className="sub_content">
            <Loading/>
        </div>
    );
}

export default LoginBody;