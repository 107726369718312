import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {CmmnHeader} from "../components/common/CmmnHeader";
import {Sub_top_002} from "../const/ConstImages";
import UgcBody from "../components/privacy/UgcBody";

export const UgcScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Helmet>
                <title>User Generated Content Policy</title>
                <meta name="description" content="User Generated Content Policy" />
            </Helmet>
            <CmmnHeader backgroundImg={Sub_top_002} title="User Generated Content Policy" />
            <UgcBody />
        </>
    )
}

export default UgcScreen;